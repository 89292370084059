import React, { useEffect, useState, Fragment } from "react";

// components
import NerveSVG from "./SVG/NerveSVG";
import ForaminaSVG from "./Foramina/ForaminaSVG";
import NerveMobile from "./NerveMobile";

// style
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/pro-solid-svg-icons";
import { faBriefcaseMedical } from "@fortawesome/pro-regular-svg-icons";
import { green, grayscale } from "../Utilities";

// Data
import { cranialData } from "../Static/Data";

export default function Nerve(props) {
  const nerveId = props.match.params.nerveId;
  console.log(props);

  useEffect(() => {
    props.handleLocation("nerve");
  }, []);

  const [nerves, setNerves] = useState();
  const [nerve, setNerve] = useState();
  useEffect(() => {
    const nervesArray = cranialData.data.cranialNerves;
    const activeNerve = nervesArray.filter(nerveObj => nerveObj.id == nerveId);
    setNerve(activeNerve[0]);
  }, [nerveId]);

  const [nerveView, setNerveView] = useState("anatomy");
  function toggleNerveView(view) {
    setNerveView(view);
  }

  function renderFunctions(functionType) {
    if (functionType == "Motor") {
      return (
        <Fragment>
          <div className="label-subtitle">{functionType}</div>
          <div dangerouslySetInnerHTML={{ __html: nerve.functionMotor }} />
        </Fragment>
      );
    }
    if (functionType == "Sensory") {
      return (
        <Fragment>
          <div className="label-subtitle">{functionType}</div>
          <div dangerouslySetInnerHTML={{ __html: nerve.functionSensory }} />
        </Fragment>
      );
    }
    if (functionType == "Both") {
      return (
        <Fragment>
          <div className="sub-content">
            <div className="label-subtitle">Sensory</div>
            <div dangerouslySetInnerHTML={{ __html: nerve.functionSensory }} />
          </div>
          <div className="sub-content">
            <div className="label-subtitle">Motor</div>
            <div dangerouslySetInnerHTML={{ __html: nerve.functionMotor }} />
          </div>
        </Fragment>
      );
    }
  }

  if (!nerve) {
    return null;
  }
  return (
    <Fragment>
      <NerveTopContainer>
        <div className="info">
          <div className="info-block">
            <div className="label-title">FUNCTION</div>
            <div className="contents">
              {renderFunctions(nerve.functionType)}
            </div>
          </div>
          <div className="info-block">
            <div className="label-title">COMPONENTS</div>
            <div className="contents">
              <div dangerouslySetInnerHTML={{ __html: nerve.components }} />
            </div>
          </div>

          <div className="info-block">
            <div className="label-title">ORIGIN</div>
            <div className="contents">
              <StyledButton
                nerveView={nerveView}
                onClick={() => toggleNerveView("anatomy")}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: nerve.origin }}
                  className="content-button-text"
                />
                <StyledIcon
                  icon={faBrain}
                  className="content-button-icon anatomy"
                />
              </StyledButton>
            </div>
          </div>

          <div className="info-block">
            <div className="label-title">FORAMINA</div>
            <div className="contents">
              <StyledButton
                nerveView={nerveView}
                onClick={() => toggleNerveView("foramina")}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: nerve.foramina }}
                  className="content-button-text"
                />
                <StyledIcon
                  icon={faBrain}
                  className="content-button-icon foramina"
                />
              </StyledButton>
            </div>
          </div>
        </div>

        <div className="img-container">
          {nerveView == "anatomy" ? (
            <NerveSVG {...props} nerveId={nerveId} />
          ) : (
            <ForaminaSVG {...props} nerveId={nerveId} />
          )}
        </div>
        <div className="title">
          <div className="numeral">{`CN ${nerve.numeral}`}</div>
          <div className="nerve-name">{`${nerve.name} Nerve`}</div>
        </div>
      </NerveTopContainer>

      <NerveMobile
        toggleNerveView={toggleNerveView}
        renderFunctions={renderFunctions}
        nerveView={nerveView}
        nerve={nerve}
        nerves={nerves}
        nerveId={nerveId}
        {...props}
      />

      <NerveBottomContainer>
        <div className="body-block">
          <h2>Anatomy</h2>
          {nerve.anatomy.map((division, index) => {
            return (
              <Fragment>
                <div className="body-inner-block" key={index}>
                  {division.divisionTitle && <h3>{division.divisionTitle}</h3>}
                  {division.divisionDesc && (
                    <div className="reading-text">{division.divisionDesc}</div>
                  )}
                  {division.divisionPoints.length > 0 &&
                    division.divisionPoints.map((points, index) => {
                      return (
                        <div
                          className="reading-text division-points"
                          key={index}
                        >
                          {points}
                        </div>
                      );
                    })}
                </div>
                <div className="body-inner-block correlation">
                  {division.clinicalCorrelations && (
                    <Fragment>
                      {division.clinicalCorrelations.map(correlation => {
                        return (
                          <ClinicalCorrelation
                            key={correlation.id}
                            onClick={() =>
                              props.history.push(`../case/${correlation.id}`)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faBriefcaseMedical}
                              size="2x"
                              className="correlation-icon"
                            />
                            <div>
                              <div className="correlation-label">
                                Clinical Correlation
                              </div>
                              <div className="correlation-title">
                                {correlation.title}
                              </div>
                              <div className="correlation-description">
                                {correlation.description}
                              </div>
                            </div>
                          </ClinicalCorrelation>
                        );
                      })}
                    </Fragment>
                  )}
                </div>
              </Fragment>
            );
          })}
        </div>
        <div className="body-block">
          <h2>Physical Exam</h2>
          {nerve.testing.map((test, index) => {
            return (
              <Fragment key={index}>
                <div className="body-inner-block">
                  <h3>{test.function}</h3>
                  <div className="reading-text">{test.test}</div>
                </div>

                <div className="body-inner-block correlation">
                  {test.clinicalCorrelations && (
                    <Fragment>
                      {test.clinicalCorrelations.map(correlation => {
                        return (
                          <ClinicalCorrelation
                            key={correlation.id}
                            onClick={() =>
                              props.history.push(`../case/${correlation.id}`)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faBriefcaseMedical}
                              size="2x"
                              className="correlation-icon"
                            />
                            <div>
                              <div className="correlation-label">
                                Clinical Correlation
                              </div>
                              <div className="correlation-title">
                                {correlation.title}
                              </div>
                              <div className="correlation-description">
                                {correlation.description}
                              </div>
                            </div>
                          </ClinicalCorrelation>
                        );
                      })}
                    </Fragment>
                  )}
                </div>
              </Fragment>
            );
          })}
        </div>
        <div className="body-block">
          <h2>Clinical Correlation</h2>
          {nerve.disorders.map((disorder, index) => {
            return (
              <Fragment key={index}>
                <div className="body-inner-block">
                  <h3>
                    {disorder.pathology}

                    {disorder.subtitle && (
                      <div className="subtitle">{disorder.subtitle}</div>
                    )}
                  </h3>

                  {disorder.description && (
                    <div className="reading-text">{disorder.description}</div>
                  )}
                  <ul>
                    {disorder.causes.map(cause => {
                      return (
                        <li key={cause} className="reading-text">
                          {cause}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="body-inner-block correlation">
                  {disorder.clinicalCorrelations && (
                    <Fragment>
                      {disorder.clinicalCorrelations.map(correlation => {
                        return (
                          <ClinicalCorrelation
                            key={correlation.id}
                            onClick={() =>
                              props.history.push(`../case/${correlation.id}`)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faBriefcaseMedical}
                              size="2x"
                              className="correlation-icon"
                            />
                            <div>
                              <div className="correlation-label">
                                Clinical Correlation
                              </div>
                              <div className="correlation-title">
                                {correlation.title}
                              </div>
                              <div className="correlation-description">
                                {correlation.description}
                              </div>
                            </div>
                          </ClinicalCorrelation>
                        );
                      })}
                    </Fragment>
                  )}
                </div>
              </Fragment>
            );
          })}
        </div>
      </NerveBottomContainer>
    </Fragment>
  );
}

const NerveTopContainer = styled.div`
  display: grid;
  grid-gap: 45px;
  grid-template-columns: 3fr 2.4fr 5fr;
  max-width: 1200px;
  margin: auto;
  padding: 50px 15px 15px 15px;
  .info {
    text-align: right;
    align-self: end;
    padding: 10px 0;
    .info-block:not(:last-child) {
      margin-bottom: 45px;
    }
    .label-title {
      font-weight: 700;
    }
    .label-subtitle {
      font-weight: 500;
    }
    .contents {
      font-weight: 300;
      margin-top: 10px;
      .sub-content {
        margin-bottom: 15px;
      }
    }
  }
  .img-container {
    img {
      max-width: 100%;
      max-height: 600px;
    }
  }
  .title {
    align-self: center;
    .numeral {
      font-size: 24px;
    }
    .nerve-name {
      font-weight: 700;
      font-size: 50px;
    }
  }

  @media (max-width: 1200px) {
    grid-template-columns: 3fr 1.8fr 3fr;
    grid-gap: 35px;
    .info {
      font-size: 14px;
      .contents {
        margin-top: 5px;
      }
    }
    .title {
      align-self: center;
      .numeral {
        font-size: 18px;
      }
      .nerve-name {
        font-weight: 700;
        font-size: 40px;
      }
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: 2fr 2fr 2fr;
    grid-gap: 20px;
    .title {
      align-self: center;
      .numeral {
        font-size: 16px;
      }
      .nerve-name {
        font-weight: 700;
        font-size: 22px;
      }
    }
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

const NerveBottomContainer = styled.div`
  max-width: 700px;
  margin: auto;
  margin-top: 200px;
  position: relative;
  z-index: 2;
  h2 {
    font-size: 32px;
    margin-bottom: 15px;
  }
  h3 {
    font-size: 22px;
    margin-bottom: 10px;
    margin-top: 15px;
    .subtitle {
      font-size: 16px;
      font-weight: 300;
    }
  }
  .body-block {
    margin-bottom: 75px;
    .body-inner-block {
      margin-bottom: 35px;
      .division-points {
        margin-top: 15px;
      }
      &.correlation {
        margin-bottom: 50px;
      }
    }
  }
  @media (max-width: 425px) {
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 18px;
    }
    margin-top: 75px;
  }
`;

const ClinicalCorrelation = styled.div`
  background: #f9f9f9;
  padding: 15px;
  box-shadow: 0px 4.53399px 4.53399px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: 25px;
  align-items: center;
  cursor: pointer;
  .correlation-icon {
    color: ${grayscale.darkDark};
  }
  .correlation-label {
    font-size: 14px;
    color: ${grayscale.lightDark};
  }
  .correlation-title {
    font-size: 20px;
    font-weight: 700;
    color: ${grayscale.darkDark};
  }
  .correlation-description {
    font-size: 16px;
    color: ${grayscale.darkDark};
  }
`;

const StyledButton = styled.div`
  background: #f9f9f9;
  padding: 8px 15px;
  border-radius: 5px;
  width: fit-content;
  text-align: right;
  margin-left: auto;
  cursor: pointer;
  .content-button-text {
    display: inline-block;
    vertical-align: middle;
  }
  .content-button-icon {
    margin-left: 10px;
    vertical-align: middle;
    &.anatomy {
      color: ${props =>
        props.nerveView == "anatomy" ? green.bright : "lightgray"};
    }
    &.foramina {
      color: ${props =>
        props.nerveView == "foramina" ? green.bright : "lightgray"};
    }
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  display: inline-block;
`;
