import React from "react";

// Dependencies
import { Link, Route } from "react-router-dom";

// Styles
import styled from "styled-components";
import { grayscale, fontFamily, LoadingContainer } from "../Utilities";
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-solid-svg-icons";

export default function CaseCard(props) {
  const { caseObj, collection, collectionUrl, locked } = props;
  if (locked) {
    return (
      <CaseContainer locked>
        <span className="icon">
          <FontAwesomeIcon icon={faLock} />
        </span>
        <span className="title">{caseObj.chief_complaint}</span>
      </CaseContainer>
    );
  }
  return (
    <CaseLink to={`/collection/${collectionUrl}/${caseObj.hashid}`}>
      <CaseContainer>
        <div className="title">{caseObj.chief_complaint}</div>
      </CaseContainer>
    </CaseLink>
  );
}

const CaseContainer = styled.div`
  padding: 25px;
  margin: 5px 0;
  background: ${grayscale.lightGray};
  font-family: ${fontFamily.sansSerif};
  cursor: pointer;
  .title {
    font-size: 16px;
    font-weight: 700;
  }
  .icon {
    margin-right: 15px;
    color: darkgray;
  }
  @media (max-width: 500px) {
    padding: 18px;
    .title {
      font-size: 14px;
    }
  }
  ${props => props.locked && `color:lightgray`}
`;

const CaseLink = styled(Link)`
  text-decoration: none;
  color: black;
`;
