import React, { Fragment } from "react";

// style
import { green, grayscale } from "../../Utilities";

export default function VectorXII(props) {
  const { nerveId } = props;
  return (
    <Fragment>
      <path
        d="M70.7033,508.2489s4.7976,3.58,2.9553,8.4928-2.7127,6.9084-4.58,7.369c-1.8684.4621-5.0923-.3055-7.0881,0-1.9958.3071-9.9789,2.3028-12.8957,3.9915-2.9169,1.69-8.7507,5.0678-10.9,7.9847-2.1493,2.9153-4.6057,5.6787-5.5268,7.9815l-3.07,7.6761,3.3774-18.269a18.5813,18.5813,0,0,1,3.224-3.683c1.8422-1.5352,4.2986-3.07,5.68-4.1451a60.5447,60.5447,0,0,1,6.7549-3.5309,47.8674,47.8674,0,0,0-7.062,1.38,56.5059,56.5059,0,0,0-6.1408,3.07,3.6415,3.6415,0,0,0-1.3817,1.23l1.3817-6.4479a22.1024,22.1024,0,0,1,3.3774-2.9153,14.29,14.29,0,0,1,4.4521-2.1493,19.0812,19.0812,0,0,1,3.531-.9211s-3.3775-1.3817-5.9873-.4621a41.3842,41.3842,0,0,0-4.1835,1.701s1.42-7.5348,1.8807-9.8376a59.71,59.71,0,0,0,.7031-6.1148,18.7061,18.7061,0,0,0,4.21,3.1994c2.1493,1.0732,8.9043,3.8381,8.9043,3.8381S47.56,503.694,45.2572,501.7a45.7413,45.7413,0,0,1-5.22-5.9873s-.1535-3.376-.3071-5.3733c-.1535-1.9957-.1013-6.9975-.1013-6.9975a51.9859,51.9859,0,0,1,6.1409,3.683c2.7634,1.9973,6.9084,6.6029,8.9042,9.0577a47.9176,47.9176,0,0,0,8.1366,7.676,70.6873,70.6873,0,0,0,7.7912,4.25"
        fill={nerveId == "12" ? green.bright : grayscale.gray}
        onClick={() => props.history.push("/nerve/12")}
        style={containerStyle}
      />
      <path
        d="M31.7412,538.5662a19.4237,19.4237,0,0,1,8.482-11.45,47.0019,47.0019,0,0,1,17.5782-6.9207.3236.3236,0,0,0,.2564-.3838.33.33,0,0,0-.3838-.2564,36.4487,36.4487,0,0,0-20.9556,9.2113c-2.8524,2.5852-4.21,6.1531-5.04,9.7869,0,.0123.0123.0384.0261.0384s.0245-.0107.0368-.0261Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/12")}
        style={containerStyle}
      />
      <path
        d="M33.1613,529.163c2.4563-4.26,7.8035-4.9372,12.09-6.2544a.2885.2885,0,0,0,.218-.3347.2759.2759,0,0,0-.3332-.2041c-4.4644,1.1253-9.7746,2.2383-12.0253,6.7533,0,.0277,0,.0384.0123.0538.0138.0015.0384.0015.0384-.0139Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/12")}
        style={containerStyle}
      />
      <path
        d="M34.3127,524.0477c3.3391-7.2431,12.8436-9.52,20.059-7.6008a.3181.3181,0,0,0,.3961-.2287.3442.3442,0,0,0-.23-.3976c-7.4581-2.6989-17.4631.284-20.2771,8.2026h0c-.0123.0122,0,.0261.0123.0368.0153,0,.04,0,.04-.0123Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/12")}
        style={containerStyle}
      />
      <path
        d="M35.1325,518.7236a3.9081,3.9081,0,0,1,2.0848-2.2383c2.2506-1.05,4.6317-1.9467,7.151-1.5629a.2663.2663,0,0,0,.2687-.2548.2792.2792,0,0,0-.2564-.2824,10.8642,10.8642,0,0,0-7.88,2.2122,4.2349,4.2349,0,0,0-1.42,2.0986h0c-.0138.0123,0,.0261.0123.0369.0138.0046.04.0046.04-.0093Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/12")}
        style={containerStyle}
      />
      <path
        d="M39.0841,500.6481c-.8321,2.4057-1.7393,5.1936-.0383,7.5225,1.4968,2.06,4.056,1.9712,6.2559,2.3028a.52.52,0,0,0,.18-1.0239c-2.2123-.499-4.9895-.499-6.1915-2.8417a7.2025,7.2025,0,0,1-.14-5.949c0-.0122,0-.0245-.0261-.0368-.0139,0-.0261,0-.04.0261Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/12")}
        style={containerStyle}
      />
      <path
        d="M38.4962,510.18a19.301,19.301,0,0,0,6.9852.8567.1311.1311,0,0,0,.1274-.14.1416.1416,0,0,0-.1535-.129,19.8651,19.8651,0,0,1-6.9469-.6387h0c-.0122-.0122-.0261,0-.0383.0123,0,.0107.0138.0384.0261.0384Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/12")}
        style={containerStyle}
      />
      <path
        d="M38.9705,496.7579c-.2825,2.1615-.0767,4.9388,1.9958,6.05,4.8482,2.61,9.5828,5.2826,15.0328,6.1147a.4788.4788,0,0,0,.5634-.3838.4661.4661,0,0,0-.3715-.55,44.4177,44.4177,0,0,1-6.9346-2.2015c-2.8263-.9979-5.22-2.7879-8.06-3.6584a5.6883,5.6883,0,0,1-2.1754-5.3609c.0122-.0277,0-.0384-.0261-.0384-.0123.0046-.0246.0169-.0246.0276Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/12")}
        style={containerStyle}
      />
      <path
        d="M39.3789,491.5658c-.3838,6.0748,4.8988,10.3995,9.3909,13.5743a.37.37,0,0,0,.5235-.066.3844.3844,0,0,0-.0645-.5373,40.5379,40.5379,0,0,1-7.8418-7.4335,7.7637,7.7637,0,0,1-1.9574-5.5375h0a.03.03,0,0,0-.0261-.0277.0282.0282,0,0,0-.0246.0277Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/12")}
        style={containerStyle}
      />
      <path
        d="M39.3789,482.7629a8.0936,8.0936,0,0,0,3.2884,9.311c2.2383,2.788,3.85,5.886,6.8824,8.1612,2.1232,1.5874,4.1312,2.943,6.2682,4.3124a.35.35,0,0,0,.499-.0906.3667.3667,0,0,0-.0906-.5112c-4.2725-3.0827-8.5711-6.1669-11.27-10.9752-.8827-1.5736-2.4563-2.3412-3.6077-3.6845a7.4941,7.4941,0,0,1-1.9191-6.5246c.0123-.0154,0-.0277-.026-.0277-.0123-.0077-.0246.0046-.0246.0292Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/12")}
        style={containerStyle}
      />
      <path
        d="M27.9677,559.5219c1.19-3.6339,1.7916-7.42,3.3268-10.9661A41.5707,41.5707,0,0,1,40.34,535.0337c7.088-6.8578,16.5418-11.0274,26.3672-9.9282a9.4239,9.4239,0,0,0,5.6925-4.26c.0123-.0261.0123-.0645-.0122-.0768a.0625.0625,0,0,0-.0768.0123c-1.3955,2.2906-4.2479,3.85-6.8962,2.84-7.1648-.6663-14.0088,1.549-20.2386,5.5528-5.3088,3.3913-8.8536,7.6883-12.1911,12.9863-3.442,5.4731-4.3877,11.31-5.0662,17.36h0c-.0123.0123,0,.0261.0261.0261.0107.0154.023.0031.023-.0245Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/12")}
        style={containerStyle}
      />
      <path
        d="M38.5606,480.3066a4.9252,4.9252,0,0,0,1.9574,4.2095c3.39,2.3535,6.64,4.1082,9.288,7.1787,2.0726,2.3918,3.62,4.951,5.9873,7.0619,2.0849,1.8546,4.0806,3.5448,6.1916,5.3855,2.8662,2.4948,6.41,3.3007,9.595,5.0017,2.596,3.8657,1.8545,9.3141-1.0746,12.8851-1.0992,1.3433-3.1088,2.0449-4.4.7676-.8951-.8966-.7676-2.2123-.5251-3.4665a30.6189,30.6189,0,0,1,2.4686-8.0722c.55-1.1268,1.2159-2.5223,2.6345-2.7649l.4866-.2686-.5879.0261.18.1013c.218.0384.4222.0906.64.1275a.4755.4755,0,0,0,.23-.9227c-.1274-.0492-.2686-.0891-.396-.1382l-.1013-.1028a.5216.5216,0,0,0-.5757.0261l-.3194.0905a3.8725,3.8725,0,0,0-2.4041,1.9851,25.1189,25.1189,0,0,0-3.5448,11.5263,3.3363,3.3363,0,0,0,1.2021,2.943c2.0848,1.572,4.7207.499,6.2053-1.3817,3.2884-4.1451,4.426-10.5914.7154-14.7-3.0827-1.7-6.563-2.4165-9.351-4.8482-2.0986-1.8177-4.0683-3.4665-6.1408-5.3226-2.38-2.1232-3.9286-4.6962-6.0134-7.1-2.943-3.3882-6.6659-5.45-10.5285-7.6761a3.6193,3.6193,0,0,1-1.7148-2.5576c-.0123-.0384-.0507-.0783-.0891-.066s-.0767.0522-.0645.0921l.0492-.02Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/12")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
  cursor: "pointer"
};
