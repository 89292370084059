import React, { Fragment } from "react";

// style
import { green, grayscale } from "../../Utilities";

export default function VectorX(props) {
  const { nerveId } = props;
  return (
    <Fragment>
      <path
        d="M69.4347,507.55s6.7549,1.2267,10.2859,1.0731a102.0161,102.0161,0,0,0,11.9746-1.3817c3.3775-.6125,6.2944-.7676,8.5972-1.0731s5.3732-.7676,7.369-.4621c1.9958.3086,3.3775,1.69,4.2986,4.1466s1.0746,9.3648-.307,11.82-2.1493,4.6056-4.2986,4.7592-11.0535.7676-14.431,1.0761c-3.3775.3055-15.8127,2.6084-19.4972,3.9916s-14.738,5.22-18.7295,7.062a55.0965,55.0965,0,0,0-8.29,4.7607c-1.8423,1.2281-4.6057,3.2239-4.6057,3.2239s1.9958-7.062,3.8381-10.2859,3.07-5.6788,5.22-7.83,3.3774-3.2239,5.9873-3.838a19.9666,19.9666,0,0,1,6.6014-.4606c2.1493.1536,3.2239.1536,4.9126-1.0746a8.3054,8.3054,0,0,0,3.531-5.0662c.6141-2.4579,1.0747-4.6056.3071-6.4479a17.223,17.223,0,0,0-2.7634-3.9931Z"
        fill={nerveId == "10" ? green.bright : grayscale.gray}
        onClick={() => props.history.push("/nerve/10")}
        style={containerStyle}
      />
      <path
        d="M105.6334,507.048c-2.6989,5.49-5.1553,12.3983-1.7532,18.2061,1.01,1.7516,3.2239,2.06,5.0155,1.4569,1.8422-.64,2.6989-2.507,3.4281-4.1942a17.158,17.158,0,0,0,1.6365-9.0854c-.4851-3.5678-1.9957-8.7246-6.46-8.4559h-.1536a.8252.8252,0,0,0-.3577.1152l.0507.0368a5.9487,5.9487,0,0,0-2.7634,3.7121c-1.1775,3.3514-2.6359,6.6889-2.3289,10.2967a7.7479,7.7479,0,0,0,1.5613,4.2356.3932.3932,0,0,0,.525.1275.3738.3738,0,0,0,.1275-.5113,7.5814,7.5814,0,0,1-.9335-5.3732c.7676-3.8749,1.3556-7.9186,3.9916-10.8463l.5757-.3592-.3838.1166.4345-.2195a4.0207,4.0207,0,0,1,2.8017,1.8914,12.3384,12.3384,0,0,1,1.8684,8.4943c-.6018,3.2746-1.2666,6.8578-4.0683,8.8551-1.3311.0905-2.943.0644-3.6722-1.2927a12.3569,12.3569,0,0,1-1.2021-7.2416c.3577-3.4036.4605-6.8808,2.1232-9.9405a.0467.0467,0,0,0-.0123-.0384c-.0261.0031-.0384.0031-.0507.0138h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/10")}
        style={containerStyle}
      />
      <path
        d="M42.0005,547.63c9.8637-8.0476,21.889-12.2049,34.1323-15.8372a94.68,94.68,0,0,1,29.6925-4.1558,8.99,8.99,0,0,0,4.3876-2.5961.19.19,0,0,0,.0384-.27.2074.2074,0,0,0-.2825-.0368,5.7056,5.7056,0,0,1-2.1493.9963c-6.64.6417-12.9341.3086-19.7014,1.6519a129.8574,129.8574,0,0,0-17.7055,4.426c-5.3088,1.8407-10.4134,3.76-15.53,6.05-5.0278,2.2521-9.9528,4.86-13.5866,9.0685a.5428.5428,0,1,0,.7676.7676L42,547.63Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/10")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
    cursor: "pointer"
}
