import React, { Fragment } from "react";

export default function OpticCanal(props) {
  const { nerveId, foramenId, green, grayscale } = props;
  return (
    <Fragment>
      <path
        d="M42.2751,248.0771s2.8837-3.1628,5.9534-2.4186,6.2326,2.5116,7.4419,6.4186,1.4883,7.8139,1.1163,8.279a5.7549,5.7549,0,0,1-5.8605,1.4884C47.3913,261.0073,41.3448,258.7747,42.2751,248.0771Z"
        fill={nerveId == "2" || foramenId == "optic-canal" ? green.bright : grayscale.gray}
        onClick={() => props.history.push("/foramina/optic-canal")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
  cursor: "pointer"
};
