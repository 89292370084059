import React, { Fragment } from "react";

export default function CribiformPlate(props) {
  const { nerveId, green, grayscale, foramenId } = props;
  return (
    <Fragment>
      <path
        d="M3.7315,116.6392S6.15,118.081,6.6152,119.43s1.2558,3.3489,1.0232,8.4651-.3721,7.721-1.07,9.6744a22.2314,22.2314,0,0,0-.8838,4.93,19.1385,19.1385,0,0,0-.279,2.6511c.0465.6977.279,13.3023.279,13.3023a23.7237,23.7237,0,0,0,.14,3.8605c.2325.7442,1.1628,9.0232-.14,12.372a13.3223,13.3223,0,0,0-.6976,4.2791s.4651,4.6046-.2791,6.3721c-.3721,1.0232-.1395,3.8139-.1395,4.3721a12.562,12.562,0,0,1-1.1163,3.5348c-.3256.3721-.7907,1.7675-.3721,2.3721a19.4416,19.4416,0,0,0,1.907,1.9535,18.5178,18.5178,0,0,1,2.0465,2.5582c.279.6511,2.4186,3.2093,3.5349,2.5581a4.7428,4.7428,0,0,0,1.9534-2.93,5.2961,5.2961,0,0,1,.7442-2.5582,3.4576,3.4576,0,0,1,2.3256-.8372c.8372.0465,3.2558.1861,3.907-.1395a5.1984,5.1984,0,0,0,2.0465-3.2558,10.88,10.88,0,0,0,0-4.7442c-.4186-1.3953-1.6744-4.6046-1.814-5.0232a11.9059,11.9059,0,0,1-.93-3.4419,24.5425,24.5425,0,0,1-.0465-4.8837c.2791-1.3023,1.2558-5.6744,1.3023-6.5581a49.4228,49.4228,0,0,0-.8837-7.1628c-.2791-.6512-2.0465-6.93-2.3256-7.3488a8.9419,8.9419,0,0,1-.93-2.7907c-.0465-.7442.1395-11.07.1395-11.07a24.1735,24.1735,0,0,0-1.1163-6.9767,20.1821,20.1821,0,0,1-.7907-3.7674,45.2642,45.2642,0,0,0-1.3953-4.3256c-.2791-.5581-2.4651-4.3721-2.4186-6.2326a91.2049,91.2049,0,0,0-.5116-10,12.298,12.298,0,0,0-1.1628-2.9768C8.3826,105.3368,9.4989,111.4764,3.7315,116.6392Z"
        opacity="0.3"
        fill={nerveId == "1" || foramenId == "cribiform-plate" ? green.bright : grayscale.gray}
        style={containerStyle}
        onClick={() => props.history.push("/foramina/cribiform-plate")}
      />
      <ellipse
        cx="12.9664"
        cy="140.6819"
        rx="1.7442"
        ry="1.8668"
        transform="translate(-126.7805 119.9377) rotate(-76.2906)"
        fill={nerveId == "1" || foramenId == "cribiform-plate" ? green.bright : grayscale.gray}
        style={containerStyle}
        onClick={() => props.history.push("/foramina/cribiform-plate")}
      />
      <ellipse
        cx="11.121"
        cy="155.7756"
        rx="1.7216"
        ry="1.5069"
        transform="translate(-145.0506 156.1253) rotate(-86.0448)"
        fill={nerveId == "1" || foramenId == "cribiform-plate" ? green.bright : grayscale.gray}
        style={containerStyle}
        onClick={() => props.history.push("/foramina/cribiform-plate")}
      />
      <ellipse
        cx="10.2979"
        cy="177.5627"
        rx="2.0578"
        ry="1.4684"
        transform="translate(-166.2442 155.9425) rotate(-79.6988)"
        fill={nerveId == "1" || foramenId == "cribiform-plate" ? green.bright : grayscale.gray}
        style={containerStyle}
        onClick={() => props.history.push("/foramina/cribiform-plate")}
      />
      <path
        d="M10.5077,131.1471c.381-.4483,1.1628.3256,1.2558.8372a7.8237,7.8237,0,0,1-.2326,2.6511c-.186.2326-.93.5582-1.2093.4187S9.717,132.0773,10.5077,131.1471Z"
        fill={nerveId == "1" || foramenId == "cribiform-plate" ? green.bright : grayscale.gray}
        style={containerStyle}
        onClick={() => props.history.push("/foramina/cribiform-plate")}
      />
      <path
        d="M8.4612,143.054c.9507-.8912,1.5348-.4651,2.14-.0465a1.3693,1.3693,0,0,1,.3721,1.7209c-.2791.3256-1.4419,1.4419-2.3721,1.2559S7.717,143.7517,8.4612,143.054Z"
        fill={nerveId == "1" || foramenId == "cribiform-plate" ? green.bright : grayscale.gray}
        style={containerStyle}
        onClick={() => props.history.push("/foramina/cribiform-plate")}
      />
      <path
        d="M9.624,148.3563c.3616-.7748,1.86-.093,1.86-.093s.7907.6512.6512,1.2558-1.2558,1.4884-1.7675,1.3489A1.7637,1.7637,0,0,1,9.624,148.3563Z"
        fill={nerveId == "1" || foramenId == "cribiform-plate" ? green.bright : grayscale.gray}
        style={containerStyle}
        onClick={() => props.history.push("/foramina/cribiform-plate")}
      />
      <path
        d="M10.88,161.054c.93-1.1628,1.9069-1.0233,2.279-.7907a1.7249,1.7249,0,0,1,.1861,1.7674c-.2791.3256-1.1628,2.0931-2.2791,1.9535S10.88,161.054,10.88,161.054Z"
        fill={nerveId == "1" || foramenId == "cribiform-plate" ? green.bright : grayscale.gray}
        style={containerStyle}
        onClick={() => props.history.push("/foramina/cribiform-plate")}
      />
      <path
        d="M10.88,166.6819a1.9134,1.9134,0,0,1,1.9535-.6512c.372.0931,1.7209.6977,1.4418,1.814a3.5986,3.5986,0,0,1-1.907,2.279,3.79,3.79,0,0,1-1.5814.14A2.6041,2.6041,0,0,1,10.88,166.6819Z"
        fill={nerveId == "1" || foramenId == "cribiform-plate" ? green.bright : grayscale.gray}
        style={containerStyle}
        onClick={() => props.history.push("/foramina/cribiform-plate")}
      />
      <path
        d="M12.74,174.31a2.418,2.418,0,0,1,2.1861-2.4651,1.7042,1.7042,0,0,1,1.6744,1.9069,2.8935,2.8935,0,0,1-1.9535,2.5117C13.6239,176.4493,12.9263,175.7051,12.74,174.31Z"
        fill={nerveId == "1" || foramenId == "cribiform-plate" ? green.bright : grayscale.gray}
        style={containerStyle}
        onClick={() => props.history.push("/foramina/cribiform-plate")}
      />
      <path
        d="M13.81,181.147a1.5174,1.5174,0,0,1,2.6046.6046,4.83,4.83,0,0,1-.5581,3.4419,1.5229,1.5229,0,0,1-2.093-.093A4.699,4.699,0,0,1,13.81,181.147Z"
        fill={nerveId == "1" || foramenId == "cribiform-plate" ? green.bright : grayscale.gray}
        style={containerStyle}
        onClick={() => props.history.push("/foramina/cribiform-plate")}
      />
      <path
        d="M8.6937,184.17a1.8805,1.8805,0,0,1,2.8837,1.3024,7.2121,7.2121,0,0,1-1.8139,3.6279c-.5116.279-1.5349.4186-1.8605-.4187S7.903,184.6819,8.6937,184.17Z"
        fill={nerveId == "1" || foramenId == "cribiform-plate" ? green.bright : grayscale.gray}
        style={containerStyle}
        onClick={() => props.history.push("/foramina/cribiform-plate")}
      />
      <path
        d="M12.88,189.4725c.6976-1.4418,1.2558-1.4883,1.7674-1.2558a2.208,2.208,0,0,1,1.2093,2.14,2.9817,2.9817,0,0,1-2.093,2c-.6977,0-.9768-.3721-1.07-1.2093A4.4776,4.4776,0,0,1,12.88,189.4725Z"
        fill={nerveId == "1" || foramenId == "cribiform-plate" ? green.bright : grayscale.gray}
        style={containerStyle}
        onClick={() => props.history.push("/foramina/cribiform-plate")}
      />
      <path
        d="M8.0891,193.1935a1.8824,1.8824,0,0,1,3.1162.5581,2.513,2.513,0,0,1-.6976,2.7907c-.7442.4186-1.814.4186-2.14-.093S7.5309,193.8911,8.0891,193.1935Z"
        fill={nerveId == "1" || foramenId == "cribiform-plate" ? green.bright : grayscale.gray}
        style={containerStyle}
        onClick={() => props.history.push("/foramina/cribiform-plate")}
      />
    </Fragment>
  );
}

const containerStyle = {
  cursor: "pointer"
};
