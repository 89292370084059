import React, { Fragment } from "react";

// style
import { green, grayscale } from "../../Utilities";

export default function VectorVIII(props) {
  const { nerveId } = props;
  return (
    <Fragment>
      <path
        d="M56.7713,493.7136a32.25,32.25,0,0,0,6.9084-3.2255c2.3028-1.6888,7.062-4.9112,9.0562-6.7534a70.5836,70.5836,0,0,1,7.6761-6.2959c2.9169-1.9943,8.7507-5.0647,11.6676-7.214s6.7549-5.8353,9.5183-7.83c2.7634-1.9973,5.5267-4.3,7.9831-5.9873s5.9873-3.2239,8.9042-1.6887,6.1408,3.5295,6.9084,6.1408c.7676,2.6084,1.9451,4.8605,1.0486,6.8056a24.3418,24.3418,0,0,1-5.5007,6.5508c-1.9957,1.6887-7.2154,5.5267-9.5183,7.2155s-4.7591,3.838-6.7549,5.5283c-1.9958,1.6887-2.7634,3.838-5.22,4.9111a63.8156,63.8156,0,0,1-13.2028,3.224,125.9059,125.9059,0,0,1-15.1986.9227c-3.9915-.1536-7.676-.7676-9.5183-.9227a20.7563,20.7563,0,0,1-4.7576-1.3817Z"
        fill={nerveId == "8" ? green.bright : grayscale.gray}
        onClick={() => props.history.push("/nerve/8")}
        style={containerStyle}
      />
      <path
        d="M111.8393,456.1377c-1.0869,2.2031-.6909,4.8759.0767,7.1388,2.3151,3.4158,5.272,6.7549,9.5828,7.7282a4.8858,4.8858,0,0,0,4.7715-1.1882c2.84-3.1088,1.0362-7.3706-.8951-10.5178a12.4161,12.4161,0,0,0-10.682-5.8983,4.3714,4.3714,0,0,0-4.2986,3.0966c-.9595,3.62,1.0363,6.8961,3.6584,9.1851a.147.147,0,0,0,.218.0123.1574.1574,0,0,0,.0123-.218,13.1306,13.1306,0,0,1-2.3028-3.967c-.64-1.9682-1.3694-4.81.8444-6.0871,2.137-1.23,4.5933-.3086,6.6137.5634a11.3228,11.3228,0,0,1,6.8316,11.1671c-.2425,2.4579-3.07,3.1472-5.1429,2.4686a15.079,15.079,0,0,1-8.0982-6.68c-1.2282-1.9835-2.3919-4.58-1.1392-6.781.0123-.0123,0-.0384-.0123-.0384-.0122-.0077-.0383.0046-.0383.0154Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/8")}
        style={containerStyle}
      />
      <path
        d="M91.3964,494.2524a11.1043,11.1043,0,0,0,4.7592-.4222c6.8562-2.3934,11.1947-7.9693,16.8489-12.4736,5.0907-4.0683,11.2454-7.0236,13.9965-12.9986a.5067.5067,0,0,0-.218-.6893.52.52,0,0,0-.6908.2195c-3.3514,5.2566-8.545,8.3777-13.6373,12.1911-3.7106,2.788-6.7288,5.8584-10.0433,8.8659-3.4543,2.0986-6.9346,4.59-11.0152,5.1936a.0891.0891,0,1,0,0,.1781v-.0645Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/8")}
        style={containerStyle}
      />
      <path
        d="M49.2042,489.0066a5.8722,5.8722,0,0,0,3.9394,4.4505c4.7975,1.4984,9.2757-.6125,13.2289-3.5186,4.8866-3.5817,8.6862-7.2923,13.5728-10.8739,6.0011-4.4,12.0253-7.2555,17.6672-11.9624,5.27-4.4014,9.9527-8.8919,16.0045-12.0621a6.4507,6.4507,0,0,1,3.6078-.4376c2.1493.1674,3.8,1.5107,5.3855,2.8417a.3155.3155,0,0,0,.4606-.0368.3331.3331,0,0,0-.0384-.4759c-1.01-.7031-1.5475-1.8162-2.5715-2.5315a7.4,7.4,0,0,0-7.5747-.4744c-6.23,3.098-10.9123,7.84-16.3761,12.2817-4.707,3.838-9.48,6.5369-14.649,9.8514-6.0257,3.8518-10.3626,8.4575-16.17,12.6517-3.2362,2.33-6.5507,4.3123-10.619,3.81a7.8718,7.8718,0,0,1-5.7049-3.5171.1645.1645,0,0,0-.23-.0507.1673.1673,0,0,0-.0522.23l.12-.1765Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/8")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
  cursor: "pointer"
};
