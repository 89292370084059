export const cranialData = {
  data: {
    cranialNerves: [
      {
        id: "1",
        numeral: "I",
        name: "Olfactory",
        functionType: "Sensory",
        functionMotor: "",
        functionSensory: "<div>Smell (olfaction)</div>",
        origin: "Cerebrum (brain)",
        foramina: "Cribiform Plate",
        components: "Special Visceral Afferent",
        anatomy: [
          {
            divisionTitle: "",
            divisionDesc:
              "Olfactory neurons originate in the olfactory epithelium in the superior part of the lateral and septal walls of the nasal cavity. The nerves ascend through the cribriform foramina of the ethmoid bone to reach the olfactory bulbs. The olfactory neurons synapse with neurons in the bulbs, which course to the primary and association areas of the cerebral cortex.",
            divisionPoints: []
          }
        ],
        testing: [
          {
            function: "Smell",
            test:
              "The ability to smell is tested by asking the person to identify items with very specific odors (such as soap, coffee, and cloves) placed under the nose. Each nostril is tested separately."
          }
        ],
        disorders: [
          {
            pathology: "Anosmia",
            description:
              "Anosmia is the inability to perceive odor or a lack of functioning olfaction—the loss of the sense of smell. Anosmia may be temporary, but some forms such as those from an accident can be permanent. Anosmia is due to a number of factors, including an inflammation of the nasal mucosa, blockage of nasal passages or a destruction of one temporal lobe. Inflammation is due to chronic mucosa changes in the paranasal sinus lining and the middle and superior turbinates.",
            causes: [
              "Head trauma",
              "Nasal disorders (eg, allergic rhinitis)",
              "Neurodegenerative disorders (eg, Alzheimer disease, Parkinson disease)",
              "Paranasal sinusitis",
              "Tumors of the cranial fossa, nasal cavity, and paranasal sinuses"
            ],
            clinicalCorrelations: [
              {
                id: "QBeXnoby",
                title: "Unconscious after exposure to natural gas",
                description:
                  "An 87-year-old woman is brought to the emergency department after she is found unconscious inside her home."
              }
            ]
          }
        ]
      },
      {
        id: "2",
        numeral: "II",
        name: "Optic",
        functionType: "Sensory",
        functionMotor: "",
        functionSensory: "<div>Vision</div>",
        origin: "Cerebrum (brain)",
        foramina: "Optic Canal",
        components: "Special Somatic Afferent",
        anatomy: [
          {
            divisionTitle: "",
            divisionDesc:
              "Visual information from each retina courses to the optic nerve, optic chiasma, optic tract, thalamus (lateral geniculate nucleus), and optic radiations to terminate in the primary visual cortex of the occipital lobe.",
            divisionPoints: [],
            clinicalCorrelations: [
              {
                id: "y5eVlObE",
                title: "Vision changes after fall",
                description:
                  "A 65-year-old man with a history of hypertension, hyperlipidemia, and myocardial infarction is brought to the emergency department after tripping down the stairs this morning."
              }
            ]
          },
          {
            divisionTitle: "Optic nerve (CN II)",
            divisionDesc:
              "Formed by the axons of ganglion cells of the retina that converge at the optic disc; conveys visual information from the retina, leaves the orbit by way of the optic canal, and courses to the optic chiasm.",
            divisionPoints: []
          },
          {
            divisionTitle: "Optic chiasm",
            divisionDesc:
              "Each optic nerve carries axons from the entire retina of the eye. However, after coursing through their respective optic canals, the right and left optic nerves engage in a redistribution of axons at the optic chiasma, located just anterior to the pituitary stalk. The optic chiasma is created by neurons from the nasal half of each retina crossing over to the opposite side.",
            divisionPoints: []
          },
          {
            divisionTitle: "Optic tracts",
            divisionDesc:
              "The two optic tracts emerge from the optic chiasma and course to the associated lateral geniculate nucleus of the thalamus. The right optic tract contains axons from the temporal half of the right retina and the nasal half of the left retina. The left optic tract contains neurons from the temporal half of the left retina and the nasal half of the right retina.",
            divisionPoints: []
          }
        ],
        testing: [
          {
            function: "Vision",
            test:
              "The ability to see is tested by asking the person to read an eye chart. Peripheral (side) vision is tested by asking the person to look straight ahead while the practitioner gradually moves a finger toward the person's center of vision from above, below, left, and right. The person is then asked to say when the finger is first seen."
          },
          {
            function: "Detection of light",
            test:
              "The ability to detect light is tested by shining a bright light (as from a flashlight) into each pupil in a darkened room."
          }
        ],
        disorders: [
          {
            pathology: "Optic neuritis",
            description:
              "Optic neuritis is an inflammatory, demyelinating condition that causes acute, usually monocular, visual loss. It is highly associated with multiple sclerosis (MS), occurring in 50 percent of individuals at some time during the course of their illness.",
            subtitle: "(papillitis and retrobulbar)",
            causes: [
              "Uveitis",
              "Acute demyelinating disease (eg, multiple sclerosis, neuromyelitis optica)",
              "Bacterial infections (eg, TB, syphilis, Lyme disease)",
              "Postinfectious or disseminated encephalomyelitis",
              "Viral infections (eg, HIV, herpes simplex, hepatitis B, cytomegalovirus)"
            ],
            clinicalCorrelations: [
              {
                id: "YqaQ5Zan",
                title: "Recent onset of vision loss",
                description:
                  "A 32-year-old woman presents with recent onset of vision loss in her right eye. She reports that a few months ago she had a similar episode but it resolved in a few days and she did not seek medical attention."
              }
            ]
          },
          {
            pathology: "Toxic-nutritional optic neuropathy",
            description:
              "Nutritional optic neuropathy can be defined by visual impairment due to optic nerve damage secondary to nutritional deficiency. Nutritional optic neuropathy occurs mainly due to vitamin deficiency. Deficiency of thiamine (vitamin B1), cyanocobalamin (vitamin B12), pyridoxine (vitamin B6), niacin (vitamin B3), riboflavin (vitamin B2), and/or folic acid have all been implicated",
            subtitle: "(toxic amblyopia)",
            causes: [
              "Drugs (chloramphenicol, ethambutol, isoniazid, streptomycin, sulfonamides, digitalis, chlorpropamide, ergot, disulfiram)",
              "Methanol ingestion",
              "Nutritional deprivation if severe",
              "Organic mercury",
              "Vitamin B12 deficiency"
            ],
            clinicalCorrelations: []
          },
          {
            pathology: "Bitemporal hemianopia",
            description:
              "Bitemporal hemianopia is a type of partial blindness where vision is missing in the outer half of both the right and left visual field.",
            causes: [
              "Craniopharyngioma",
              "Meningioma of tuberculum sellae",
              "Saccular aneurysm in the cavernous sinus",
              "Suprasellar extension of pituitary adenoma"
            ],
            clinicalCorrelations: []
          },
          {
            pathology: "Anterior ischemic optic neuropathy",
            description:
              "Anterior ischemic optic neuropathy (AION) is a sudden loss of vision due to an interruption of blood flow to the front (anterior) of the optic nerve",
            causes: [
              "Crowded optic disk morphology (called disk at risk)",
              "Complications after cataract extraction",
              "Connective tissue disease that causes arteritis (eg, giant cell [temporal] arteritis, antiphospholipid antibody syndrome)",
              "Diabetes",
              "Hypotension or hypovolemia if severe",
              "Ipsilateral internal carotid artery obstruction",
              "Phosphodiesterase type 5 (PDE5) inhibitors (eg, sildenafil, tadalafil, vardenafil)",
              "Retinal artery embolism"
            ],
            clinicalCorrelations: []
          }
        ]
      },
      {
        id: "3",
        numeral: "III",
        name: "Occulomotor",
        functionType: "Motor",
        functionMotor:
          "<div>Eye Movement</div>" +
          "<div>Eyelid Elevation</div>" +
          "<div>Pupillary Constriction</div>" +
          "<div>Lens Accommodation</div>",
        functionSensory: "",
        origin: "Midbrain",
        foramina: "Superior Orbital Fissure",
        components:
          "<div>General Somatic Efferent</div>" +
          "<div>General Visceral Efferent</div>",
        anatomy: [
          {
            divisionTitle: "",
            divisionDesc:
              "Upon exiting the midbrain, the oculomotor nerve courses between the posterior cerebral and superior cerebellar arteries to run along the lateral wall of the cavernous sinus, superior to CN IV. CN III enters the orbit through the superior orbital fissure, where CN III divides into superior and inferior divisions.",
            divisionPoints: [
              "Somatic motor neurons. CN III innervates four of the six extraocular muscles (superior rectus, medial rectus, inferior rectus, and inferior oblique) and the levator palpebrae superioris muscle. The somatic motor component of CN III plays a major role in controlling the muscles responsible for the precise movements of the eyes.",

              "Visceral motor neurons. Preganglionic parasympathetic neurons of CN III originate in the Edinger–Westphal nucleus and synapse in the ciliary ganglion, providing innervation to the ciliary body (lens accommodation) and sphincter pupillae muscle (pupil constriction)."
            ]
          }
        ],
        testing: [
          {
            function: "Eye movement (upward, downward, and inward)",
            test:
              "The ability to move each eye up, down, and inward is tested by asking the person to follow a target moved by the practitioner."
          },
          {
            function: "Pupillary Constriction/Dilation",
            test:
              "The pupils’ response to light is checked by shining a bright light (as from a flashlight) into each pupil in a darkened room."
          },
          {
            function: "Eyelid Elevation",
            test: "The upper eyelid is checked for drooping (ptosis)."
          }
        ],
        disorders: [
          {
            pathology: "Occulomotor Nerve Palsy",
            description:
              "These palsies can occur when pressure is put on the nerve or the nerve does not get enough blood. People have double vision when they look in a certain direction, the eyelid droops, and the pupil may be widened (dilated).",
            causes: [
              "Aneurysm of posterior communicating artery",
              "Ischemia of the 3rd cranial nerve (often due to small-vessel disease as occurs in diabetes) or its fascicle in the midbrain",
              "Transtentorial herniation due to intracranial mass (eg, subdural hematoma, tumor, abscess)"
            ],
            clinicalCorrelations: [
              {
                id: "YQdJ7veO",
                title: "Worsening Headache",
                description:
                  "A 77-year-old female who was recently diagnosed with a brain tumor prents for worsening headaches."
              }
            ]
          }
        ]
      },
      {
        id: "4",
        numeral: "IV",
        name: "Trochlear",
        functionType: "Motor",
        functionMotor: "<div>Eye Movement</div>",
        functionSensory: "",
        origin: "Midbrain",
        foramina: "Superior Orbital Fissure",
        components: "<div>General Somatic Efferent</div>",
        anatomy: [
          {
            divisionTitle: "",
            divisionDesc:
              "The trochlear nerve is the only cranial nerve that originates from the dorsal aspect of the brainstem. CN IV courses around the brainstem in the free edge of the tentorium cerebelli, through the lateral wall of the cavernous sinus, and enters the orbit via the superior orbital fissure. The trochlear nerve supplies somatic motor innervation to the superior oblique muscle, which causes the eyeball to move down and out.",
            divisionPoints: []
          }
        ],
        testing: [
          {
            function: "Eye movement (downward and inward)",
            test:
              "The ability to move each eye down and inward is tested by asking the person to follow a target moved by the practitioner."
          }
        ],
        disorders: [
          {
            pathology: "Trochlear Nerve Palsy",
            description:
              "Fourth cranial nerve palsy may affect one or both eyes. Because the superior oblique muscle is paretic, the eyes do not adduct normally. Patients see double images, one above and slightly to the side of the other; thus, going down stairs, which requires looking down and inward, is difficult. However, tilting the head to the side opposite the palsied muscle can compensate and eliminate the double images.",
            causes: [
              "Often idiopathic",

              "Head trauma",

              "Infarction often due to small-vessel disease (eg, in diabetes)",

              "Tentorial meningioma",

              "Pinealoma"
            ],
            clinicalCorrelations: []
          },
          {
            pathology: "Superior Oblique Myokymia",
            description:
              "Superior Oblique Myokymia (SOM) is a rare condition, characterized by episodes of sudden, rhythmic, monocular contractions of the superior oblique muscle. As a result, patients experience oscillopsia and diplopia, particularly when vision is directed downwards. SOM does not preferentially affect one age group, but right-sided SOM has been found statistically more prevalent in females than left-sided SOM.",
            causes: [
              "Entrapment of the trochlear nerve by a vascular loop (similar to the pathophysiology of trigeminal neuralgia)"
            ],
            clinicalCorrelations: []
          }
        ]
      },
      {
        id: "5",
        numeral: "V",
        name: "Trigeminal",
        functionType: "Both",
        functionSensory:
          "<div>Facial Sensation (V1-3)</div>" +
          "<div>Somatosensation to anterior 2/3 of tongue</div>",
        functionMotor: "<div>Muscles of Mastication</div>",
        origin: "Pons",
        foramina:
          "<div>V1 - Superior orbital fissure</div>" +
          "<div> V2 - Foramen rotundum</div>" +
          "<div>V3 - Foramen ovale & spinosum</div>",
        components:
          "<div>General Somatic Afferent (V1, V2, V3)</div>" +
          "<div>Special Visceral Efferent</div>",
        anatomy: [
          {
            divisionTitle: "",
            divisionDesc:
              "CN V originates from the lateral surface of the pons as a large sensory root and a smaller motor root. These roots enter the trigeminal (Meckel's) cave of the dura, lateral to the body of the sphenoid bone and the cavernous sinus. The trigeminal ganglion gives rise to three divisions, named for the cranial location to the eyes (ophthalmic), the maxilla (maxillary), and the mandible (mandibular).",
            divisionPoints: [
              "Sensory. Sensory neurons within CN V lead to the trigeminal (semilunar) ganglion, which houses the cell bodies for the general sensory neurons.",

              "Motor. The motor root runs parallel to the sensory root, bypassing the ganglion and becoming part of the mandibular nerve (CN V-3).",

              "Transporting visceral motor neurons. CN V also aids in distributing visceral motor (parasympathetic) neurons of the head to their destinations for CNN III, VII, and IX."
            ]
          },
          {
            divisionTitle: "CN V-1: Ophthalmic Division",
            divisionDesc:
              "CN V-1 courses along the lateral wall of the cavernous sinus and enters the orbit via the superior orbital fissure. CN V-1 provides general sensory innervation to the orbit, cornea, and the skin of the bridge of the nose, scalp, and forehead (above the lateral corners of the eye).",
            divisionPoints: []
          },
          {
            divisionTitle: "CN V-2: Maxillary Division",
            divisionDesc:
              "CN V-2 passes through the lateral wall of the cavernous sinus and through the foramen rotundum into the pterygopalatine fossa. This nerve provides general sensory innervation to the skin between the lateral corners of the eye and corners of the mouth. Additionally CN V-2 provides general sensory innervation to the palate, nasal cavity, paranasal sinuses and the maxillary teeth and gums.",
            divisionPoints: []
          },
          {
            divisionTitle: "CN V-3: Mandibular Division",
            divisionDesc:
              "CN V-3 courses through the foramen ovale into the infratemporal fossa.",
            divisionPoints: [
              "Sensory. CN V-3 provides general sensory innervation to the lower part of the face (below the lateral corners of the mouth), including the anterior two-thirds of the tongue, the mandibular teeth, the mandibular face, and even part of the scalp.",

              "Motor. CN V-3 provides general sensory innervation to the skin of the face below the lateral corners of the mouth as well as the anterior two-thirds of the tongue, the mandibular teeth and lateral sides of the scalp."
            ],
            clinicalCorrelations: [
              {
                id: "APdRwYeG",
                title: "Blister of the lip",
                description:
                  "A 42-year-old woman develops a painful blister over the vermilion of her upper lip."
              }
            ]
          }
        ],
        testing: [
          {
            function: "Facial sensation",
            test:
              "Sensation in areas of the face is tested using a pin and a wisp of cotton. The blink reflex is tested by touching the cornea of the eye with a cotton wisp."
          },
          {
            function: "Chewing",
            test:
              "Strength and movement of muscles that control the jaw are tested by asking the person to clench the teeth and open the jaw against resistance."
          }
        ],
        disorders: [
          {
            pathology: "Trigeminal Neuralgia",
            description:
              "Trigeminal neuralgia is severe paroxysmal, lancinating facial pain due to a disorder of the 5th cranial nerve. Pain occurs along the distribution of one or more sensory divisions of the trigeminal nerve, most often the maxillary. The pain is paroxysmal, lasting seconds up to 2 min, but attacks may recur rapidly. It is lancinating, excruciating, and sometimes incapacitating. Pain is often precipitated by stimulating a facial trigger point (eg, by chewing, brushing the teeth, or smiling). Sleeping on that side of the face is often intolerable. Usually, only one side of the face is affected.",
            causes: [
              "Compression by an intracranial artery (eg, anterior inferior cerebellar artery, ectatic basilar artery)",
              "Less often, a venous loop that compresses the 5th cranial (trigeminal) nerve at its root entry zone into the brain stem"
            ],
            clinicalCorrelations: []
          }
        ]
      },
      {
        id: "6",
        numeral: "VI",
        name: "Abducens",
        functionType: "Motor",
        functionMotor: "<div>Eye Abduction</div>",
        functionSensory: "",
        origin: "Pontomedullary Region",
        foramina: "Superior Orbital Fissure",
        components: "<div>General Somatic Efferent</div>",
        anatomy: [
          {
            divisionTitle: "",
            divisionDesc:
              "The abducens nerve originates from the pons and courses through the cavernous sinus, entering the orbit via the superior orbital fissure. The abducens nerve supplies somatic motor innervation to the lateral rectus muscle, which abducts the eye.",
            divisionPoints: []
          }
        ],
        testing: [
          {
            function: "Eye movement (outward)",
            test:
              "The ability to move each eye outward beyond the midline is tested by asking the person to look to the side."
          }
        ],
        disorders: [
          {
            pathology: "Abducens Nerve Palse",
            description:
              "Sixth cranial nerve palsy affects the lateral rectus muscle, impairing eye abduction. The eye may be slightly adducted when the patient looks straight ahead. Determining the cause requires MRI and often lumbar puncture and evaluation for vasculitis.",
            causes: [
              "Often idiopathic",
              "Head trauma",
              "Increased intracranial pressure",
              "Infarction (may be mononeuritis multiplex)",
              "Infections or tumors affecting the meninges",
              "Multiple sclerosis",
              "Nasopharyngeal carcinoma",
              "Pontine or cerebellar tumors",
              "Pontine infarction",
              "Wernicke encephalopathy"
            ],
            clinicalCorrelations: [
              {
                id: "olejYWdj",
                title: "Headache and double vision",
                description:
                  "A 66 year old male presents for headache and fever. He reports his symptoms started getting worse after being diagnosed with a sinus infection recently."
              }
            ]
          }
        ]
      },
      {
        id: "7",
        numeral: "VII",
        name: "Facial",
        functionType: "Both",
        functionMotor:
          "<div>Facial Expression, Lacrimation & Salivation</div>" +
          "<div>Eyelid closing</div>" +
          "<div>Stapedius</div>",
        functionSensory: "<div>Taste to anterior 2/3 of tongue</div>",
        origin: "Pontomedullary Region",
        foramina: "Internal acoustic meatus",
        components:
          "<div>General Somatic Afferent</div>" +
          "<div>General Visceral Afferent</div>" +
          "<div>Special Visceral Afferent</div>" +
          "<div>General Visceral Efferent</div>" +
          "<div>Special Visceral Efferent</div>",
        anatomy: [
          {
            divisionTitle: "",
            divisionDesc:
              "The facial nerve traverses the internal acoustic meatus carrying four modalities. Two distinct fascial sheaths package the four modalities carried by CN VII, with branchial motor neurons in one sheath (facial nerve proper) and visceral motor, special sensory, and general sensory neurons in another sheath (nervus intermedius). The nervous intermedius contains the axons destined for the greater petrosal and chorda tympani nerves.",
            divisionPoints: [
              "Branchial motor neurons. Supply muscles derived from the second branchial arch, including the muscles of facial expression as well as the stapedius, posterior belly of the digastricus, and stylohyoid muscles via the facial nerve proper.",

              "Visceral motor neurons. Provide parasympathetic innervation to most glands in the head, including the lacrimal and nasopalatal glands (via the greater petrosal nerve) and submandibular and sublingual glands (via the chorda tympani nerve). An exception is the parotid gland, which receives its parasympathetic innervation from CN IX.",

              "Special sensory neurons. Transmit taste sensation from the anterior two-thirds of the tongue via the chorda tympani nerve.",

              "General sensory neurons. Transmit general sensation from a portion of the external acoustic meatus and auricle."
            ]
          },
          {
            divisionTitle: "Facial Nerve Proper",
            divisionDesc:
              "The branchial motor components constitute the largest portion of CN VII. After entering the temporal bone via the internal acoustic meatus, a small branch of CN VII courses to the stapedius muscle, and the rest of the branchial motor neurons course through the facial canal to exit the skull via the stylomastoid foramen. In the parotid gland, five terminal branches (i.e., temporal, zygomatic, buccal, mandibular, and cervical) provide voluntary control of the muscles of facial expression, including the buccinator, occipitalis, platysma, posterior digastricus, and stylohyoid muscles.",
            divisionPoints: []
          },
          {
            divisionTitle: "Greater Petrosal Nerve",
            divisionDesc:
              "Contains preganglionic parasympathetic neurons that synapse in the pterygopalatine ganglion and innervate the lacrimal glands (produces tears that wash the eye) and palatonasal glands (produces mucus to line the nasal cavity and palate).",
            divisionPoints: []
          },
          {
            divisionTitle: "Chorda Tympani Nerve",
            divisionDesc:
              "Arises in the descending part of the facial canal and crosses the medial aspect of the tympanic membrane, passing between the malleus and incus. The chorda tympani exits the skull through the petrotympanic fissure and joins the lingual nerve from CN V-3 in the infratemporal fossa. The chorda tympani nerve contains the following:",
            divisionPoints: [
              "Preganglionic parasympathetic neurons that synapse in the submandibular ganglion en route to innervate the submandibular and the sublingual salivary glands (produces saliva).",

              "Special sensory neurons (taste) from the anterior two-thirds of the tongue, with cell bodies located in the geniculate ganglion."
            ]
          },
          {
            divisionTitle: "Auricular Branches",
            divisionDesc:
              "Arise from the external acoustic meatus and auricle and carry general sensory neurons through the geniculate ganglion to the brainstem.",
            divisionPoints: [
              "Geniculate ganglion. A knee-shaped ganglion in CN VII, located within the temporal bone and housing sensory cell bodies for the special sensory neurons for taste and general sensory neurons from the ear."
            ]
          }
        ],
        testing: [
          {
            function: "Facial Expressions",
            test:
              "The ability to move the face is tested by asking the person to smile, to open the mouth and show the teeth, and to close the eyes tightly."
          },
          {
            function: "Taste (Anterior 2/3 of Tongue) & Salivation",
            test:
              "Taste is tested using substances that are sweet (sugar), sour (lemon juice), salty (salt), and bitter (aspirin, quinine, or aloes)."
          }
        ],
        disorders: [
          {
            pathology: "Facial Nerve Palsy",
            description:
              "Facial nerve palsy is often idiopathic (formerly called Bell palsy). Idiopathic facial nerve palsy is sudden, unilateral peripheral facial nerve palsy. Symptoms of facial nerve palsy are hemifacial paresis of the upper and lower face.",
            causes: [
              "Vestibular schwannoma",
              "Basilar skull fracture",
              "Bell palsy",
              "Guillain-Barré syndrome",
              "Infarcts and tumors of the pons",
              "Lyme disease",
              "Ramsay Hunt syndrome (herpes zoster oticus)",
              "Sarcoidosis",
              "Tumors that invade the temporal bone"
            ],
            clinicalCorrelations: [
              {
                id: "YRdGQ3aD",
                title: "Facial Droop",
                description:
                  "A 55-year-old woman presents, complaining that she 'must have had a stroke.' She was drinking her morning coffee and realized it was dribbling from her mouth."
              }
            ]
          }
        ]
      },
      {
        id: "8",
        numeral: "VIII",
        functionType: "Sensory",
        functionMotor: "",
        functionSensory: "<div>Hearing & Balance</div>",
        name: "Vestibulocochlear",
        origin: "Pontomedullary Region",
        foramina: "Internal acoustic meatus",
        components: "<div>Special Somatic Afferent</div>",
        anatomy: [
          {
            divisionTitle: "Special sensory neurons",
            divisionDesc:
              "The vestibulocochlear nerve traverses the internal acoustic meatus with CN VII",
            divisionPoints: [
              "CN VIII originates from the grooves between the pons and the medulla oblongata. CN VIII divides into the cochlear branch to the cochlea (hearing) and the vestibular branch to the semicircular canal (equilibrium)."
            ]
          }
        ],
        testing: [
          {
            function: "Hearing",
            test:
              "Hearing is tested with a tuning fork or with headphones that play tones of different frequencies (pitches) and loudness (audiometry)."
          },
          {
            function: "Balance",
            test:
              "Balance is tested by asking the person to walk a straight line."
          }
        ],
        disorders: [
          {
            pathology: "Benign paroxysmal positional vertigo",
            description:
              "Benign paroxysmal positional vertigo (BPPV) is the most common cause of relapsing otogenic vertigo. It occurs in short (< 60 sec) episodes, occurring with certain head positions, accompanied by nausea and vomiting. It affects people increasingly as they age and can severely affect balance in the elderly, leading to potentially injurious falls.",
            causes: [
              "Otolithic aggregation in the posterior or horizontal semicircular canal, related to aging and/or trauma",
              "Infection"
            ],
            clinicalCorrelations: []
          },
          {
            pathology: "Meniere Disease",
            subtitle: "Endolymphatic Hydrops",
            description:
              "Meniere disease is an inner ear disorder that causes vertigo, fluctuating sensorineural hearing loss, and tinnitus. In Meniere disease, pressure and volume changes of the labyrinthine endolymph affect inner ear function. The etiology of endolymphatic fluid buildup is unknown. Peak incidence is between ages 20 and 50. Risk factors include:",
            causes: [
              "Family history of Meniere disease",
              "Preexisting autoimmune disorders",
              "allergies",
              "trauma to the head or ear",
              "syphilis"
            ],
            clinicalCorrelations: [
              {
                id: "BDbDJ5el",
                title: "Ringing in Ear",
                description:
                  "A 34-year-old woman comes to the physician because of a 6-month history of ringing in her ears, a feeling of spinning, and progressive hearing loss in her left ear."
              }
            ]
          },
          {
            pathology: "Vestibular Neuronitis",
            description:
              "Vestibular neuronitis causes a self-limited episode of vertigo, presumably due to inflammation of the vestibular division of the 8th cranial nerve; some vestibular dysfunction may persist.",
            causes: ["Viral Infection"],
            clinicalCorrelations: []
          }
        ]
      },
      {
        id: "9",
        numeral: "IX",
        name: "Glossopharyngeal",
        functionType: "Both",
        functionMotor: "<div>Swallowing & Salivation</div>",
        functionSensory:
          "<div>Somatosensation (Posterior 1/3 Tongue)</div>" +
          "<div>Taste (Posterior 1/3 Tongue)</div>" +
          "<div>Pharynx Somatosensation</div>" +
          "<div>Sinus/Carotid Chemoreceptors</div>" +
          "<div>Sinus/Carotid Baroreceptors</div>",
        origin: "Medulla Oblongata",
        foramina: "Jugular foramen",
        components:
          "<div>General Somatic Afferent</div>" +
          "<div>General Visceral Afferent</div>" +
          "<div>Special Visceral Afferent</div>" +
          "<div>General Visceral Efferent</div>" +
          "<div>Special Visceral Efferent</div>",
        anatomy: [
          {
            divisionTitle: "",
            divisionDesc:
              "The glossopharyngeal nerve emerges from the lateral aspect of the medulla oblongata and traverses the jugular foramen, where the nerves superior and inferior sensory ganglia are located ",
            divisionPoints: []
          },
          {
            divisionTitle: "Branchial Motor Neurons",
            divisionDesc:
              "Course in a pharyngeal branch to innervate the stylopharyngeus muscle, the only skeletal muscle derived from the third branchial arch.",
            divisionPoints: []
          },
          {
            divisionTitle: "Visceral Motor Neurons",
            divisionDesc:
              "Visceral motor neurons. Parasympathetic neurons from CN IX innervate the parotid gland via the following pathway:",
            divisionPoints: [
              "Preganglionic parasympathetic neurons arise in the inferior salivatory nucleus of the medulla oblongata, traverse the jugular foramen, and then enter the petrous part of the temporal bone via the tympanic canaliculus to the tympanic cavity.",

              "The tympanic nerve forms the tympanic plexus on the promontory of the middle ear to provide general sensation to the tympanic membrane.",

              "The tympanic plexus emerges from the temporal bone at the lesser petrosal hiatus as the lesser petrosal nerve and exits the skull via the foramen ovale with CN V-3.",

              "The preganglionic parasympathetic neurons synapse in the otic ganglion.",

              "Postganglionic parasympathetic neurons exit the otic ganglion and travel with the auriculotemporal branch of CN V-3 to provide visceral motor innervation the parotid gland."
            ]
          },
          {
            divisionTitle: "General Sensory Neurons",
            divisionDesc:
              "Course within the tympanic plexus, providing innervation to the internal surface of the tympanic membrane, the middle ear, and the auditory tube. General sensory neurons also course in the pharyngeal branch from the posterior third of the tongue and the oropharynx.",
            divisionPoints: []
          },
          {
            divisionTitle: "Special Sensory Neurons",
            divisionDesc:
              "Course from the posterior third of the tongue within the pharyngeal branch of CN IX, providing taste sensation.",
            divisionPoints: []
          },
          {
            divisionTitle: "Visceral Sensory Neurons",
            divisionDesc:
              "Course from the carotid sinus (baroreceptor) and carotid body (chemoreceptor) and ascend in the sinus nerve, joining CN IX. The cell bodies of the visceral sensory neurons reside in the inferior ganglion.",
            divisionPoints: [],
            clinicalCorrelations: [
              {
                id: "y1aK5JdQ",
                title: "Sore Throat",
                description:
                  "A 12-year-old girl is brought to the physician because of a sore throat. There is pain with swallowing and a general feeling of malaise."
              }
            ]
          }
        ],
        testing: [
          {
            function: "Swallowing, the gag reflex, and speech",
            test:
              "Because both the 9th and 10th cranial nerves control swallowing and the gag reflex, they are tested together. The person is asked to swallow. The person is asked to say “ah-h-h” to check movement of the palate (roof of the mouth) and uvula (the small, soft projection that hangs down at the back of throat). The back of the throat may be touched with a tongue blade, which evokes the gag reflex in most people. The person is asked to speak to determine whether the voice sounds nasal (another test of palate movement)."
          }
        ],
        disorders: [
          {
            pathology: "Glossopharyngeal Neuralgia",
            description:
              "Glossopharyngeal neuralgia is characterized by recurrent attacks of severe pain in the 9th and 10th cranial nerve distribution (posterior pharynx, tonsils, back of the tongue, middle ear, under the angle of the jaw). Glossopharyngeal neuralgia is rare, more commonly affecting men, usually after age 40.",
            causes: [
              "Ectatic artery or tumor (less common) compressing the nerve"
            ],
            clinicalCorrelations: []
          }
        ]
      },
      {
        id: "10",
        numeral: "X",
        name: "Vagus",
        functionType: "Both",
        functionMotor:
          "<div>Swallowing, Talking, Coughing</div>" +
          "<div>Parasympathetic to GI tract</div>",
        functionSensory:
          "<div>Skin around ear</div>" +
          "<div>Taste/Sensation to Epiglottis</div>" +
          "<div>Sensory to Body Viscera</div>",
        origin: "Medulla Oblongata",
        foramina: "Jugular foramen",
        components:
          "<div>General Somatic Afferent</div>" +
          "<div>Special Visceral Afferent</div>" +
          "<div>General Visceral Afferent</div>" +
          "<div>General Visceral Efferent</div>" +
          "<div>Special Visceral Efferent</div>",

        anatomy: [
          {
            divisionTitle: "Modalities",
            divisionDesc:
              "The vagus nerve emerges from the lateral aspect of the medulla oblongata and traverses the jugular foramen, where the superior and inferior sensory ganglia are located. The vagus nerve travels between the internal jugular vein and internal carotid artery within the carotid sheath. The vagus nerve exits the medulla oblongata and travels with CN IX and XI through the jugular foramen. CN X consists primarily of the following four modalities:",
            divisionPoints: []
          },
          {
            divisionTitle: "Visceral Sensory Neurons",
            divisionDesc:
              "Provide visceral sensory information from the larynx (below the vocal folds), trachea, and esophagus, and the thoracic and abdominal viscera as well as chemoreceptors from the carotid bodies and stretch and chemoreceptors from the aortic arch.",
            divisionPoints: []
          },
          {
            divisionTitle: "General Sensory Neurons",
            divisionDesc:
              "Provide general sensory information from part of the external acoustic meatus, the pinna, and the laryngopharynx.",
            divisionPoints: []
          },
          {
            divisionTitle: "Branchial Motor Neurons",
            divisionDesc:
              "Supply the palatal, laryngeal, and pharyngeal muscles.",
            divisionPoints: []
          }
        ],
        testing: [
          {
            function: "Swallowing, the gag reflex, and speech",
            test:
              "Because both the 9th and 10th cranial nerves control swallowing and the gag reflex, they are tested together. The person is asked to swallow. The person is asked to say “ah-h-h” to check movement of the palate (roof of the mouth) and uvula (the small, soft projection that hangs down at the back of throat). The back of the throat may be touched with a tongue blade, which evokes the gag reflex in most people. The person is asked to speak to determine whether the voice sounds nasal (another test of palate movement).",
            clinicalCorrelations: [
              {
                id: "9wdL5jej",
                title: "Hoarseness after surgery",
                description:
                  "A 66 year old male presents for headache and fever. He reports his symptoms started getting worse after being diagnosed with a sinus infection recently."
              }
            ]
          }
        ],
        disorders: [
          {
            pathology: "Vagal Nerve Dysfunction",
            subtitle: "Hoarseness, dysphonia, and dysphagia, Vasovagal syncope",
            description:
              "Injury or pathology to the vagal nerve causes various clinical presentations which is secondary to the vast innervation of this nerve. Some etiologies include entrapment of the recurrent laryngeal nerve by mediastinal tumor or involement of the nerve with Herpes Zoster. Other causes of Vagal nerve injury include:",
            causes: [
              "Infectious or carcinomatous meningitis",
              "Medullary tumors or ischemia (eg, lateral medullary syndrome)",
              "Tumor or aneurysm in the posterior fossa or jugular foramen (jugular foramen syndrome)"
            ]
          }
        ]
      },
      {
        id: "11",
        numeral: "XI",
        name: "Accessory",
        functionType: "Motor",
        functionMotor:
          "<div>Shoulder Shrug (trapezius)</div>" +
          "<div>Head-turning (sternocleidomastoid)</div>",
        functionSensory: "",
        origin:
          "<div>Medulla Oblongata &</div>" +
          "<div>Superior Cervical Spinal Cord</div>",
        foramina: "Jugular foramen",
        components: "<div>General Somatic Efferent *</div>" +
            "<div>Special Visceral Efferent *</div>",
        anatomy: [
          {
            divisionTitle: "",
            divisionDesc:
              "The spinal accessory nerve originates from the upper spinal cord, ascends through the foramen magnum, then descends through the jugular foramen, and provides branchial motor innervation to the trapezius and sternocleidomastoid muscles",
            divisionPoints: [
                "* There is often inconsistencies in medical literature as to the true modality of CN XI. Some sources cite it as having general somatic efferent components, while others cite it as having special viscerla efferent components. Some have even proposed a new classification for its modality as 'transitional' - a nerve demonstrating characteristics of both spinal and cranial components."
            ]
          }
        ],
        testing: [
          {
            function: "Shoulder Shurg and Head-turning",
            test:
              "The person is asked to turn the head and to shrug the shoulders against resistance provided by the examiner."
          }
        ],
        disorders: [
          {
            pathology:
              "Paralysis of the sternocleidomastoid and trapezius muscles",
            description:
              "The Accessory nerve innervates both the sternocleidomastoid (SCM) and trapezius muscles and so damage or injury to this nerve can lead to complete or partial paralysis of these muscles. Remember that the SCM and trapezius muscles are responsible for Head-turning and shoulder shrug respectively. Some potential etiologies for injury include:",
            causes: [
              "Iatrogenic (eg, due to lymph node biopsy in posterior triangle of the neck)",
              "Idiopathic",
              "Trauma",
              "Tumor or aneurysm in the posterior fossa or jugular foramen (jugular foramen syndrome)"
            ],
            clinicalCorrelations: [
              {
                id: "4QbY0Waz",
                title: "Inability to raise his right arm",
                description:
                  "A 61-year-old man with a longstanding history of cigarette smoking and alcohol use is diagnosed with oral squamous cell carcinoma"
              }
            ]
          }
        ]
      },
      {
        id: "12",
        numeral: "XII",
        name: "Hypoglossal",
        functionType: "Motor",
        functionMotor: "<div>Tongue Movement (except palatoglossus)</div>",
        functionSensory: "",
        origin: "Medulla Oblongata",
        foramina: "Hypoglossal Canal",
        components: "<div>General Somatic Efferent</div>",
        anatomy: [
          {
            divisionTitle: "",
            divisionDesc:
              "The hypoglossal nerve exits the medulla oblongata in the groove between the pyramid and the olive. Upon exiting the hypoglossal canal, CN XII courses along the lateral surface of the hyoglossus muscle, deep to the mylohyoid muscle. CN XII provides somatic motor innervation to all intrinsic and extrinsic tongue muscles such as genioglossus, hyoglossus, and styloglossus (but not palatoglossus, which is supplied by CN X).",
            divisionPoints: []
          }
        ],
        testing: [
          {
            function: "Tongue Movement",
            test:
              "The person is asked to stick out the tongue, which is observed for deviation to one side or the other.",
            clinicalCorrelations: [
              {
                id: "lNbWmney",
                title: "Difficulty with tongue movements",
                description:
                  "Neurologic examination shows that the patient is able to say 'Kuh, kuh, kuh' and 'Mi, mi, mi', but not 'La, la, la.' "
              }
            ]
          }
        ],
        disorders: [
          {
            pathology: "Tongue Fasciculation and Atrophy",
            description:
              "The Hypoglossal nerve innervates the muscles of the tongue (except the palatoglossus) and so damage to this nerve leads to partial or complete paralysis of these muscles. As a result, these muslces will atrophy.Fasciculations, may be present indicating an lower motor neuron lesion. Some etiologies include:",
            causes: [
              "Intramedullary lesions (eg, motor neuron disease, tumors)",
              "Lesions of the basal meninges or occipital bones (eg, platybasia, Paget disease of skull base)",
              "Surgical trauma (eg, due to endarterectomy)",
              "Motor neuron disease (eg, amyotrophic lateral sclerosis)"
            ],
            clinicalCorrelations: []
          }
        ]
      }
    ]
  }
};
