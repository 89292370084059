import React, { useState, useEffect, Fragment } from "react";
import "./App.css";

// Components
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Home from "./Home/Home";
import HomeNonAuth from "./Home/HomeNonAuth";
import Nerve from "./Nerve/Nerve";
import Foramina from "./Nerve/Foramina/Foramina";
import CaseDetails from "./CaseDetails/CaseDetails";
import Collection from "./Collection/Collection";
import Collections from "./Collection/Collections";
import Spinner from "./Utilities/Spinner";

// Dependencies
import { Route, Switch } from "react-router-dom";

// Style
import styled from "styled-components";
import { fontFamily } from "./Utilities";

// Apollo, GraphQL
import { Query } from "react-apollo";
import gql from "graphql-tag";

const GET_USER = gql`
  query GET_USER {
    user {
      id
      isSuperuser
      username
      profile {
        id
        subscribedApps {
          id
          title
          description
          url
          logo
        }
        subscribedRounds {
          id
          collectionTitle
        }
      }
    }
  }
`;

export default function App(props) {
  const [location, setLocation] = useState();

  function handleLocation(locationMarker) {
    setLocation(locationMarker);
  }

  return (
    <Query query={GET_USER}>
      {({ loading, data }) => {
        if (loading) return <Spinner />;
        const user = data.user;
        if (!user) {
          window.location = "https://medzcool.com/cranial";
          return <Spinner />;
        }

        let userHasApp;
        if (user) {
          userHasApp = user.profile.subscribedApps.find(app => {
            return app.title == "Cranial";
          });
        }

        if (userHasApp) {
          return (
            <AppContainer>
              <Header {...props} location={location} userHasApp={userHasApp} />
              <Switch>
                <Route
                  exact
                  path="/"
                  render={props => (
                    <Home {...props} handleLocation={handleLocation} />
                  )}
                />

                <Route
                  path="/nerve/:nerveId?"
                  render={props => (
                    <Nerve {...props} handleLocation={handleLocation} />
                  )}
                />

                <Route
                  path="/foramina/:foraminaId?"
                  render={props => (
                    <Foramina {...props} handleLocation={handleLocation} />
                  )}
                />

                <Route
                  path="/quizzes"
                  render={props => (
                    <Collections {...props} handleLocation={handleLocation} />
                  )}
                />

                <Route
                  path="/collection/:collectionUrl/:caseId?"
                  render={props => (
                    <Collection
                      {...props}
                      user={user}
                      handleLocation={handleLocation}
                    />
                  )}
                />

                <Route
                  exact
                  path="/case/:caseId"
                  render={props => <CaseDetails {...props} user={user} />}
                />
              </Switch>
            </AppContainer>
          );
        } else {
          window.location = "https://medzcool.com/cranial";
          return <Spinner />;
        }
      }}
    </Query>
  );
}

const AppContainer = styled.div`
  font-family: ${fontFamily.sansSerif};
  padding: 15px;
  .reading-text {
    font-size: 21px;
    font-family: ${fontFamily.serif};
  }
  @media (max-width: 425px) {
    .reading-text {
      font-size: 18px;
    }
  }
`;
