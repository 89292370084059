import React, { Fragment } from "react";

export default function ForamenRotundum(props) {
  const { nerveId, foramenId, green, grayscale } = props;
  return (
    <Fragment>
      <path
        d="M85.0192,291.1468c-.7891,2.63-.3489,4.7441,1.8837,5.0232s6.279-.1395,7.0465-2.4419a4.155,4.155,0,0,0-.7675-4.4651,10.1767,10.1767,0,0,0-4.0465-1.1162C88.0889,288.1468,85.4378,289.7514,85.0192,291.1468Z"
        fill={
          nerveId == "5" || foramenId == "foramen-rotundum"
            ? green.bright
            : grayscale.gray
        }
        onClick={() => props.history.push("/foramina/foramen-rotundum")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
  cursor: "pointer"
};
