import React, { Fragment } from "react";

export default function InternalAcousticMeatus(props) {
  const { nerveId, foramenId, green, grayscale } = props;
  return (
    <Fragment>
    <path
      d="M95.39,431.5779c-3.8887,0-5.7173-4.47-7.4327-7.626-1.4272-2.8922-.57-5.7861-.2865-8.6783,0-.5255,1.1442-1.8414,2.0019-1.3144,1.4289,1.3144,2.0035,3.1557,3.4307,4.47,2.8612,3.4193,6.575,5.5226,9.1481,8.9419a5.1969,5.1969,0,0,1,.2865,4.9972c0,.5254-1.4272.5254-2.0019.7889a4.5794,4.5794,0,0,1-3.7172,0c-.5712,0-.8577-.5254-.518-1.2986-.3363.2446-.6244-.2809-.9109-.2809Z"
      fill="#74605b"
      fill={
        nerveId == "7" ||
        nerveId == "8" ||
        foramenId == "internal-acoustic-meatus"
          ? green.bright
          : grayscale.gray
      }
      onClick={() => props.history.push("/foramina/internal-acoustic-meatus")}
      style={containerStyle}
    />
    <path
      d="M79.2556,402.7814a10.2662,10.2662,0,0,0,3.1082,4.5033c1.6794,1.559,3.8236,2.3669,5.2353,4.29,1.8217,2.4962,2.8613,4.9467,4.6281,7.4618a18.4821,18.4821,0,0,0,5.2524,4.8647c2.3947,1.5779,4.4137,3.484,5.1461,6.067.2882.9372.3929,2.2342-.928,2.5972a6.4637,6.4637,0,0,1-5.3965-.7069c-4.6281-3.32-8.184-7.8563-8.1291-13.248.0188-2.0859.8765-4.123.9468-6.2279.0206-.1483-.3036-.3124-.4991-.4781a.5224.5224,0,0,0-.6793-.1483.4337.4337,0,0,0-.1612.6248c.0188.1973.1235.41.036.5586-4.484,7.3814.5009,15.6653,7.1651,20.3153a7.9821,7.9821,0,0,0,8.0588.6754c1.8577-.9531,2.055-3.0911,1.4289-4.8016-1.52-4.1088-5.7534-6.2137-8.9337-9.1864-3.0019-2.8276-4.146-6.6571-7.057-9.6156-1.645-1.6758-3.8235-2.4647-5.719-3.8958a8.4979,8.4979,0,0,1-3.0208-3.8154.269.269,0,0,0-.34-.1293.2254.2254,0,0,0-.1424.295h0Z"
      fill={grayscale.gray}
      onClick={() => props.history.push("/foramina/internal-acoustic-meatus")}
      style={containerStyle}
    />
    </Fragment>
  );
}

const containerStyle = {
  cursor: "pointer"
};
