import React, { Fragment } from "react";

// style
import { green, grayscale } from "../../Utilities";

export default function VectorIV(props) {
  const { nerveId } = props;
  return (
    <Fragment>
      <path
        d="M77.19,410.8137s-6.4479-10.9-7.83-13.97-8.5972-18.8831-9.9789-24.1028S55.39,361.38,55.39,358.31s.7676-9.9789.9211-11.0535.2564-2.9169,1.2282-3.8381a4.26,4.26,0,0,1,5.68-.4605c2.3028,1.8422,3.5309,3.2239,4.2986,5.0662a16.7293,16.7293,0,0,1,1.0746,5.3732c.1535,1.8422-.6141,6.1408-.6141,8.1366s2.7634,12.1282,3.838,15.0451c1.0747,2.9153,5.3733,14.4294,6.755,16.8873s3.9915,7.2155,4.7591,9.2112,1.8177,6.32,1.8177,6.32-1.5091.895-3.0443,1.6627-4.9142.1535-4.9142.1535Z"
        fill={nerveId == "4" ? green.bright : grayscale.gray}
        onClick={() => props.history.push("/nerve/4")}
        style={containerStyle}
      />
      <path
        d="M57.1873,345.4921a7.6792,7.6792,0,0,0,1.9452,5.7693,10.1291,10.1291,0,0,0,6.7042,3.4665c1.3955.0768,3.1472-.2932,3.5448-1.97.55-2.2137-.5757-4.1957-1.5874-6.0763a10.0037,10.0037,0,0,0-5.04-4.9772c-1.8422-.7031-4.644-.4851-5.4745,1.7394a7.7549,7.7549,0,0,0,3.1594,9.0839.3363.3363,0,1,0,.4222-.5236,7.7981,7.7981,0,0,1-2.6728-5.6925c.0123-1.1391-.1535-2.5331,1.1514-3.3007a3.1641,3.1641,0,0,1,2.8141-.0768,10.496,10.496,0,0,1,6.0395,9.3648c-.0138,1.1253-1.3817,1.2927-2.2261,1.1775-4.0176-.525-8.6862-3.5064-8.7245-7.9831h0a.028.028,0,0,0-.0261-.026.0264.0264,0,0,0-.0292.0245h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/4")}
        style={containerStyle}
      />
      <path
        d="M58.62,342.3188c-2.4564,1.2788-2.7634,4.3754-3.0965,6.844a51.9537,51.9537,0,0,0,.0645,12.8451c2.1876,6.5369,3.7612,13.2151,6.46,19.6123,3.5188,8.3162,7.9831,16.0169,11.4373,24.2809,2.7373,4.6424,5.27,9.3648,7.9954,14.0088,2.9937,7.5977,8.0476,14.173,10.7342,21.889a.0533.0533,0,0,0,.1029-.0276c-2.2138-7.9186-5.86-15.16-9.5306-22.58-3.0321-6.104-6.7933-11.5509-9.569-17.7547-3.1211-6.9868-6.7672-13.51-9.7486-20.5457-2.596-6.1286-4.056-12.5243-6.2053-18.7542a42.181,42.181,0,0,1-.396-9.9282c.2932-3.2884.18-6.8577,1.9835-9.71a.2555.2555,0,1,0-.4084-.307l.1765.1274Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/4")}
        style={containerStyle}
      />
      <path
        d="M67.4226,347.1425a16.0713,16.0713,0,0,1,1.2788,6.7426c-2.9553,8.7.5373,17.3985,3.6845,25.5474,1.2926,3.353,2.5454,6.64,3.9654,9.9528,2.725,6.3588,6.5753,12.14,8.3162,18.8447a.7936.7936,0,1,0,1.5859-.0645c-1.2926-5.7693-4.3876-10.7971-6.9345-16.1319-2.1616-4.555-3.9793-9.0839-5.68-13.8169-1.5874-4.3754-3.3391-8.4437-4.2218-13.1644-.7553-3.9778.2809-7.6623.2932-11.5.0138-2.3028-1.3817-4.2986-2.2383-6.4233,0-.0261-.0261-.0261-.0384-.0261-.0107.0138-.0246.0261-.0107.04Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/4")}
        style={containerStyle}
      />
    </Fragment>
  );
}
const containerStyle = {
    cursor: "pointer"
}
