import React, { Fragment } from "react";

export default function HypoglossalCanal(props) {
  const { nerveId, foramenId, green, grayscale } = props;
  return (
    <Fragment>
      <path
        d="M48.3158,449.0234s-3,3-1,6l2,3a3.4914,3.4914,0,0,0,2,1,3.5355,3.5355,0,0,0,3-3,22.6851,22.6851,0,0,0-1-4,14.3854,14.3854,0,0,0-2-3c-1-1-3,0-3,0"
        fill={
          nerveId == "12" || foramenId == "hypoglossal-canal"
            ? green.bright
            : grayscale.gray
        }
        onClick={() => props.history.push("/foramina/hypoglossal-canal")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
  cursor: "pointer"
};
