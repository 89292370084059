import React, { Fragment } from "react";

export default function JugularForamen(props) {
  const { nerveId, foramenId, green, grayscale } = props;
  return (
    <Fragment>
      <path
        d="M85.3158,444.0234a1.683,1.683,0,0,1,1.75-1.0165,9.5611,9.5611,0,0,1,2.8372,1.0465s7.7906,6.3256,9.5348,9.2791c.7907,1.07,1.3489,4.6511,1.3489,4.6511l.3023,2.4651s.3721,4.3256,1,5.721a27.9005,27.9005,0,0,0,3,4.186,23.6763,23.6763,0,0,0,3.8139,3.07c1.814,1.07,8.1163,2.7674,8.1163,2.7674s4.5116,1.0465,6.4883,1.7442,4.2559,2.3488,4.3721,3.3488-1.1628,2.3256-1.1628,2.3256a6.9439,6.9439,0,0,1-2.6744,1.5116,17.1955,17.1955,0,0,1-4.3255-.4418c-.6279-.2326-4.6279-1.628-5.2791-2.07s-4-2.8605-4.2791-3.2093a5.1238,5.1238,0,0,0-2.4418-.93,17.3919,17.3919,0,0,1-4.5116-.721,21.3976,21.3976,0,0,1-5.2791-3.7441c-1.0465-1.2093-7.6279-9.7442-7.6279-9.7442a12.0724,12.0724,0,0,1-1.8372-2.6744,30.9164,30.9164,0,0,0-.7674-3.6744,39.6285,39.6285,0,0,1-2.1861-5.14C85.4843,452.1,84.8915,444.7376,85.3158,444.0234Z"
        fill={nerveId == "9" || nerveId == "10" || nerveId == "11" || foramenId == "jugular-foramen"  ? green.bright : grayscale.gray}
        onClick={() => props.history.push("/foramina/jugular-foramen")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
  cursor: "pointer"
};
