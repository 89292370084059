import React, { Fragment } from "react";

export default function ForamenOvale(props) {
  const { nerveId, foramenId, green, grayscale } = props;
  return (
    <Fragment>
      <path
        d="M116.2051,339.0769c.844,4.895-.4186,7.3256-2.7907,9.5581s-8.093,1.1861-9.9767.07-8.7907-4.9535-11.372-13.0465c-1.8838-9.2791,9.3488-7.8139,9.3488-7.8139a16.987,16.987,0,0,1,7.8837,2.93C112.9958,333.4257,115.8563,337.0536,116.2051,339.0769Z"
        fill={nerveId == "5" || foramenId == "foramen-ovale" ? green.bright : grayscale.gray}
        onClick={() => props.history.push("/foramina/foramen-ovale")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
  cursor: "pointer"
};
