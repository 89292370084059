import React, { Fragment } from "react";

// style
import { green, grayscale } from "../../Utilities";

export default function VectorV(props) {
  const { nerveId } = props;
  return (
    <Fragment>
      <path
        d="M77.19,410.8137a6.07,6.07,0,0,0,4.2986.3071,10.2433,10.2433,0,0,0,5.9873-5.2182,26.5431,26.5431,0,0,0,2.3028-10.7464c-.1535-3.0705-1.9958-9.9789-2.4564-13.2044-.46-3.2239-1.3816-9.8253-1.5352-12.8957S84.098,358.31,83.6375,355.7s-2.4564-10.7465-2.9169-13.97-2.4564-9.6718-2.7634-12.5887a50.2068,50.2068,0,0,1-.4606-8.4436c.23-1.6888,3.3775-4.7592,6.6014-6.6014s7.2155-4.6057,10.4395-4.9127,4.58.1274,5.2826,1.4446.8566,4.6962,1.01,6.69a78.0334,78.0334,0,0,0,2.3028,9.9788,45.7145,45.7145,0,0,0,2.4563,5.5268,79.933,79.933,0,0,0,1.5352-8.5972c.3071-3.3774,1.3817-8.9042,1.5353-11.6676s-.1536-7.5225-.1536-10.1324-.46-5.8338-.307-7.5225a6.2079,6.2079,0,0,1,2.9169-4.6056c1.8422-1.2282,8.7507-2.4564,11.207-2.9169a35.4371,35.4371,0,0,1,10.9.1535,9.552,9.552,0,0,1,5.22,3.224c.8183,1.1775,1.2282,4.4521,1.2282,6.6014s.1535,15.6591.4606,19.4971.9211,13.8169,1.9957,17.3479,3.8381,10.5914,5.9874,12.4352a14.7767,14.7767,0,0,0,8.5971,3.6845c3.6845.307,8.9042.6141,12.7423,0s9.6718-.9211,9.6718-.9211,3.07-.3071,5.0662,3.2239a38.8123,38.8123,0,0,1,3.838,15.9662c-.1535,4.6056-1.3172,13.0232-2.3028,15.0451s-1.8422,2.9168-3.838,3.5309-12.8958,3.8381-16.7338,5.0647c-3.838,1.23-9.8254,3.2255-13.2028,5.3732-3.3775,2.1493-8.1366,4.7607-11.207,7.6761-3.0705,2.9169-6.2944,7.5225-9.3648,10.4409a112.3434,112.3434,0,0,1-10.1324,8.5972c-2.61,1.8423-8.7507,6.6029-11.2071,8.7507s-6.6014,5.9858-8.29,8.5988c-1.6887,2.6083-6.7549,8.2885-6.7549,8.2885s-4.6056-12.1266-7.6761-18.5776C84.2516,424.9377,77.19,410.8138,77.19,410.8138"
        fill={nerveId == "5" ? green.bright : grayscale.gray}
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M89.0291,348.5625c-.7031,2.814-2.3289,5.2075-3.1349,8.0338-1.394,4.9126-.4728,9.8514-.6141,14.7886.9089,7.2923,2.6483,14.4049,4.0683,21.5943.1152,6.04-.1919,13.5236-6.345,16.6954a5.7392,5.7392,0,0,1-5.3855.1413.7807.7807,0,0,0-1.0485.3838.7983.7983,0,0,0,.3838,1.0608c4.9633,2.4563,10.5423-1.6365,12.3323-6.256a25.7158,25.7158,0,0,0,1.5475-12.3584c-.4867-4.1712-1.83-7.957-2.4824-12.1282-.4852-3.1211-1.3433-6.05-1.42-9.1345-.0906-3.8-.525-7.5732-.3331-11.3866.1919-3.9394,1.4078-7.6761,2.4947-11.4235,0-.0123,0-.0384-.0261-.0384-.0108.0016-.0231.0016-.0369.0277h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M78.4377,324.7146c-1.0869-1.3433-.5634-3.1088.1919-4.426,1.4078-2.4564,3.6722-3.9148,6.038-5.4116,3.9531-2.4825,7.88-5.0156,12.538-5.04a1.5744,1.5744,0,0,1,1.2282.8321,3.5812,3.5812,0,0,1-.1151,3.5816c-2.0342,4.4137-6.6521,6.9084-11.181,8.3285-2.9169.9089-6.1408,1.6765-9.1222.8183h0a.498.498,0,1,0-.7937.6018c.4222.9088,1.4969.8582,2.2767.7937a60.9987,60.9987,0,0,0,9.199-1.7133,20.1116,20.1116,0,0,0,7.74-3.838c2.6744-2.0219,4.6318-5.2458,3.7229-8.61a3.1789,3.1789,0,0,0-3.0059-2.3535c-4.8881.0645-9.1222,2.8663-13.2919,5.1814-2.4686,2.1493-5.9489,3.6845-6.8194,7.151a4.1016,4.1016,0,0,0,1.3556,4.1573.023.023,0,0,0,.0384,0,.0437.0437,0,0,0,0-.0537Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M78.0662,319.01a21.55,21.55,0,0,1-.7676,3.3145,6.9635,6.9635,0,0,0-.3193,2.7512c.614,5.3855,2.4041,10.272,3.2884,15.6975.46,2.814.7292,5.4116,1.3694,8.2134,1.8177,8.0475,3.7612,15.5179,4.4782,23.4764a.1125.1125,0,0,0,.1274.1013.1141.1141,0,0,0,.1152-.1151c-.1413-4.3493.3316-8.5972-.6663-13.0493-1.0486-4.7592-2.38-9.0317-3.0321-13.7785-.9073-6.6014-2.596-12.5-3.9777-19.1395-.5374-2.5715-.2948-4.9771-.5251-7.4719h0a.0645.0645,0,1,0-.1289,0h.0384Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M85.23,313.4323c3.3268-1.5352,6.5507-3.1733,10.2859-3.8a4.4135,4.4135,0,0,1,3.3007.8827l.525.2825-.3454-.2687.0767.1152a5.1666,5.1666,0,0,1,1.0869,3.07,34.688,34.688,0,0,0,1.6627,11.0781c1.0869,3.2884,1.6242,6.8194,3.7474,9.6088a.5185.5185,0,0,0,.9733-.3577,2.3764,2.3764,0,0,0-.1919-1.113c-2.5592-6.2437-4.5672-12.7683-4.6563-19.5355a5.6578,5.6578,0,0,0-1.2159-3.3514l-.2947-.3193a.6755.6755,0,0,0-.3071-.2564l-.4866-.23c.0261.0261.0383.0507.0645.0768-5.0662-1.8807-9.9144,1.8683-14.2514,4.056h0c-.0123.0123-.0261.0261-.0123.0507,0,.0107.0261.0107.0384.0107h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M111.1484,290.7373c-1.6365.9979-3.4542,2.8663-2.5592,4.8989a2.9246,2.9246,0,0,0,1.305,1.4845,19.7966,19.7966,0,0,0,9.0838,1.8039c6.41-.1274,13.0232-.218,18.8063-4.056a3.812,3.812,0,0,0,1.8162-2.9292c.09-2.2123-2.02-3.6339-3.8764-4.3631-4.4015-1.7271-9.07-1.2911-13.7018-.6141-4.6179.6786-9.71.78-12.8574,4.8359a5.2182,5.2182,0,0,0-1.1514,3.5049c.23,3.3775-.1658,6.69.0261,10.0557a40.29,40.29,0,0,1,.3455,5.2826c-.3194,4.3631-1.2789,8.5711-1.6627,12.9341-.4221,4.7469-1.42,9.2758-1.9835,14.0211a90.8331,90.8331,0,0,1-1.8683,12.4229h0a.0732.0732,0,0,0,.1412.0384,155.2022,155.2022,0,0,0,4.9511-22.4785c.1274-7.4458,2.9169-14.5462,1.7148-22.2345-.3577-2.2521.0507-4.44.0891-6.6659.0383-1.6242-.869-3.1349-.499-4.7453.7154-3.1856,4.1835-4.2341,7.1126-4.81,5.5391-1.1007,10.6436-2.0848,16.2472-1.1514,2.3918.3961,4.9633,1.4323,5.846,3.8119a3.9031,3.9031,0,0,1-1.1391,1.919c-2.8263,2.1754-6.05,2.8785-9.4415,3.3775-2.8908.4345-5.7049.5373-8.5588.895-3.1856-.5634-6.844-.0261-9.4415-2.4057-1.5352-1.4078-.4345-3.9025,1.2926-4.7852a.0483.0483,0,0,0,.0123-.0384c-.0092-.0092-.0369-.023-.0491-.0092h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M136.071,288.2042c1.6,2.1493,3.48,4.3232,3.3514,7.1387-.23,5.6665.1412,11.31.2042,16.9641a130.462,130.462,0,0,0,1.1007,15.71c1.2159,9.3525,4.2863,21.2888,15.16,22.9637a46.407,46.407,0,0,0,8.0338.6908,55.4232,55.4232,0,0,0,7.894-.806c2.5715-.41,5.0662-1.1007,7.6254-.6018a.7575.7575,0,0,0,.8689-.6524.7663.7663,0,0,0-.6524-.8705c-2.5838-.64-5.22.1151-7.88.5373a72.701,72.701,0,0,1-7.5486.806,37.88,37.88,0,0,1-7.8419-.6647,10.667,10.667,0,0,1-4.733-1.4969c-7.3045-5.0155-8.4314-13.5221-9.4937-21.4161-.4345-3.2885-.64-6.474-.7937-9.7225-.281-5.949-.281-11.91-.3716-17.8468-.0506-3.0444.64-6.6644-2.2644-8.8782a15.5549,15.5549,0,0,1-2.5976-1.9067h0a.06.06,0,0,0-.0644,0,.0417.0417,0,0,0,.003.0522Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M118.0323,320.9027c.8183-3.8381.525-7.7912.7676-11.706.218-3.4665.96-6.9468.4606-10.375h0a.4345.4345,0,0,0-.869,0c.1413,3.4543-.18,6.9085-.3193,10.375-.1658,3.9025.6525,7.83-.089,11.6921h0c-.0123.0123,0,.0261.0261.0384.0092.0015.023-.0108.023-.0246h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M130.5182,319.8664c-.1658-7.6638,1.19-15.2877.5757-22.9376h0a.3961.3961,0,0,0-.7922,0,102.8272,102.8272,0,0,1-.0906,11.5909c-.23,3.7873.3332,7.5609.2564,11.3482,0,.0261.0123.0384.0261.0384s.0246-.0138.0246-.04Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M132.1547,353.2311a431.1035,431.1035,0,0,0,1.9574-43.5984.1849.1849,0,0,0-.18-.1919.1827.1827,0,0,0-.18.18c-.8828,14.5323-.8582,29.0784-1.65,43.6122h0c-.0123.0123,0,.0261.0261.0384.0138-.0015.0261-.0138.0261-.04Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M110.0093,397.3806a87.608,87.608,0,0,0,7.7282-17.3094c2.5209-7.1894,3.5694-14.3543,4.9757-22.0042,2.0725-11.2454,1.7532-21.9919,2.1631-33.0316a.0389.0389,0,0,0-.0384-.0522.0471.0471,0,0,0-.0507.0522,231.9944,231.9944,0,0,1-3.25,33.94c-3.3514,12.9219-5.3211,26.444-11.5786,38.38-.0122.0122-.0122.026.0123.0368.0138.0138.0261,0,.0384-.0123Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M101.6562,403.0732c4.6317-5.5528,6.6905-12.5887,7.4458-19.7274.7553-7.2032-.1658-14.3159.6141-21.4669a.1163.1163,0,0,0-.1152-.1412.126.126,0,0,0-.14.1151c-1.1391,7.1648-.2564,14.3159-1.2788,21.6081-.9856,6.9468-2.4563,13.8553-6.5753,19.5739-.0123.0123,0,.0369.0123.0492a.0336.0336,0,0,0,.0368-.0107h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M184.4547,385.5979c-2.0725,1.919-4.7715-.0383-5.8077-2.0587a32.0522,32.0522,0,0,1-3.0965-12.231c-.2441-3.0827-.5251-6.1408-.5113-9.2113a22.9662,22.9662,0,0,1,2.2645-9.9021c.78-1.638,2.725-2.9168,4.3615-1.7148,2.9046,2.1232,4.03,5.4884,4.8236,8.8536a35.4387,35.4387,0,0,1,1.05,11.7827c-.5112,5.0907-1.01,10.464-3.8887,14.7887-2.7633.5112-5.3609-1.9574-6.345-4.4521h0a.0708.0708,0,0,0-.1028-.0384.0868.0868,0,0,0-.0507.1029c1.0746,2.9552,3.2623,7.0619,7.0359,5.8982,2.84-2.1631,3.1349-6.064,3.9408-9.3909a36.34,36.34,0,0,0,.7938-14.0348c-.7677-5.6035-1.7533-11.9624-6.9853-15.1863-1.3556-.8321-2.8785-.0384-4.056.8443-3.8764,5.68-4.5933,12.9081-4.12,19.6384.3838,5.2581,1.0747,10.464,3.62,15.2247a6.31,6.31,0,0,0,4.7208,3.39c1.2527.1536,2.0848-.8443,2.9169-1.612a.4457.4457,0,1,0-.5634-.6908Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M88.0189,456.5108a26.3644,26.3644,0,0,0,7.561-5.757c5.7048-6.6245,10.4517-13.2412,17.3478-18.7787,2.9553-2.3673,5.8461-4.2863,8.674-6.7549,2.8908-2.5454,4.862-5.3993,7.1771-8.52,3.9531-5.3349,5.539-11.2208,7.8556-17.116a15.52,15.52,0,0,1,3.21-5.272.1653.1653,0,0,0-.0123-.2425.1774.1774,0,0,0-.23.0123c-4.925,4.951-6.3972,11.8978-9.8254,17.9727a44.5752,44.5752,0,0,1-9.4676,12.14c-4.1711,3.6461-8.6355,6.345-12.538,10.2613-5.949,5.9628-10.6958,12.231-16.12,18.7557a16.127,16.127,0,0,1-3.6722,3.25c-.0123,0-.0123.0261-.0123.0384a.0922.0922,0,0,0,.0522.0107h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M110.9442,432.844c5.5145-4.8344,12.3078-7.676,17.6427-12.9572,4.26-4.2218,7.6115-8.7906,11.9746-12.908a60.6914,60.6914,0,0,1,22.4141-13.0861c6.563-2.266,13.3824-3.0444,19.6384-6.2422,1.4078-.7169,2.137-2.1232,2.9307-3.4158a.117.117,0,0,0-.0261-.1536.1056.1056,0,0,0-.1535.0261,4.2811,4.2811,0,0,1-2.0219,1.6627c-9.3648,4.2985-19.5478,5.27-28.8742,9.8514a58.0944,58.0944,0,0,0-16.17,11.3222c-3.8,3.8764-6.9084,7.9585-10.682,11.8718-3.9915,4.1312-8.763,6.7426-13.0232,10.5806-.8443.7553-1.5613,1.549-2.3411,2.3658-.41.4114-.96.614-1.3433,1.0377h0a.0394.0394,0,0,0,0,.0507c-.0031.0046.023.0046.0353-.0061h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M89.3745,416.25c1.9068-2.3151,4.58-3.7735,6.1286-6.46a51.1377,51.1377,0,0,0,6.0257-15.977c1.7148-8.6493.588-16.7092-.0384-25.0484,0-.0261-.0122-.0384-.0261-.0384a.0335.0335,0,0,0-.0383.0384c.8059,8.5449,1.3555,17.0147-.8951,25.6364a81.4078,81.4078,0,0,1-5.6664,14.8148c-1.3049,2.7511-3.62,4.707-5.539,7.01h0c-.0123.0123-.0123.0261.0122.0384.0108.0122.0246.0122.0369-.0139h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M112.045,395.718c15.07-13.9827,33.096-25.5873,53.6295-29.1812a.0806.0806,0,0,0,.0645-.0891.08.08,0,0,0-.1029-.0645c-9.9267,1.6366-19.4081,4.4521-28.4014,9.6074a116.7624,116.7624,0,0,0-15.633,10.6313c-3.5571,2.8662-6.1792,6.2545-9.5951,9.047-.0123,0-.0123.0261,0,.0369a.047.047,0,0,0,.0384.0122Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M139.55,370.7831a88.6339,88.6339,0,0,1,27.7735-8.1105.2119.2119,0,1,0-.0384-.4221c-9.8131.55-19.1134,4.0176-27.7612,8.482h0c-.0123,0-.0261.0261-.0123.0383s.0261.0261.0384.0123Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M149.9892,363.7857c6.69-2.2138,13.7141-2.9676,20.6225-4.21a.2724.2724,0,1,0-.089-.5373,75.5986,75.5986,0,0,0-20.5458,4.6824h0c-.0261.0123-.0261.0261-.0261.0384s.0123.0261.0384.0261Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M94.9781,415.1507a186.7545,186.7545,0,0,1,26.3549-22.632c10.2982-7.5226,20.2909-13.8814,31.8817-17.962.0261-.0123.0384-.0384.0261-.0507a.0324.0324,0,0,0-.0507-.0261c-11.7566,3.8381-22.0563,9.8254-32.419,17.3986-4.644,3.402-8.7123,6.497-12.9725,10.1984-4.7592,4.119-8.545,8.7108-12.8574,13.037a.023.023,0,0,0,0,.0368.024.024,0,0,0,.0369,0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M89.5787,428.6605c9.9282-10.49,20.4567-19.8164,31.5993-29.5527,7.7282-6.755,16.0306-11.8841,24.7168-16.8229a.1259.1259,0,0,0-.1274-.2165c-7.3045,4.0422-14.6858,7.5993-21.3394,13.1138-8.2517,6.844-15.7221,13.33-23.4119,20.6732a158.8748,158.8748,0,0,0-11.488,12.7652c-.0123.0154-.0123.0384,0,.0384a.0378.0378,0,0,0,.0507.0015h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
      <path
        d="M89.7845,436.1309a3.4467,3.4467,0,0,0,1.6764-1.0992c6.781-6.474,12.6394-13.037,19.4833-19.4481,4.555-4.271,8.9426-8.327,13.4715-12.4352a.0358.0358,0,0,0-.0507-.0506c-11.4757,11.0673-24.4988,20.5334-34.72,32.8412h0a.1919.1919,0,0,0,.23.307l-.0906-.1152Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/5")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
  cursor: "pointer"
};
