import React, { Fragment } from "react";

// style
import { green, grayscale } from "../../Utilities";

export default function VectorIII(props) {
  const { nerveId } = props;
  return (
    <Fragment>
      <path
        d="M28.2179,371.5124s1.0747-8.5972,2.1478-12.5887c1.0746-3.9916,2.7634-10.1324,4.4521-14.5845s4.7591-12.2817,6.6014-15.6592a96.29,96.29,0,0,1,5.5268-9.2112c1.3816-1.8423,4.7591-6.2944,4.7591-6.2944a6.3486,6.3486,0,0,1,7.5225.1535c3.8381,2.9169,4.6057,5.8338,2.9169,9.0578s-7.369,13.2028-9.2112,17.8084-6.2944,17.6549-7.5226,21.3394-2.43,7.8173-2.1492,9.2113c.2809,1.3955.4605,2.1493.4605,2.1493s-4.4521-.4606-7.2155-.7676-8.2886-.6141-8.2886-.6141Z"
        fill={nerveId == "3" ? green.bright : grayscale.gray}
        onClick={() => props.history.push("/nerve/3")}
        style={containerStyle}
      />

      {/* Outline Paths */}
      <path
        d="M29.0807,371.9223c.9212-2.6744.588-5.513,1.3172-8.3024,1.7532-6.6521,3.2363-12.9849,5.5774-19.33,4.0177-10.9122,8.8014-21.2365,16.5036-30.371,2.226-2.6483,6.064-.8183,8.06,1.1253,1.5859,1.5475,2.5454,3.9792,1.5475,6.038-3.4926,7.1387-7.5609,13.714-10.5285,21.0584-3.9393,9.7609-7.957,19.5356-9.2619,29.9873a.7937.7937,0,1,0,1.5874.0123c1.3694-10.2092,5.2581-19.7659,9.1222-29.31,2.8908-7.1264,6.5507-13.5728,10.31-20.406a6.9957,6.9957,0,0,0-1.0486-7.8035c-2.2905-2.8524-6.7672-4.7852-10.0433-2.6482-8.3147,9.0961-13.51,20.3922-17.7823,31.92a170.184,170.184,0,0,0-5.6035,19.6123,55.9739,55.9739,0,0,0-.96,8.1749.6923.6923,0,0,0,1.3557.281l-.1536-.0384Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/3")}
        style={containerStyle}
      />
      <path
        d="M45.8529,373.5726a3.0027,3.0027,0,0,0-.6525-1.1253c-1.2021-1.5475-.7169-3.4158-.2687-5.1168,1.087-4.0944,2.43-7.868,3.8-11.8211,3.5816-10.35,6.69-20.7376,11.8595-30.3066a.1438.1438,0,1,0-.2426-.1535c-4.26,6.7933-7.3306,14.1746-10.2475,21.671-2.6221,6.7165-4.67,13.407-6.7549,20.3922-.7538,2.5331-.6908,5.6035,1.7271,7.2278a.67.67,0,0,0,.9472-.9473l-.1673.18Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/3")}
        style={containerStyle}
      />
      <path
        d="M61.1666,315.76a10.8279,10.8279,0,0,1,1.97,4.0161H63.124l.0384-.2809-.0768.1412c-.1151,1.4585-.806,3.0182-2.3934,3.21a5.6844,5.6844,0,0,1-4.8988-1.3049c-1.97-1.9574-4.1574-4.5795-3.0965-7.4841a4.9806,4.9806,0,0,1,7.3812-.0645c.0261.0138.0384.0138.0522,0,.0123-.0261.0123-.0384,0-.0507a8.7594,8.7594,0,0,0-3.4665-2.0726c-2.1493-.6278-5.22.1781-5.6419,2.6606-.7676,4.4659,2.8141,8.8274,7.1649,9.5444a5.4616,5.4616,0,0,0,4.2218-.55,3.6954,3.6954,0,0,0,1.4446-3.5571l.0507-.0768a.5426.5426,0,0,0,.0384-.2809l-.1919-.436a12.3114,12.3114,0,0,0-2.547-3.4665.0229.0229,0,0,0-.0383,0,.04.04,0,0,0,.0015.0522Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/3")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
    cursor: "pointer"
}
