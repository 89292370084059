import React, { Fragment } from "react";

// style
import { green, grayscale } from "../../Utilities";

export default function VectorII(props) {
  const { nerveId } = props;
  return (
    <Fragment>
      <path
        d="M3.9616,293.8323a15.2145,15.2145,0,0,0,5.8338-1.3817c2.9169-1.3817,6.2944-2.3028,9.0578-5.68s10.5929-15.1986,10.5929-15.1986S37.89,259.29,39.2714,256.9873s4.2725-7.1909,6.127-9.0455,6.46-3.85,9.5306-2.4686S63.5262,251,65.2149,254.07s3.07,5.9858,2.4564,8.4437c-.6141,2.4563-6.0887,10.9506-7.9831,13.8168a151.03,151.03,0,0,0-9.3648,18.1155,149.75,149.75,0,0,0-5.5268,16.58s-1.9957,7.5225-2.3028,9.6718-2.4563,10.2859-2.4563,10.2859.4606-5.3732-1.6888-7.676-7.8295-6.7549-12.8957-8.29A62.1731,62.1731,0,0,0,9.9474,312.869a53.3681,53.3681,0,0,0-5.9858.307V293.8324h0Z"
        fill={nerveId == "2" ? green.bright : grayscale.gray}
        onClick={() => props.history.push("/nerve/2")}
        style={containerStyle}
      />

      {/* Outline Paths */}
      <path
        d="M33.5835,306.6513c3.0965-10.1063,8.84-19.0489,14.0211-28.1711a.2186.2186,0,0,0-.0768-.2809.2106.2106,0,0,0-.2932.0767A155.8541,155.8541,0,0,0,37.46,295.2017c-1.7532,3.6968-2.7127,7.5855-3.9393,11.4373,0,.0138,0,.0384.026.0384a.0385.0385,0,0,0,.0369-.0261Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/2")}
        style={containerStyle}
      />
      <path
        d="M37.5873,314.8386c-.3454-9.0071,4.6578-16.8751,8.7123-24.525a.1077.1077,0,0,0-.0521-.1535.0969.0969,0,0,0-.14.0506c-4.44,7.4842-9.506,15.544-8.571,24.6279a.0294.0294,0,0,0,.0261.0261.0267.0267,0,0,0,.0245-.0261Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/2")}
        style={containerStyle}
      />
      <path
        d="M-.0376,292.7193c3.6584,2.8908,8.52,1.6887,12.383-.0123,8.0337-3.5433,12.2172-11.3728,16.8366-18.78,3.2362-5.1814,6.6521-9.4155,9.9144-14.5722,2.6359-4.1835,4.2356-8.545,7.9708-11.7951,4.9127-4.2863,11.3867-.7169,15.1218,3.4021,3.02,3.3391,5.6557,7.3828,5.2842,12.0007-1.6887,6.1025-5.9873,10.7327-9.3387,16.2348A93.54,93.54,0,0,0,52.43,289.176c-5.1813,11.3605-9.8514,22.695-11.5141,35.2576-.0906.6648.0261,1.3173-.0906,1.9835a22.6177,22.6177,0,0,1-.4728,3.2885h0a.7839.7839,0,0,0,.5634.9717.7944.7944,0,0,0,.9718-.5634c.7031-4.6563.8566-9.2757,2.1493-13.932a162.6329,162.6329,0,0,1,5.975-17.4891,98.0045,98.0045,0,0,1,6.5124-13.843c3.4158-5.7693,7.1387-10.6559,10.5024-16.439a12.1809,12.1809,0,0,0,1.919-5.143c.55-6.9729-4.1958-12.9219-10.03-16.8351-3.1472-2.1109-6.9729-3.2362-10.4271-1.5859a10.2133,10.2133,0,0,0-2.8663,1.7778c-3.4158,3.39-5.2074,7.5348-7.7405,11.6415-1.7148,2.7634-3.3391,5.1813-5.3087,7.8035-4.5028,6.0134-7.4074,12.1542-12.09,17.9358-2.4441,3.02-5.0017,5.8584-8.6356,7.2539-3.7612,1.4324-7.88,2.2905-11.8456,1.394-.0261,0-.0384,0-.0507.0123a.0923.0923,0,0,0,.0107.0537h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/2")}
        style={containerStyle}
      />
      <path
        d="M60.2961,265.0732c-6.282-3.6338-12.7422-9.4154-13.19-17.13a2.9245,2.9245,0,0,1,2.507-2.0986c3.4159-.0768,7.3184-.3577,10.08,2.0725a.1816.1816,0,0,0,.2564-.0122.1855.1855,0,0,0-.0123-.2687c-2.6221-2.61-6.5891-3.313-10.1447-3.1856-2.02.0768-3.9915,1.523-4.056,3.4926-.23,7.2539,5.7693,13.2274,12.2694,15.9524a10.212,10.212,0,0,1,2.2645,1.2282.048.048,0,0,0,.0383-.0123c.0139-.0123,0-.0384-.0122-.0384h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/2")}
        style={containerStyle}
      />
      <path
        d="M43.32,326.66c1.2665-3.3775.5112-7.0359,1.5091-10.5669.7415-2.6359,1.2788-5.1552,2.0219-7.7911,1.5091-5.272,3.032-10.2859,4.9771-15.34,4.0683-10.5284,10.005-19.9316,16.72-28.8481,1.3433-2.5976.5112-5.5651-.96-7.9324h0a.1579.1579,0,1,0-.2687.1658,11.2406,11.2406,0,0,1-.0261,6.4356c-.5112,1.6887-1.8422,2.8263-2.9168,4.3231A112.6626,112.6626,0,0,0,50.38,292.271a146.32,146.32,0,0,0-5.0278,15.6837c-1.6887,6.2437-3.9148,12.4613-2.2905,18.8324a.23.23,0,1,0,.46,0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/2")}
        style={containerStyle}
      />
      <path
        d="M4.9012,305.9344c8.0337-2.06,13.8291-8.3163,19.382-14.3159,5.68-6.1408,9.9911-12.9095,14.5707-19.689,0-.0261,0-.0507-.0261-.0645-.0123-.0123-.0384,0-.0507.0123a137.695,137.695,0,0,1-15.2753,18.96,120.2067,120.2067,0,0,1-11.5,10.72c-2.2015,1.7916-4.3369,3.6968-7.1126,4.3247a.0278.0278,0,0,0-.0261.0245c0,.0277.0261.0415.0384.0277Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/2")}
        style={containerStyle}
      />
      <path
        d="M11.7329,308.3139c7.2922-1.0869,12.2694-7.6238,15.57-13.7263a.0815.0815,0,0,0-.0383-.1028.0677.0677,0,0,0-.1013.0261c-3.66,5.86-8.6356,11.6169-15.4427,13.74h0c-.0123.0123-.0261.0261-.0261.0384a.0361.0361,0,0,0,.0384.0245Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/2")}
        style={containerStyle}
      />
      <path
        d="M39.6353,274.7328c3.89-5.1553,7.5732-10.5039,12.0775-15.16a.41.41,0,0,0-.5112-.64c-4.9127,4.44-7.9693,10.3749-11.6154,15.762-.0123.0123,0,.0383.0123.0506a.0485.0485,0,0,0,.0368-.0122Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/2")}
        style={containerStyle}
      />
      <path
        d="M31.0381,296.2242c6.3972-10.9246,13.255-21.5574,20.4444-31.9831a.371.371,0,0,0-.0768-.5235.3868.3868,0,0,0-.525.0891c-4.8743,5.8338-8.2134,12.3829-12.3707,19.0488-2.814,4.5028-5.2074,8.7891-7.5225,13.3425h0c-.0123.0123,0,.0261.0122.0384.0123.0016.0384.0016.0384-.0123h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/2")}
        style={containerStyle}
      />
      <path
        d="M1.6266,348.7161c12.1788.4482,25.4461-1.242,35.1041-9.2881,4.145-4.3615,5.975-11.4373,2.533-16.7583-8.9932-9.8377-23.2829-11.3867-35.8594-10.12h0a.5251.5251,0,0,0,0,1.05c5.7448-.0261,11.3606-.3071,17.1944.6908a29.0494,29.0494,0,0,1,17.1053,9.0071,7.9578,7.9578,0,0,1,1.8929,5.4116,13.8,13.8,0,0,1-6.563,11.6415,44.2775,44.2775,0,0,1-14.1362,5.5267,57.5455,57.5455,0,0,1-7.2662,1.1253c-3.4158.3455-6.8962-.3331-10.1968.7415a.5471.5471,0,0,0,.2057,1.0747Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/2")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
    cursor: "pointer"
}
