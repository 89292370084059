import React, { Fragment, useState, useEffect } from "react";

// Dependencies
import {
  EditorState,
  RichUtils,
  AtomicBlockUtils,
  convertToRaw,
  convertFromRaw
} from "draft-js";

// Components
import DraftReadOnly from "./DraftReadOnly";

// Elements
import { CaseBlock, StyledLabel } from "../Utilities";

// Styles
import styled from "styled-components";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";

function CaseStem(props) {
  const {
    collectionExplanationExempt,
    collectionGraphical,
    collectionUrl
  } = props;

  useEffect(() => {
    if (collectionExplanationExempt.includes(collectionUrl)) {
      setHiddenStem(false);
    } else {
      setHiddenStem(true);
    }
  }, []);
  const [hiddenStem, setHiddenStem] = useState();
  function stemToggle(stemState) {
    setHiddenStem(stemState);
  }

  return (
    <Fragment>
      {props.caseType == "clinical scenario" ? (
        <CaseBlock>
          {!collectionGraphical.includes(collectionUrl) && (
            <ModifiedStyledLabel>
              <span>Clinical Scenario</span>
            </ModifiedStyledLabel>
          )}

          <Fragment>
            <ModifiedStyledLabel>
              <StemShow
                answered={props.answered}
                icon={faChevronDown}
                hiddenStem={hiddenStem}
                onClick={() => stemToggle(false)}
              >
                <FontAwesomeIcon icon={faChevronDown} />
              </StemShow>
              {!collectionExplanationExempt.includes(collectionUrl) && (
                <StemHide
                  answered={props.answered}
                  hiddenStem={hiddenStem}
                  onClick={() => stemToggle(true)}
                >
                  <FontAwesomeIcon icon={faChevronUp} />
                </StemHide>
              )}
            </ModifiedStyledLabel>

            <StemWrapper answered={props.answered} hiddenStem={hiddenStem}>
              <DraftReadOnly propsEditorState={props.stem} onChange={true} />
            </StemWrapper>
          </Fragment>
        </CaseBlock>
      ) : (
        <CaseBlock>
          <Fragment>
            <ModifiedStyledLabel>
              <span>Question</span>
              <StemShow
                answered={props.answered}
                icon={faChevronDown}
                hiddenStem={hiddenStem}
                onClick={() => stemToggle(false)}
              >
                <FontAwesomeIcon icon={faChevronDown} />
              </StemShow>
              {!collectionExplanationExempt.includes(collectionUrl) && (
                <StemHide
                  answered={props.answered}
                  hiddenStem={hiddenStem}
                  onClick={() => stemToggle(true)}
                >
                  <FontAwesomeIcon icon={faChevronUp} />
                </StemHide>
              )}
            </ModifiedStyledLabel>
            <StemWrapper answered={props.answered} hiddenStem={hiddenStem}>
              <DraftReadOnly propsEditorState={props.question} />
            </StemWrapper>
          </Fragment>
        </CaseBlock>
      )}
    </Fragment>
  );
}
const ModifiedStyledLabel = styled(StyledLabel)`
  margin-bottom: 10px;
`;
const StemWrapper = styled.div`
  ${props => props.answered && props.hiddenStem && "display: none"}
  ${props =>
    props.answered && !props.hiddenStem && "display: block"}
  img,iframe {
    width: 100%;
  }
`;
const StemShow = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 25px 35px 25px 25px;
  margin-top: 5px;
  cursor: pointer;
  ${props => props.answered && props.hiddenStem && `display: block;`}
`;
const StemHide = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 25px 35px 25px 25px;
  margin-top: 5px;
  cursor: pointer;
  ${props => props.answered && !props.hiddenStem && `display: block;`}
`;

export default CaseStem;
