import React, { Fragment } from "react";

import ForamenMagnum from "./ForamenMagnum"
import Skull from "./Skull"
import HypoglossalCanal from "./HypoglossalCanal"
import JugularForamen from "./JugularForamen"
import ForamenLacerum from "./ForamenLacerum"
import ForamenSpinosum from "./ForamenSpinosum"
import InternalAcousticMeatus from "./InternalAcousticMeatus"
import ForamenOvale from "./ForamenOvale"
import ForamenRotundum from "./ForamenRotundum"
import SuperiorOrbitalFissure from "./SuperiorOrbitalFissure"
import OpticCanal from "./OpticCanal"
import CribiformPlate from "./CribiformPlate"
import CarotidCanal from "./CarotidCanal"

// style
import { green, grayscale } from "../../Utilities";

import styled from "styled-components";

export default function ForaminaSVG(props) {
  return (
    <SVGContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="333"
        height="781"
        viewBox="0 0 333 781"
      >
        <title>foramina</title>
        <Skull {...props} green={green} grayscale={grayscale}/>
        <HypoglossalCanal {...props} green={green} grayscale={grayscale}/>
        <JugularForamen {...props} green={green} grayscale={grayscale}/>
        <ForamenLacerum {...props} green={green} grayscale={grayscale}/>
        <CarotidCanal {...props} green={green} grayscale={grayscale}/>
        <InternalAcousticMeatus {...props} green={green} grayscale={grayscale}/>
        <ForamenSpinosum {...props} green={green} grayscale={grayscale}/>
        <ForamenOvale {...props} green={green} grayscale={grayscale}/>
        <ForamenRotundum {...props} green={green} grayscale={grayscale}/>
        <SuperiorOrbitalFissure {...props} green={green} grayscale={grayscale}/>
        <OpticCanal {...props} green={green} grayscale={grayscale}/>
        <CribiformPlate {...props} green={green} grayscale={grayscale}/>
        <ForamenMagnum {...props} green={green} grayscale={grayscale}/>

      </svg>
    </SVGContainer>
  );
}

const SVGContainer = styled.div`
  svg {
    width: 100%;
    height: auto;
  }
`;
