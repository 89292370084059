import React, { Fragment } from "react";
import { useSpring, animated } from "react-spring";
import { homeUrl } from "../settings";

// Dependencies
import { Link } from "react-router-dom";

// Styles
import styled from "styled-components";
import { themeColors, fontFamily, fontWeight } from "../Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOut,
  faTimes,
  faUnlock,
  faHome,
  faBrain,
  faQuestionCircle,
  faMoneyBill,
  faUser,
  faUserLock,
  faUserPlus
} from "@fortawesome/pro-regular-svg-icons";
import { faFirstAid } from "@fortawesome/pro-solid-svg-icons";

export default function Menu(props) {
  return (
    <Fragment>
      <MenuContainer style={props.style}>
        <div onClick={props.toggleMenu} className="close">
          <span>Close Menu</span>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        {props.userHasApp ? (
          <Fragment>
            <div className="menu-item">
              <a href="/">
                <FontAwesomeIcon icon={faHome} />
                <span>Home</span>
              </a>
            </div>
            <div className="menu-item">
              <a href="/quizes">
                <FontAwesomeIcon icon={faQuestionCircle} />
                <span>Quizzes</span>
              </a>
            </div>
            <div className="menu-item">
              <a href="/foramina">
                <FontAwesomeIcon icon={faBrain} />
                <span>Foramina</span>
              </a>
            </div>
            <div className="menu-item">
              <a href="https://api.medzcool.com/logout">
                <FontAwesomeIcon icon={faSignOut} />
                <span>Logout</span>
              </a>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="menu-item">
              <a href="https://medzcool.com/login/cranial">
                <FontAwesomeIcon icon={faUserLock} />
                <span>LOGIN</span>
              </a>
            </div>
            <div className="menu-item">
              <a href="https://medzcool.com/login/cranial">
                <FontAwesomeIcon icon={faUserPlus} />
                <span>SIGN-UP</span>
              </a>
            </div>
            <div className="menu-item">
              <a href="#">
                <FontAwesomeIcon icon={faMoneyBill} />
                <span>PRICING</span>
              </a>
            </div>
          </Fragment>
        )}
      </MenuContainer>
      <MenuBackground style={props.opacity} onClick={props.toggleMenu} />
    </Fragment>
  );
}

const MenuContainer = styled(animated.div)`
  font-family: ${fontFamily.sansSerif};
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 300px;
  color: white;
  background: #1d1d1d;
  z-index: 11;
  padding: 25px;
  .menu-item {
    cursor: pointer;
    span {
      margin-left: 15px;
    }
    padding: 10px;
    margin: 15px 0;
    a {
      color: white;
    }
  }
  .close {
    cursor: pointer;
    text-align: right;
    margin-bottom: 25px;
    border-bottom: 1px solid gray;
    padding-bottom: 25px;
    span {
      margin-right: 15px;
    }
  }
  @media (max-width: 425px) {
    width: 100%;
    padding: 0;
    .close {
      padding: 25px;
    }
    .menu-item {
      text-align: center;
    }
  }
`;
const MenuBackground = styled(animated.div)`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: black;
  z-index: 10;
`;
