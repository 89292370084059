import React, { Fragment } from "react";

// components
import NerveSVG from "./SVG/NerveSVG";
import ForaminaSVG from "./Foramina/ForaminaSVG";

// style
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/pro-solid-svg-icons";
import { green } from "../Utilities";

// Data
import { cranialData } from "../Static/Data";

export default function NerveMobile(props) {
  const {
    toggleNerveView,
    renderFunctions,
    nerveView,
    nerve,
    nerves,
    nerveId
  } = props;

  return (
    <NerveTopContainerMobile>
      <div className="title">
        <div className="numeral">{`CN ${nerve.numeral}`}</div>
        <div className="nerve-name">{`${nerve.name} Nerve`}</div>
      </div>

      <div className="info">
        <div className="info-block">
          <div className="label-title">FUNCTION</div>
          <div className="contents">{renderFunctions(nerve.functionType)}</div>
        </div>
        <div className="info-block">
          <div className="label-title">COMPONENTS</div>
          <div className="contents">
            <div dangerouslySetInnerHTML={{ __html: nerve.components }} />
          </div>
        </div>

        <div className="info-block">
          <div className="label-title">ORIGIN</div>
          <div className="contents">
            <StyledButton
              nerveView={nerveView}
              onClick={() => toggleNerveView("anatomy")}
            >
              <div
                dangerouslySetInnerHTML={{ __html: nerve.origin }}
                className="content-button-text"
              />
              <StyledIcon
                icon={faBrain}
                className="content-button-icon anatomy"
              />
            </StyledButton>
          </div>
        </div>

        <div className="info-block">
          <div className="label-title">FORAMINA</div>
          <div className="contents">
            <StyledButton
              nerveView={nerveView}
              onClick={() => toggleNerveView("foramina")}
            >
              <div
                dangerouslySetInnerHTML={{ __html: nerve.foramina }}
                className="content-button-text"
              />
              <StyledIcon
                icon={faBrain}
                className="content-button-icon foramina"
              />
            </StyledButton>
          </div>
        </div>
      </div>

      <div className="img-container">
        {nerveView == "anatomy" ? (
          <NerveSVG {...props} nerveId={nerveId} />
        ) : (
          <ForaminaSVG {...props} nerveId={nerveId} />
        )}
        <WhiteGradient />
      </div>
    </NerveTopContainerMobile>
  );
}

const NerveTopContainerMobile = styled.div`
  display: none;
  margin: auto;
  position: relative;
  .title {
    margin-bottom: 35px;
    position: relative;
    z-index: 3;
    .numeral {
      font-size: 18px;
    }
    .nerve-name {
      font-weight: 700;
      font-size: 32px;
    }
  }
  .info {
    text-align: left;
    padding: 10px 0;
    font-size: 14px;
    position: relative;
    z-index: 3;
    .info-block {
      margin-bottom: 25px;
    }
    .label-title {
      font-weight: 700;
    }
    .label-subtitle {
      font-weight: 500;
    }
    .contents {
      font-weight: 300;
      margin-top: 5px;
      .sub-content {
        margin-bottom: 15px;
      }
    }
  }
  .img-container {
    position: absolute;
    top: 0;
    right: -15px;
    width: 60%;
    z-index: 1;
    transform: scaleX(-1);
    img {
      max-width: 100%;
      max-height: 600px;
    }
  }
  @media (max-width: 600px) {
    display: block;
  }
`;

const WhiteGradient = styled.div`
  background: white;
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  z-index: 2;
  background: linear-gradient(89.59deg, rgba(255, 255, 255, 0) 1.01%, #FFFFFF 97%);
`;

const StyledButton = styled.div`
  background: #f9f9f9;
  padding: 8px 15px;
  border-radius: 5px;
  width: fit-content;
  text-align: left;
  cursor: pointer;
  .content-button-text {
    display: inline-block;
    vertical-align: middle;
  }
  .content-button-icon {
    margin-left: 10px;
    vertical-align: middle;
    &.anatomy {
      color: ${props =>
        props.nerveView == "anatomy" ? green.bright : "lightgray"};
    }
    &.foramina {
      color: ${props =>
        props.nerveView == "foramina" ? green.bright : "lightgray"};
    }
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  display: inline-block;
`;
