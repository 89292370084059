import React, { Fragment } from "react";

export default function CarotidCanal(props) {
  const { nerveId, foramenId, green, grayscale } = props;
  return (
    <Fragment>
      <path
        d="M113.81,376.3559c1.0825-.6889,1.0232-2.8837.93-3.5814a10.04,10.04,0,0,0-3.4883-4.7907,16.337,16.337,0,0,0-4.6047-2c-.7442-.1395-4-.2791-4.6046.5116s.6046,3.3024.6046,3.3024a15.6047,15.6047,0,0,0,3.1628,4.372c1.9535,1.7675,2.8372,2.4652,3.7209,2.7442A4.43,4.43,0,0,0,113.81,376.3559Z"
        fill={
          foramenId == "carotid-canal"
            ? green.bright
            : grayscale.gray
        }
        onClick={() => props.history.push("/foramina/carotid-canal")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
  cursor: "pointer"
};
