import React, { useEffect, useState, Fragment } from "react";

// Dependencies
import { Link } from "react-router-dom";

// components
import NerveSVG from "../SVG/NerveSVG";
import ForaminaSVG from "./ForaminaSVG";
import ForaminaMobile from "./ForaminaMobile";

// style
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/pro-solid-svg-icons";
import { green } from "../../Utilities";

// Data
import { cranialData } from "../../Static/Data";
import { foraminaData } from "../../Static/ForaminaData";

export default function Nerve(props) {
  const formainaId = props.match.params.foraminaId;

  useEffect(() => {
    props.handleLocation("foramina");
  }, []);

  const [nerves, setNerves] = useState();
  const [nerve, setNerve] = useState();
  const [foramen, setForamen] = useState();
  const [foramens, setForamens] = useState();
  useEffect(() => {
    const foraminaArray = foraminaData.data.foraminaData;
    setForamens(foraminaArray);
    const activeForamen = foraminaArray.filter(
      foramenObj => foramenObj.id == formainaId
    );
    setForamen(activeForamen[0]);
  }, [formainaId]);

  const [nerveView, setNerveView] = useState("foramina");
  function toggleNerveView(view) {
    setNerveView(view);
  }
  if (!foramens) return null;

  if (!foramen) {
    return (
      <Fragment>
        <NerveTopContainer>
          <div className="info">
            {foramens.map((foramensObj, index) => {
              return (
                <div key={index} className="styled-button">
                  <Link to={`/foramina/${foramensObj.id}`}>
                    {foramensObj.name}
                  </Link>
                </div>
              );
            })}
          </div>

          <div className="img-container">
            <ForaminaSVG {...props} />
          </div>
          <div className="title">
            <div className="nerve-name">Select a Foramen</div>
          </div>
        </NerveTopContainer>

        <ForaminaMobile
          foramen={foramen}
          foramens={foraminaData.data.foraminaData}
        />

        <NerveBottomContainer></NerveBottomContainer>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <NerveTopContainer>
        <div className="info">
          {foramen.nerves.length > 0 && (
            <div className="info-block">
              <div className="label-title">NERVES</div>
              <div className="contents">
                {foramen.nerves.map((nerve, index) => {
                  return (
                    <div className="content" key={index}>
                      {nerve}
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {foramen.vessels.length > 0 && (
            <div className="info-block">
              <div className="label-title">VESSELS</div>
              <div className="contents">
                {foramen.vessels.map((vessel, index) => {
                  return (
                    <div className="content" key={index}>
                      {vessel}
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {foramen.miscellaneous.length > 0 && (
            <div className="info-block">
              <div className="label-title">MISCELLANEOUS</div>
              <div className="contents">
                {foramen.miscellaneous.map((misc, index) => {
                  return (
                    <div className="content" key={index}>
                      {misc}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <div className="img-container">
          <ForaminaSVG {...props} foramenId={foramen.id} />
        </div>
        <div className="title">
          <div className="nerve-name">{foramen.name}</div>
        </div>
      </NerveTopContainer>

      <ForaminaMobile foramen={foramen} />

      <NerveBottomContainer></NerveBottomContainer>
    </Fragment>
  );
}

const NerveTopContainer = styled.div`
  display: grid;
  grid-gap: 45px;
  grid-template-columns: 3fr 2.4fr 5fr;
  max-width: 1200px;
  margin: auto;
  padding: 50px 15px 15px 15px;
  .info {
    text-align: right;
    align-self: end;
    padding: 10px 0;
    .styled-button {
      background: #f9f9f9;
      padding: 8px 15px;
      border-radius: 5px;
      width: fit-content;
      cursor: pointer;
      margin-left: auto;
      margin-bottom: 5px;
      a {
        color: black;
        text-decoration: none;
      }
    }
    .info-block:not(:last-child) {
      margin-bottom: 45px;
    }
    .label-title {
      font-weight: 700;
    }
    .label-subtitle {
      font-weight: 500;
    }
    .contents {
      font-weight: 300;
      margin-top: 10px;
      .content {
        margin-bottom: 10px;
      }
      .sub-content {
        margin-bottom: 15px;
      }
    }
  }
  .img-container {
    img {
      max-width: 100%;
      max-height: 600px;
    }
  }
  .title {
    align-self: center;
    .numeral {
      font-size: 24px;
    }
    .nerve-name {
      font-weight: 700;
      font-size: 50px;
    }
  }

  @media (max-width: 1200px) {
    grid-template-columns: 3fr 1.8fr 3fr;
    grid-gap: 35px;
    .info {
      font-size: 14px;
      .contents {
        margin-top: 5px;
      }
    }
    .title {
      align-self: center;
      .numeral {
        font-size: 18px;
      }
      .nerve-name {
        font-weight: 700;
        font-size: 40px;
      }
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: 2fr 2fr 2fr;
    grid-gap: 20px;
    .title {
      align-self: center;
      .numeral {
        font-size: 16px;
      }
      .nerve-name {
        font-weight: 700;
        font-size: 22px;
      }
    }
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

const NerveBottomContainer = styled.div`
  max-width: 700px;
  margin: auto;
  margin-top: 200px;
  position: relative;
  z-index: 2;
  h2 {
    font-size: 32px;
    margin-bottom: 15px;
  }
  h3 {
    font-size: 22px;
    margin-bottom: 10px;
    margin-top: 15px;
    .subtitle {
      font-size: 16px;
      font-weight: 300;
    }
  }
  .body-block {
    margin-bottom: 75px;
    .body-inner-block {
      margin-bottom: 35px;
      .division-points {
        margin-top: 15px;
      }
    }
  }
  @media (max-width: 425px) {
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 18px;
    }
    margin-top: 75px;
  }
`;

const StyledButton = styled.div`
  background: #f9f9f9;
  padding: 8px 15px;
  border-radius: 5px;
  width: fit-content;
  text-align: right;
  margin-left: auto;
  cursor: pointer;
  .content-button-text {
    display: inline-block;
    vertical-align: middle;
  }
  .content-button-icon {
    margin-left: 10px;
    vertical-align: middle;
    &.anatomy {
      color: ${props =>
        props.nerveView == "anatomy" ? green.bright : "lightgray"};
    }
    &.foramina {
      color: ${props =>
        props.nerveView == "foramina" ? green.bright : "lightgray"};
    }
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  display: inline-block;
`;
