import React, { Fragment } from "react";

export default function ForamenLacerum(props) {
  const { nerveId, foramenId, green, grayscale  } = props;
  return (
    <Fragment>
      <path
        d="M44.8564,342.9374a13.1246,13.1246,0,0,1,6.221.6046c.4883.314,4.43,5.407,5.9651,6.4535A30.543,30.543,0,0,0,63.6006,353.1c.8023.07,3.8721,1.9884,4.4651,2.5116a15.925,15.925,0,0,1,2.2674,3.907c.2791.8372.9419,1.7442.7675,2.1628s-1.1512.1047-1.1512.1047a5.9708,5.9708,0,0,0-1.779-1.57c-.7326-.2442-1.6047-.4186-1.8489-.07s-.1046.4884-.4884.6279a2.3051,2.3051,0,0,1-1.6046.0349,4.3844,4.3844,0,0,0-1.6395-.6977,3.9029,3.9029,0,0,0-1.8489.6977,10.0822,10.0822,0,0,1-2.1628,1.43,5.1825,5.1825,0,0,1-2.0232.5931,7.1632,7.1632,0,0,1-4.57-1.5,9.4554,9.4554,0,0,0-3.8372-1.814,19.1913,19.1913,0,0,1-6.0348-2.8953c-2.3024-1.8837-4.3954-3.6628-4.0117-7.0814s3.8721-5.407,3.8721-5.407,1.1163-.686,1.2791-.7558S44.8564,342.9374,44.8564,342.9374Z"
        fill={grayscale.gray}
        fill={
          nerveId == "7" || foramenId == "foramen-lacerum"
            ? green.bright
            : grayscale.gray
        }
        onClick={() => props.history.push("/foramina/foramen-lacerum")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
  cursor: "pointer"
};
