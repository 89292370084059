import React, { Fragment } from "react";

export default function SuperiorOrbitalFissure(props) {
  const { nerveId, foramenId, green, grayscale } = props;
  return (
    <Fragment>
      <path
        d="M118.7865,247.891a8.3557,8.3557,0,0,1-.8372,5.5814c-1.5349,2.5814-6.07,4.9535-6.07,4.9535s-3.7675,1.186-4.9535,2.4419-4.3954,4.3255-6,7.4651-4.2558,7.3255-6.07,8.93-4.3255,3.279-6.9069,2.7209C88.368,278.24,98.554,259.1236,118.7865,247.891Z"
        fill={
          nerveId == "3" ||
          nerveId == "4" ||
          nerveId == "5" ||
          nerveId == "6" ||
          foramenId == "superior-orbital-fissure"
            ? green.bright
            : grayscale.gray
        }
        onClick={() => props.history.push("/foramina/superior-orbital-fissure")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
  cursor: "pointer"
};
