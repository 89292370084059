import React, { Fragment } from "react";

// style
import { green, grayscale } from "../../Utilities";
import styled from "styled-components";

export default function VectorI(props) {
  const { nerveId } = props;
  return (
    <Fragment>
      <path
        d="M22.9967,281.2436s2.4563-8.7507,3.3774-11.5141,2.61-15.9662,2.9169-20.4183a155.5307,155.5307,0,0,0-1.3817-23.3352,74.8689,74.8689,0,0,1-1.2281-14.1239c.1535-3.2239,1.0746-9.8253,1.3816-13.2028s.9212-14.8915.4606-19.19A139.8175,139.8175,0,0,0,25.3,162.4184c-1.0747-3.6845-3.3775-13.0493-3.3775-18.8831s.9211-12.8958,2.61-16.8873,4.9126-9.3648,8.29-9.8254,6.6014,2.3028,8.29,5.0662,5.3732,14.5845,5.8338,19.19.9211,19.19.4606,24.2563S45.1037,185.14,44.029,189.5916s-4.145,19.19-4.2986,22.8746-.1535,13.8169,0,18.2675.9212,23.9493.9212,23.9493l-17.655,26.5606Z"
        fill={nerveId == "1" ? green.bright : grayscale.gray}
        onClick={() => props.history.push("/nerve/1")}
        style={containerStyle}
      />

      {/* Outline Paths */}
      <path
        d="M23.0673,280.8214c2.725-5.9106,5.143-11.8779,5.9689-18.4409a156.4352,156.4352,0,0,0,1.4584-26.06c-.25-5.5452-1.63-10.9-2.1876-16.4652a60.3181,60.3181,0,0,1-.4606-9.6518c.307-5.45,1.4584-10.7849,1.67-16.1965.2886-8.0015.8444-15.851-1.0946-23.9109-1.3049-5.45-2.7818-10.3243-4.1067-15.7558-2.5914-10.67-1.8806-21.473,3.1288-31.1832,1.3433-2.61,3.3774-5.0662,6.391-5.6619,3.3391.7477,6.0257,3.3959,7.9248,6.1608,6.236,15.1786,6.0257,31.9892,4.5473,48.1672-1.42,15.6392-6.3128,30.4356-7.4074,46.0747a36.958,36.958,0,0,0-.02,5.8154c.826,8.7691.9212,17.4047,1.1131,26.1369.0383,1.919.3654,3.8764-.172,5.7755a.7729.7729,0,0,0,.3838,1.0363.7807.7807,0,0,0,1.0563-.3839,3.3877,3.3877,0,0,0,.3254-.9978c.1536-7.1572-.1335-14.2959-.4789-21.473-.1536-3.3775-.4222-6.735-.5558-10.094-.4606-11.9362,2.2077-23.2385,4.7975-35.31,4.26-19.9961,4.9511-38.6489.4038-58.5483-1.4584-6.3328-5.24-12.6655-11.8979-13.932-3.7044.5173-6.2744,3.4542-7.9063,6.62a45.0132,45.0132,0,0,0-4.5672,22.0686c.307,11.6676,6.064,22.3189,6.6014,33.9665a117.3157,117.3157,0,0,1-.1152,16.4068c-.46,4.9311-1.2466,9.7287-1.5551,14.6613-.5758,8.9226,1.9957,17.5014,2.533,26.3488.5374,8.5-.2886,16.8489-1.2465,25.3678-.7492,6.7165-2.8218,13.0109-4.6057,19.44h0a.0413.0413,0,0,0,.0384.0384.06.06,0,0,0,.0353-.02h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/1")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
    cursor: "pointer"
}
