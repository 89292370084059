import React, { Fragment } from "react";

// style
import { green, grayscale } from "../../Utilities";

export default function VectorXI(props) {
  const { nerveId } = props;
  return (
    <Fragment>
      <path
        d="M40.86,553.7418s.7676,1.5352,1.9958,1.5352,5.22-1.69,7.83-3.2255,13.0492-7.0635,16.8873-9.5183,11.8211-7.5226,15.5056-8.9042,8.9042-3.8381,11.36-3.8381,6.6014-1.2281,6.1409,4.2986-1.5613,7.05-3.07,8.4437-3.838,1.6887-6.6014,2.3028-6.4478,1.3817-8.9042,3.5325c-2.4563,2.1477-7.062,7.062-9.3648,9.67-2.3028,2.6114-8.0337,9.8253-10.1585,13.8952-2.1231,4.0683-5.9612,13.2795-7.4964,17.5766-1.5352,4.3-4.6056,15.6606-5.5267,20.1128a132.986,132.986,0,0,0-1.9958,16.58c0,2.61-.4606,6.4479-1.8423,7.6761s-3.9915,2.3028-5.68,1.69c-1.6888-.6141-2.38-2.2031-2.5715-6.8593s1.3433-17.8591,2.4179-21.6971S45.1588,587.67,45.1588,587.67a25.7143,25.7143,0,0,0-3.9916,2.6083,13.4987,13.4987,0,0,0-2.9169,3.0705,20.9649,20.9649,0,0,0-1.5352,3.6845V586.44s.3071,1.9973,1.9958.6141,8.5972-6.6014,8.5972-6.6014l3.838-10.1339a33.3849,33.3849,0,0,0-4.7592,2.3028c-2.3028,1.3817-3.5309,1.8438-5.22,3.838a42.0085,42.0085,0,0,0-3.9148,6.68l.9979-12.3584s1.5352.46,4.4521-1.0747,6.2944-3.07,8.29-4.2985a20.0219,20.0219,0,0,0,6.1409-5.5268c1.9958-2.7649,8.1366-11.2086,8.1366-11.2086l-10.4394,5.22-12.4352,6.6013-2.84,2.1478,1.305-8.9Z"
        fill={nerveId == "11" ? green.bright : grayscale.gray}
        onClick={() => props.history.push("/nerve/11")}
        style={containerStyle}
      />
      <path
        d="M45.3662,633.6341a6.2089,6.2089,0,0,0,2.4947-3.76c1.1637-4.8114.9856-9.4308,1.7916-14.3021a193.2271,193.2271,0,0,1,5.9229-24.5756c2.4041-8.0338,5.22-15.4673,10.2981-22.5415A162.606,162.606,0,0,1,81.482,549.9313a24.7669,24.7669,0,0,1,8.6739-4.26c2.3289-.5634,4.6057-.8182,6.8317-1.5474,3.313-2.5454,5.8077-7.4059,4.426-11.6155a.2946.2946,0,0,0-.3577-.2041.3167.3167,0,0,0-.2042.3715c.3716,4.1574-1.3433,9.5183-5.9873,10.39-5.3855,1.0224-10.4394,2.4563-14.6106,5.8461a185.6174,185.6174,0,0,0-15.7481,18.64,36.5518,36.5518,0,0,0-3.3775,5.5145,99.9211,99.9211,0,0,0-7.5993,18.96c-1.7394,6.5369-3.313,12.5242-4.7453,19.2545-1.2927,6.0764-1.1637,11.7444-1.9068,17.7317-.2041,1.6258-.4359,3.3268-1.6119,4.5534h0a.1015.1015,0,0,0,.0138.1428.0992.0992,0,0,0,.14-.0138l-.0522-.0615Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/11")}
        style={containerStyle}
      />
      <path
        d="M95.6689,530.5217c-2.3412,3.8-5.757,8.6233-3.0443,13.1123.7415,1.2527,2.5331.9978,3.6584.2932a11.7957,11.7957,0,0,0,5.08-8.1366c.4606-2.4947.1658-5.8215-2.5976-6.73-3.5187-.0906-4.81,4.12-6.038,6.7549h0a.1258.1258,0,0,0,.23.1013c1.2927-2.2383,2.418-5.7187,5.3226-6.04,2.3151,1.2174,2.2,4.3508,1.5475,6.5124-.8828,2.8908-2.5208,7.5624-6.2944,6.9591-1.2665-1.1791-.9211-2.98-.7937-4.4644a21.31,21.31,0,0,1,2.98-8.3408c.0123-.0138,0-.0261-.0122-.0369-.0123,0-.0384,0-.0384.0154h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/11")}
        style={containerStyle}
      />
      <path
        d="M41.7462,552.641a4.3739,4.3739,0,0,0,.2948,2.5576l-.0123.0507a.3238.3238,0,0,0,.1658.1535l.1151.0783a1.6373,1.6373,0,0,0,.8444.3961c4.0821-.2825,7.3306-2.8663,10.9261-4.644,3.2362-1.6,6.2053-3.2884,9.3525-5.2458,4.2725-2.636,8.0476-5.143,12.3462-7.65,7.1264-4.1558,14.5077-7.3183,22.5414-8.393h.2564l-.218-.1136.0768.0891c.5634.0261.7676.588,1.19.8044a.0749.0749,0,0,0,.1013-.0123c.0384-.0383.0261-.0783,0-.1166a4.47,4.47,0,0,1-.9334-1.1008l.0261-.0629a.3124.3124,0,0,0-.23-.1029l-.3454-.0138a.4909.4909,0,0,0-.499-.3454c-3.4158-.1274-6.563,1.179-9.7608,2.2767a66.9739,66.9739,0,0,0-15.4166,7.4842,249.7029,249.7029,0,0,1-25.7148,14.9683A18.8792,18.8792,0,0,1,43.18,554.887l-.6018-.18.1535.155-.3577-.4866v.1658a2.1708,2.1708,0,0,1-.5634-1.8929c0-.0261-.0123-.0368-.0261-.0368-.0261.0015-.0384.0138-.0384.0292h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/11")}
        style={containerStyle}
      />
      <path
        d="M38.1262,587.0666c-.2948-2.6867.7154-5.3349,1.9451-7.7022,2.3289-4.4505,7.151-5.975,11.3083-8.4928a4.31,4.31,0,0,0,1.65-1.3709.4431.4431,0,0,0,.0645-.6034.4382.4382,0,0,0-.6018-.0629c-4.3493,2.9691-9.6964,4.3861-12.9342,9.1606-1.8683,2.7634-1.7916,6.0257-1.4968,9.0823,0,.0138.0261.0276.0384.0276s.0261-.0276.0261-.0383Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/11")}
        style={containerStyle}
      />
      <path
        d="M39.5462,635.2322c-1.83-2.8278-1.4461-6.3819-1.2665-9.5812.3838-7.05,1.05-13.972,3.1472-20.84,5.0155-16.4129,7.7528-33.1222,18.7172-47.2968,1.5475-1.9973,2.329-4.26,3.7475-6.2436a.1188.1188,0,0,0-.0123-.1428.0982.0982,0,0,0-.1412.0154c-3.1718,3.76-6.563,7.1771-9.1468,11.5647a82.0115,82.0115,0,0,0-6.8578,15.76c-3.07,9.0578-5.6158,17.8085-8.0076,27.2377a91.6035,91.6035,0,0,0-2.5331,17.6273c-.23,4.03-.82,8.8275,2.3151,11.9485a.0245.0245,0,0,0,.0383,0,.0373.0373,0,0,0,0-.0491Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/11")}
        style={containerStyle}
      />
      <path
        d="M37.5367,583.138a20.0312,20.0312,0,0,0-.3071,4.2863l.218.3346a.3689.3689,0,0,0,.23.1382l.1274.0123a1.1765,1.1765,0,0,0,1.1253-.2549c2.418-1.7654,4.6056-3.1717,7.0359-4.9265a6.3963,6.3963,0,0,0,2.5208-3.9132.44.44,0,0,0-.2042-.588.4505.4505,0,0,0-.588.2164c-1.394,3.1211-4.5027,4.6057-7.1264,6.7166-.7554.6141-1.4462,1.23-2.1493,1.8561l-.6264.1535.23.1274-.218-.46c-.0123.0384-.0123.0767-.0261.1136a6.6357,6.6357,0,0,1-.1919-3.8c.0138-.0261,0-.0384-.0261-.0384a.0311.0311,0,0,0-.0246.0261h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/11")}
        style={containerStyle}
      />
      <path
        d="M39.712,591.8012a14.1209,14.1209,0,0,1,3.3775-1.9467c.1151-.0276.2564.0768.3961.1275l-.3838-.1275-.0123-.0123.1412.1275a.5145.5145,0,0,0,.1919.1274l.14.0507-.307-.2426.0261-.0645c.0645.3332.1274.6786.1919,1.0117a.52.52,0,0,0,.588.4345.5093.5093,0,0,0,.4344-.588,4.1083,4.1083,0,0,0-.1151-1.1146l-.1274-.3346a.6516.6516,0,0,0-.2932-.2288l-.1413-.0368.2042.1136-.1535-.1259a.5948.5948,0,0,0-.3961-.155l-.3838.0122a20.0337,20.0337,0,0,0-3.4158,2.9292h0a.023.023,0,0,0,0,.0368c0,.0169.0245.0169.0383.0062Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/11")}
        style={containerStyle}
      />
      <path
        d="M36.9748,600.1788c-.1413-5.0523,3.2485-9.2112,7.7528-11.2193a3.3834,3.3834,0,0,0,1.0608-1.0624.6061.6061,0,0,0,.0123-.8566.6238.6238,0,0,0-.8689-.0123,10.9406,10.9406,0,0,1-.9334.8044c-2.1371,1.3433-4.3493,2.4825-5.5145,4.7715a16.8229,16.8229,0,0,0-1.5613,7.5732.0261.0261,0,1,0,.0522.0015Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/11")}
        style={containerStyle}
      />
      <path
        d="M39.84,562.6429a1.2722,1.2722,0,0,0,.55-.0276c3.161-1.6366,5.975-3.531,9.1345-5.1691,4.4782-2.3028,8.84-4.2479,13.278-6.6782,1.5091-.82,3.1472-1.5229,4.1451-2.9429a.2179.2179,0,1,0-.281-.3332c-7.8418,4.81-16.4006,7.2155-24.3837,12.7422-.9718.6771-1.7532,1.4078-2.6221,2.0987h0a.2985.2985,0,0,0,.3085.5112l-.1289-.2011Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/11")}
        style={containerStyle}
      />
      <path
        d="M39.0841,566.7634c-.5879,1.42-1.1636,3.4266.4483,4.4521a1.462,1.462,0,0,0,1.3433.0123A46.8492,46.8492,0,0,0,54.7571,563.59c4.8743-3.9931,7.47-9.047,11.1042-14.279a16.5569,16.5569,0,0,1,3.7229-3.6584c.0261-.0276.0383-.0644.0122-.0905a.0727.0727,0,0,0-.0906-.0261c-1.8806,1.4984-3.9654,2.6221-5.4239,4.7084-3.121,4.5028-5.7447,8.5834-9.7224,12.14-4.0177,3.58-8.763,5.5144-13.4715,7.8556-.3454.1781-.806.5619-1.2788.2411-1.3433-.8951-.9979-2.4809-.4606-3.7106,0-.0123,0-.0261-.0261-.0384a.0453.0453,0,0,0-.0383.0307h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/11")}
        style={containerStyle}
      />
      <path
        d="M37.6518,628.7076c-.23,1.701-.7031,3.4281.0645,5.1046a4.3744,4.3744,0,0,0,4.6578,2.5223,6.7259,6.7259,0,0,0,5.757-6.7442,4.2753,4.2753,0,0,0-5.0017-4.1834c-2.0848.4729-4.26,1.1391-5.2826,3.0827-.9334,1.7777-1.1253,4.3631.2426,6a.1475.1475,0,0,0,.218.0368.1724.1724,0,0,0,.026-.2287,5.2815,5.2815,0,0,1,.2058-5.1691c.9334-1.65,2.7511-2.3028,4.5672-2.6866a3.1547,3.1547,0,0,1,3.5187,2.4563,5.043,5.043,0,0,1-1.4339,4.644,6.1921,6.1921,0,0,1-4.81,1.5613c-2.3795-1.0992-3.21-3.9777-2.66-6.3972a.0281.0281,0,0,0-.0261-.0261.0292.0292,0,0,0-.043.0277Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/11")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
    cursor: "pointer"
}
