import React, { Fragment } from "react";

// style
import { green, grayscale } from "../../Utilities";

export default function VectorVII(props) {
  const { nerveId } = props;
  return (
    <Fragment>
      <path
        d="M48.75,489.1847s5.7187-5.6035,9.0961-8.674,10.593-8.5971,14.2775-11.514c3.6845-2.9154,9.9789-7.6761,13.3563-10.2844a74.702,74.702,0,0,0,10.1324-9.3648c2.4564-2.9169,5.22-5.9873,6.6014-7.8311,1.3817-1.8423,2.9169-3.838,4.6057-3.9931a9.2191,9.2191,0,0,1,6.1408,1.9973c1.3817,1.3817,2.7634,2.3028,2.9169,3.9915s.46,2.7649-.9212,4.7592a91.8376,91.8376,0,0,1-7.5225,8.7522,134.5788,134.5788,0,0,1-11.514,10.2844c-3.3775,2.4563-9.8254,6.2944-12.5888,8.1381-2.7633,1.8407-7.983,6.2944-10.5929,8.2886-2.61,1.9958-6.7534,5.3732-9.0562,6.7534s-5.8338,3.2255-8.5972,2.9169c-2.7649-.304-6.3343-4.22-6.3343-4.22"
        fill={nerveId == "7" ? green.bright : grayscale.gray}
        onClick={() => props.history.push("/nerve/7")}
        style={containerStyle}
      />
      <path
        d="M105.4175,437.5893c-3.07,2.4441-2.0986,6.7289.4728,9.1607,2.137,2.02,5.3088,3.6077,8.1612,2.2122a4.44,4.44,0,0,0,2.7772-3.6093,5.7152,5.7152,0,0,0-.5634-2.9153c-1.7778-3.442-5.6035-6.3067-9.6458-5.2474a4.1506,4.1506,0,0,0-2.6866,2.0741,6.2922,6.2922,0,0,0,1.0486,7.1894.35.35,0,0,0,.4989.0277.3652.3652,0,0,0,.0261-.5128,5.9558,5.9558,0,0,1-.78-6,2.6373,2.6373,0,0,1,1.6381-1.5352c3.6078-1.1376,7.062,1.4078,8.84,4.3753a3.396,3.396,0,0,1-.089,3.8381,3.9412,3.9412,0,0,1-4.03,1.5628,9.1582,9.1582,0,0,1-7.1-6.4985,4.0727,4.0727,0,0,1,1.4707-4.0791c.0123-.0138.0123-.0384,0-.0384a.0225.0225,0,0,0-.0383-.0046h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/7")}
        style={containerStyle}
      />
      <path
        d="M90.7946,471.3256a24.3221,24.3221,0,0,0,4.9772-3.1211c4.4137-3.9271,8.9042-7.151,12.9971-11.3989a76.0416,76.0416,0,0,0,6.7933-7.4964,5.6629,5.6629,0,0,0,.9211-5.0539.1161.1161,0,0,0-.23,0c-1.3694,5.7417-6.6658,9.2619-10.3626,13.51-5.232,4.2986-10.1831,8.8781-15.148,13.4837h0a.0756.0756,0,0,0-.0122.0891.0733.0733,0,0,0,.0905.0123l-.026-.0246Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/7")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
  cursor: "pointer"
};
