import React, { useState, useEffect } from "react";

// components
import NerveCard from "./NerveCard";

import styled from "styled-components";

// Data
import { cranialData } from "../Static/Data";

export default function HomeNonAuth(props) {
  useEffect(() => {
    props.handleLocation("home");
  }, []);
  return (
    <HomeContainer>

    </HomeContainer>
  );
}

const HomeContainer = styled.div`
  padding: 25px 15px 250px 15px;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  max-width: 1000px;
  margin: auto;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 900px;
  }
  @media (max-width: 650px) {
    grid-template-columns: 1fr 1fr;
    max-width: 500px;
  }
  @media (max-width: 425px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    padding: 0;
    padding-bottom: 50px;
  }
  @media (max-width: 320px) {
    grid-template-columns: 1fr;
  }
`;
