export const foraminaData = {
  data: {
    foraminaData: [
      {
        id: "cribiform-plate",
        name: "Cribiform Plate",
        nerves: ["Olfactory Nerves (CN I)"],
        vessels: [
          "Posterior Ethmoidal Artery & Vein",
          "Anterior Ethmoidal Artery & Vein"
        ],
        miscellaneous: []
      },
      {
        id: "optic-canal",
        name: "Optic Canal",
        nerves: ["Optic Nerve (CN II)"],
        vessels: ["Ophthalmic Artery"],
        miscellaneous: []
      },
      {
        id: "superior-orbital-fissure",
        name: "Superior Orbital Fissuere",
        nerves: [
          "Oculomotor Nerve (CN III)",
          "Trochlear Nerve (CN IV)",
          "Lacrimal, frontal, and nasociliary branches of Ophthalmic Nerve (V1)",
          "Abducens Nerve (VI)"
        ],
        vessels: ["Superior Ophthalmic Vein"],
        miscellaneous: []
      },
      {
        id: "foramen-rotundum",
        name: "Foramen Rotundum",
        nerves: ["Maxillary Nerve (V2)"],
        vessels: [],
        miscellaneous: []
      },
      {
        id: "foramen-ovale",
        name: "Foramen Ovale",
        nerves: ["Mandibular Nerve (V3)", "Lesser Petrosal Nerve"],
        vessels: ["Accessory Meningeal Artery"],
        miscellaneous: []
      },
      {
        id: "foramen-spinosum",
        name: "Foramen Spinosum",
        nerves: ["Meningeal Brach of Mandibular Nerve (V3)"],
        vessels: ["Middle Meningeal Artery & Vein"],
        miscellaneous: []
      },
      {
        id: "foramen-lacerum",
        name: "Foramen Lacerum",
        nerves: ["Greater Petrosal Nerve (Branch of CN VII)"],
        vessels: [],
        miscellaneous: []
      },
      {
        id: "carotid-canal",
        name: "Carotid Canal",
        nerves: ["Internal carotid nerve plexus"],
        vessels: ["Internal carotid artery"],
        miscellaneous: []
      },
      {
        id: "internal-acoustic-meatus",
        name: "Internal Acoustic Meatus",
        nerves: ["Facial Nerve (CN VII)", "Vestibulocochlear Nerve (CN VIII)"],
        vessels: ["Labyrinthine Artery"],
        miscellaneous: []
      },
      {
        id: "jugular-foramen",
        name: "Jugular Foramen",
        nerves: [
          "Glossopharyngeal Nerve (CN IX)",
          "Vagus Nerve (CN X)",
          "Accessory Nerve (CN XI)"
        ],
        vessels: ["Posterior Meningeal Artery"],
        miscellaneous: ["Inferior Petrosal Sinus", "Sigmoid Sinus"]
      },
      {
        id: "hypoglossal-canal",
        name: "Hypoglossal Canal",
        nerves: ["Hypoglossal Nerve (CN XII)"],
        vessels: [],
        miscellaneous: []
      },
      {
        id: "foramen-magnum",
        name: "Foramen Magnum",
        nerves: ["Spinal roots of Accessory Nerve (CN XI)"],
        vessels: [
          "Vertebral Arteries",
          "Meningeal Branches of Verteral Arteries"
        ],
        miscellaneous: ["Medulla Oblongata", "Meninges"]
      }
    ]
  }
};
