import React, { Fragment } from "react";

export default function ForamenMagnum(props) {
  const { nerveId, foramenId, green, grayscale } = props;
  return (
    <Fragment>
      <path
        d="M3.74,583.6811c8.2326.2093,12.8372-2.93,14.5116-4.1861,1.2559-.6977,5.93-5.093,7.9535-7.5349a53.3344,53.3344,0,0,1,8.1628-8.4418c2.7209-3,7.6046-4.3953,9.9069-8.6511.6977-1.1163,2.5117-8.4419,2.5117-9.2093s2.3721-8.2326,2.86-9.907a18.4134,18.4134,0,0,1,2.2326-4.1163s6.9767-10.6744,8.686-17.8255c1.2558-4.8837-.1744-11.7209-.8023-13.7093s-5.2674-6.9767-6.2093-8.093-5.1977-3.6628-5.1977-3.6628-5.2325-3.7674-6.4186-4.9883c-2.4767-1.814-5.9651-9.0349-5.9651-9.0349s-5.3255-8.8488-7.6511-14.1511-5.0233-9.9535-9.2093-13.8605-15.5348-7.9069-15.5348-7.9069Z"
        fill={
          foramenId == "foramen-magnum"
            ? green.bright
            : grayscale.gray
        }
        onClick={() => props.history.push("/foramina/foramen-magnum")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
  cursor: "pointer"
};
