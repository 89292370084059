import React, { Fragment } from "react";

export default function ForamenSpinosum(props) {
  const { nerveId, foramenId, green, grayscale } = props;
  return (
    <Fragment>
      <path
        d="M118.5423,350.3792c.6489-.6083,3.314-.2093,3.4535-.1744a8.809,8.809,0,0,1,3.8023,2.6511,8.2147,8.2147,0,0,1,1.7442,3.6279,3.9925,3.9925,0,0,1-.3139,2.5117,3.1125,3.1125,0,0,1-3.07,1.0814c-1.7442-.3489-5.3023-2.6861-5.7907-5.3721S117.9842,350.9025,118.5423,350.3792Z"
        fill={
          nerveId == "5" || foramenId == "foramen-spinosum"
            ? green.bright
            : grayscale.gray
        }
        onClick={() => props.history.push("/foramina/foramen-spinosum")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
  cursor: "pointer"
};
