import React from "react";

// Styles
import styled from "styled-components";
import {
  Logo,
  SmallText,
  AnchorTag,
  fontFamily,
} from "../Utilities";
import { grayscale } from "../Utilities";

export default function Footer(props) {
  return (
    <FooterContainer>
      <FooterInnerContainer>
        <LogoContainer>
          <Logo>MEDZCOOL</Logo>
          <SmallText>
            © <span>{new Date().getFullYear()}.</span> ALL RIGHTS RESERVED
          </SmallText>
        </LogoContainer>
        <Menu>
          <Column>
            <div className="footer-section-title">EXPLORE</div>
            <ul>
              <li>
                <AnchorTag
                  color={`${grayscale.lightGray}`}
                  href="https://medzcool.com"
                >
                  Home
                </AnchorTag>
              </li>
              <li>
                <AnchorTag
                  color={`${grayscale.lightGray}`}
                  href="https://youtube.com/medzcool"
                >
                  Videos
                </AnchorTag>
              </li>
              <li>
                <AnchorTag
                  color={`${grayscale.lightGray}`}
                  href="https://medium.com/@medzcool"
                >
                  Articles
                </AnchorTag>
              </li>
            </ul>
          </Column>
          <Column>
            <div className="footer-section-title">FOLLOW US</div>
            <ul>
              <li>
                <AnchorTag
                  color={`${grayscale.lightGray}`}
                  href="https://youtube.com/medzcool"
                >
                  YouTube
                </AnchorTag>
              </li>
              <li>
                <AnchorTag
                  color={`${grayscale.lightGray}`}
                  href="https://instagram.com/medzcool"
                >
                  Instagram
                </AnchorTag>
              </li>
              <li>
                <AnchorTag
                  color={`${grayscale.lightGray}`}
                  href="https://facebook.com/medzcooleducation"
                >
                  Facebook
                </AnchorTag>
              </li>
              <li>
                <AnchorTag
                  color={`${grayscale.lightGray}`}
                  href="https://twitter.com/medzcool"
                >
                  Twitter
                </AnchorTag>
              </li>
              <li>
                <AnchorTag
                  color={`white`}
                  href="https://medium.com/@medzcool"
                >
                  Medium
                </AnchorTag>
              </li>
            </ul>
          </Column>
          <Column>
            <div className="footer-section-title">CONTACT</div>
            <ul>
              <li>
                <a href="mailto:hello@medzcool.com" className="mailto">
                  hello@medzcool.com
                </a>
              </li>
            </ul>
          </Column>
        </Menu>
      </FooterInnerContainer>
    </FooterContainer>
  );
}

const FooterContainer = styled.div`
  background-color: #1D1D1D;
  padding: 60px 15px 60px 15px;
  .footer-section-title {
    font-size: 14px;
    font-family: futura-pt, sans-serif;
    font-weight: 500;
    color: ${grayscale.lightGray};
  }
  ${SmallText} {
    margin-top: 5px;
    color: white;
    font-weight: 700;
  }
  .mailto {
    color: ${grayscale.lightGray};
  }
  @media (max-width: 1024px) {
    padding: 60px 25px 60px 25px;
  }
`;

const LogoContainer = styled.div``;

const FooterInnerContainer = styled.div`
  display: grid;
  height: 100%;
  max-width: 1300px;
  margin: auto;
  grid-template-columns: 2fr 4fr 2fr;
  @media (max-width: 1024px) {
    grid-template-columns: 3fr 5fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    ${LogoContainer} {
      margin-bottom: 35px;
    }
  }
`;

const Menu = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const Column = styled.div`
  margin-bottom: 30px;
  ul {
    list-style: none;
    padding: 0;
    li {
      font-family: futura-pt, sans-serif;
      color: white;
      margin-bottom: 8px;
    }
  }
`;
const Newsletter = styled.div`
  ${SmallText} {
    margin-top: 5px;
    margin-bottom: 15px;
    color: ${grayscale.lightGray};
    font-weight: 700;
  }
  input {
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    max-width: 500px;
  }
`;
