import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { url } from "./settings";
import * as serviceWorker from "./serviceWorker";

import { BrowserRouter } from "react-router-dom";

// Apollo
import ApolloClient from "apollo-boost";
import { ApolloProvider, Query } from "react-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";

const cache = new InMemoryCache();
const client = new ApolloClient({
  cache,
  uri: `${url}/graphql/`,
  resolvers: {},
  credentials: "include"
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App client={client}/>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
