import React, { Fragment } from "react";

import VectorI from "./VectorI";
import VectorII from "./VectorII";
import VectorIII from "./VectorIII";
import VectorIV from "./VectorIV";
import VectorV from "./VectorV";
import VectorVI from "./VectorVI";
import VectorVII from "./VectorVII";
import VectorVIII from "./VectorVIII";
import VectorIX from "./VectorIX";
import VectorX from "./VectorX";
import VectorXI from "./VectorXI";
import VectorXII from "./VectorXII";
import Cerebrum from "./Cerebrum";

import styled from "styled-components";

export default function NerveSVG(props) {
  return (
    <SVGContainer className="nerve-svg-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="333"
        height="781"
        viewBox="0 0 333 781"
      >
        <title>Cranial Nerves (Ventral Brain)</title>
        <Cerebrum {...props} />

        <VectorXI {...props} />
        <VectorX {...props} />
        <VectorIX {...props} />
        <VectorXII {...props} />
        <VectorVIII {...props} />
        <VectorVII {...props} />
        <VectorVI {...props} />
        <VectorV {...props} />
        <VectorIV {...props} />
        <VectorIII {...props} />
        <VectorI {...props} />
        <VectorII {...props} />
      </svg>
    </SVGContainer>
  );
}

const SVGContainer = styled.div`
  svg {
    width: 100%;
    height: auto;
  }
`;
