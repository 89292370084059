import React, { useState, Fragment } from "react";

// Dependencies
import { Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";

import Menu from "./Menu";

import styled from "styled-components";
import { fontFamily } from "../Utilities";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faHome,
  faBars
} from "@fortawesome/pro-regular-svg-icons";

export default function Header(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }
  const menuAnimation = useSpring({
    transform: isMenuOpen ? `translate3d(0, 0, 0)` : `translate3d(100%, 0, 0)`
  });
  const menuOpacity = useSpring({
    opacity: isMenuOpen ? 0.5 : 0,
    display: isMenuOpen ? `block` : `none`
  });
  function renderLeft() {
    if (!props.userHasApp) {
      return (
        <div className="left">
          <span className="nav-item logo">CRANIAL</span>

          <span className="long">
            <a href="#" className="nav-item">
              PRICING
            </a>
            <a href="https://medzcool.com/cranial" className="nav-item">
              SIGN-UP
            </a>
            <a href="https://medzcool.com/login/cranial" className="nav-item">
              LOGIN
            </a>
          </span>
        </div>
      );
    } else {
      if (props.location == "nerve" || props.location == "foramina") {
        return (
          <div className="left">
            <span
              className="nav-item back"
              onClick={() => window.history.back()}
            >
              <FontAwesomeIcon icon={faChevronLeft} /> {"  "} BACK
            </span>

            <span className="long">
              <Link to="/" className="nav-item">
                HOME
              </Link>
              <Link to="/quizzes" className="nav-item">
                QUIZZES
              </Link>
              <Link to="/foramina" className="nav-item">
                FORAMINA
              </Link>
            </span>
          </div>
        );
      } else if (props.location == "collection" || null) {
        return (
          <div className="left">
            <Link className="nav-item" to="/quizzes">
              <FontAwesomeIcon icon={faChevronLeft} /> {"  "} BACK
            </Link>

            <span className="long">
              <Link to="/" className="nav-item">
                HOME
              </Link>
              <Link to="/quizzes" className="nav-item">
                QUIZZES
              </Link>
              <Link to="/foramina" className="nav-item">
                FORAMINA
              </Link>
            </span>
          </div>
        );
      } else {
        return (
          <div className="left long">
            <Link to="/" className="nav-item">
              HOME
            </Link>
            <Link to="/quizzes" className="nav-item">
              QUIZZES
            </Link>
            <Link to="/foramina" className="nav-item">
              FORAMINA
            </Link>
          </div>
        );
      }
    }
  }

  return (
    <Fragment>
      <HeaderContainer>
        {renderLeft()}

        <div className="center">
          <div className="nav-item"></div>
        </div>

        <div className="right">
          {props.userHasApp && <a href="https://api.medzcool.com/logout" className="logout">LOGOUT</a>}

          <div className="menu-bars">
            <div
              className="nav-item no-margin-right"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <FontAwesomeIcon icon={faBars} size="lg" />
            </div>
          </div>
          <Menu
            style={menuAnimation}
            toggleMenu={toggleMenu}
            opacity={menuOpacity}
            userHasApp={props.userHasApp}
          />
        </div>
      </HeaderContainer>
    </Fragment>
  );
}

const HeaderContainer = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: auto auto auto;
  margin-bottom: 25px;
  width: 100%;
  .center {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: auto auto auto;
    justify-items: center;
    max-width: fit-content;
    margin: auto;
  }
  .right {
    display: grid;
    justify-items: right;
    .menu-bars {
      display: none;
    }
  }
  a {
    text-decoration: none;
    color: black;
  }
  .nav-item {
    margin-right: 45px;
    cursor: pointer;
    &.no-margin-right {
      margin-right: 0;
    }
    &.back {
      cursor: pointer;
    }
    &.logo {
      font-weight: 700;
      font-size: 24px;
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    .center {
      display: none;
    }
    .right {
      grid-column-start: 2;
      .menu-bars {
        display: block;
      }
      .logout {
          display: none;
      }
    }
    .left {
      &.long {
        display: none;
      }
      .long {
        display: none;
      }
    }
  }
`;
