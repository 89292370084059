import React, { Component } from "react";

// components
import NerveSVG from "../Nerve/SVG/NerveSVG";

import styled from "styled-components";

export default function NerveCard(props) {
  const { nerve } = props;
  return (
    <NerveCardContainer
      onClick={() => props.history.push("/nerve/" + nerve.id)}
    >
      <div className="label">{`CN ${nerve.numeral}`}</div>
      <div className="nerve-name">{nerve.name}</div>
      <div className="nerve-svg">
        <NerveSVG {...props} nerveId={nerve.id} />
      </div>
    </NerveCardContainer>
  );
}

const NerveCardContainer = styled.div`
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 271px;
  padding: 10px;
  cursor: pointer;
  position: relative;
  .nerve-name {
    font-weight: 700;
    z-index: 1;
    position: relative;
    font-size: 22px;
  }
  .label {
    z-index: 1;
    position: relative;
  }
  .nerve-svg {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    transform: scaleX(-1);
    height: inherit;
  }
  .nerve-svg-container {
    position: absolute;
    top: 0;
    height: inherit;
    svg {
      width: inherit;
      height: inherit;
    }
  }
  @media (max-width: 768px) {
    .nerve-name {
      font-size: 20px;
    }
  }
  @media (max-width: 425px) {
    height: 200px;
    font-size: 14px;
    .nerve-name {
      font-size: 18px;
    }
  }
`;
