export const development = false;

export let url;
export let homeUrl;

if (development === true) {
  url = "http://127.0.0.1:8000";
  homeUrl = "localhost:3000"
} else {
  url = "https://api.medzcool.com";
  homeUrl = "https://medzcool.com"
}
