import React, { useState, useEffect, Fragment } from "react";
import { url, homeUrl } from "../settings";

// Dependencies
import { Link, Route } from "react-router-dom";
import axios from "axios";

// Components
import Spinner from "../Utilities/Spinner";
import { StyledButton, yellow, red, blue } from "../Utilities";

// Styles
import styled from "styled-components";
import { H1, LargeText, MediumText, fontFamily } from "../Utilities";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";

export default function CollectionCard(props) {
  // console.log(props);
  const { collection, user } = props;
  function toCurrency(number) {
    const integer = parseInt(number);
    const currency = (integer / 100).toFixed(2);
    return currency;
  }
  const [isSubscribed, setIsSubscribed] = useState(false);
  useEffect(() => {
    if (user) {
      const subscribedRounds = user.profile.subscribedRounds;
      subscribedRounds.map(subcribedCollection => {
        if (subcribedCollection.id == collection.id) {
          setIsSubscribed(true);
        }
      });
    }
  }, []);

  return (
    <CollectionCardContainer>
      <div className="img-container">
        <img
          src={props.thumbnail}
          onClick={() =>
            props.history.push(`/collection/${props.url}`)
          }
        />
      </div>

      <div className="collection-desc">
        <MediumText className="desc-block">
          <strong className="title">{props.title}</strong>
          <MediumText className="case-count">
            {props.caseCount + " cases"}
          </MediumText>
        </MediumText>

        <MediumText>{props.description}</MediumText>
      </div>

      <div className="button">
        <StyledButton
          active
          background={`${blue.bright}`}
          color={"white"}
          onClick={() =>
            props.history.push(`/collection/${props.url}`)
          }
          boxShadow={"0px 5px 5px rgba(0,0,0,0.1);"}
          borderRadius={"4px;"}
          fontWeight={"700"}
        >
          <FontAwesomeIcon icon={faPlay} />
          <span className="view-collection-text">VIEW QUIZ</span>
        </StyledButton>
      </div>
    </CollectionCardContainer>
  );
}

const CollectionCardContainer = styled.div`
  .img-container {
    position: relative;
    .sale-banner {
      position: absolute;
      background: #fd2c9b;
      padding: 8px 15px;
      top: 15px;
      left: -10px;
      color: white;
      font-family: futura-pt, sans-serif;
      font-weight: 700;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    }
  }
  img {
    width: 100%;
    max-height: 250px;
    object-fit: cover;
    cursor: pointer;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  }
  .collection-desc {
    padding: 0 0 15px 0;
    .price {
      margin-right: 8px;
      &.strikethrough {
        text-decoration: line-through;
        font-weight: 300;
      }
    }
    .case-count {
      font-size: 14px;
      color: gray;
    }
  }
  .desc-block {
    margin: 15px 0;
    .title {
      font-size: 20px;
    }
    .inline {
      display: inline-block;
    }
    .spacer {
      margin: 0 6px;
    }
  }
  .buttons {
    margin: 8px 0;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 10px;
    .learn-more {
      color: #295aec;
      text-decoration: none;
      align-self: center;
      font-family: futura-pt, sans-serif;
      cursor: pointer;
    }
  }
  .button {
    margin: 8px 0;
    .view-collection-text {
      margin-left: 10px;
    }
  }
  @media (max-width: 768px) {
    .collection-desc {
      padding: 0 0 15px 0;
    }
  }
`;
