import React, { useState, Fragment } from "react";
import { useSpring, animated } from "react-spring";

// Components
import Menu from "./Menu";

// Dependencies
import { Link } from "react-router-dom";

// Utilities
import { StyledButton, green, red } from "../Utilities";

// Styles
import styled from "styled-components";
import { themeColors, fontFamily, fontWeight } from "../Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faBars,
  faSignOut
} from "@fortawesome/pro-regular-svg-icons";
import { faBackward } from "@fortawesome/pro-solid-svg-icons";

import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";

const LOGOUT = gql`
  mutation logout {
    logout {
      user {
        id
      }
    }
  }
`;

export default function CaseDetailsHeader(props) {
  const user = props.user;
  const { answered, correct } = props;
  console.log(props.match);

  function collectionBack() {
    props.history.push(`/collection/${props.match.params.collectionUrl}`);
    props.togglePlayUnused(false);
  }
  if (!props.match) return null;

  if (!user) {
    return (
      <HeaderContainer correct={correct} answered={answered}>
        <HeaderInnerContainer>
          <HeaderLeft>
            <Logo></Logo>
          </HeaderLeft>
          <HeaderRight>
            <div className="desktop">
              <span className="menu-item sign-up">
                <Link to="/register">SIGN UP</Link>
              </span>
              <span className="menu-item">
                <Link to="/login">LOGIN</Link>
              </span>
            </div>
          </HeaderRight>
        </HeaderInnerContainer>
      </HeaderContainer>
    );
  } else {
    return (
      <HeaderContainer correct={correct} answered={answered}>
        <HeaderInnerContainer>
          <HeaderLeft>
            <Logo>
              <div className="menu-item">
                {props.match.params.collectionUrl && (
                  <StyledButton
                    onClick={collectionBack}
                    fontSize={"14px;"}
                    padding={"15px;"}
                    background={"none;"}
                    width={"fit-content;"}
                    color={answered ? "black;" : "white;"}
                  >
                    <FontAwesomeIcon icon={faBackward} />{" "}
                    <span className="text-right">Back to Collection</span>
                  </StyledButton>
                )}

                {!props.match.params.collectionUrl && (
                  <StyledButton
                    onClick={props.history.goBack}
                    fontSize={"14px;"}
                    padding={"15px;"}
                    background={"none;"}
                    width={"fit-content;"}
                    color={answered ? "black;" : "white;"}
                  >
                    <FontAwesomeIcon icon={faBackward} />{" "}
                    <span className="text-right">Back</span>
                  </StyledButton>
                )}
              </div>
            </Logo>
          </HeaderLeft>
          <HeaderRight />
        </HeaderInnerContainer>
      </HeaderContainer>
    );
  }
}

const HeaderContainer = styled.div`
  width: 100%;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  height: 50px;
  background-color: ${themeColors.dark};
  ${props =>
    props.correct && props.answered && `background-color: ${green.bright}`}
  ${props =>
    !props.correct && props.answered && `background-color: ${red.bright}`}
`;
const HeaderInnerContainer = styled.div`
  display: grid;
  height: 100%;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  max-width: 1100px;
  margin: auto;
  padding: 0 15px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0;
  }
`;
const HeaderRight = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  color: white;
  justify-self: end;
  align-items: center;
  font-family: ${fontFamily.sansSerif};
  .menu-item {
    cursor: pointer;
    a {
      text-decoration: none;
      color: white;
    }
    &.sign-up {
      margin-right: 25px;
    }
  }
`;
const HeaderLeft = styled.div`
  color: white;
  width: 300px;
  .logo-icon {
    height: 30px;
    display: none;
  }
  .logo-text {
    a {
      text-decoration: none;
      color: white;
    }
  }
  .menu-item {
    font-family: ${fontFamily.sansSerif};
    cursor: pointer;
    a {
      text-decoration: none;
      color: white;
    }
  }
  .mobile {
    display: none;
  }
  @media (max-width: 768px) {
    width: 100%;
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
  @media (max-width: 425px) {
    .logo-icon {
      display: block;
    }
    .logo-text {
      display: none;
    }
  }
`;

export const Logo = styled.div`
  font-family: ${fontFamily.sansSerif};
  color: white;
  font-weight: ${fontWeight.bold}
  font-size: 22px;
`;
