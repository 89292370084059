import React, { useState, useEffect } from "react";

// Dependencies
import { Link } from "react-router-dom";

// components
import CollectionCard from "./CollectionCard";

// styles
import styled from "styled-components";

export default function Collections(props) {
  useEffect(() => {
    props.handleLocation();
  }, []);
  return (
    <HomeContainer>
      <h2>Quizzes</h2>

      <div className="body-grid">
        <CollectionCard
          title="Functions of the Cranial Nerves"
          url="cranial-nerve-functions"
          thumbnail="https://medzcool.s3-us-west-2.amazonaws.com/Rounds/cranial-nerve-function-thumb.svg"
          caseCount="16"
          description="A quiz testing your knowledge in identifying functions of the cranial nerves"
          {...props}
        />
        <CollectionCard
          title="Cranial Nerve Numerals"
          url="cranial-nerve-numerals"
          thumbnail="https://medzcool.s3-us-west-2.amazonaws.com/Rounds/roman-numerals-thumb.svg"
          caseCount="12"
          description="A quiz to reinforce the association of the corresponding cranial nerve roman numeral with its name"
          {...props}
        />
        <CollectionCard
          title="Ventral Cranial Anatomy"
          url="ventral-cranial-anatomy"
          thumbnail="https://medzcool.s3-us-west-2.amazonaws.com/Rounds/ventral-cranial-collection-thumb.jpg"
          caseCount="12"
          description="Testing your knowledge on the anatomical location of the cranial nerves on the ventral surface of the brain"
          {...props}
        />
      </div>
    </HomeContainer>
  );
}

const HomeContainer = styled.div`
  padding: 25px 15px 250px 15px;
  max-width: 1000px;
  margin: auto;
  h2 {
    margin-bottom: 40px;
  }
  .body-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 120px 50px;
  }
  @media (max-width: 1024px) {
    max-width: 900px;
    .body-grid {
      grid-gap: 75px 25px;
    }
  }
  @media (max-width: 768px) {
    .body-grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (max-width: 425px) {
    padding: 0;
    padding-bottom: 50px;
    .body-grid {
      grid-template-columns: 1fr;
      grid-gap: 75px;
    }
  }
`;
