import React, { Fragment } from "react";

// style
import { green, grayscale } from "../../Utilities";

export default function VectorVI(props) {
  const { nerveId } = props;
  return (
    <Fragment>
      <path
        d="M24.994,465.9247s4.7592-5.6787,6.2944-8.5972,5.8338-10.2844,8.4436-13.2028,5.8338-7.5225,8.29-9.9789a65.7287,65.7287,0,0,1,5.8338-5.3732c1.0747-.7676,5.22-1.8423,7.5226,1.0731s3.838,5.0678,3.3774,7.217-2.4563,4.6057-3.9915,6.4464c-1.5352,1.8423-8.7507,10.5929-11.0535,13.6633s-5.9874,8.4437-7.2155,11.3606a56.8677,56.8677,0,0,0-2.9169,7.676c-.4606,1.9943-.9211,5.3733-.9211,5.3733s-1.9958-6.1409-4.4521-8.9058c-2.4579-2.7587-9.2113-6.7518-9.2113-6.7518Z"
        fill={nerveId == "6" ? green.bright : grayscale.gray}
        onClick={() => props.history.push("/nerve/6")}
        style={containerStyle}
      />
      <path
        d="M53.707,432.0872c1.0363,3.3667,2.5853,6.8716,5.949,8.4974,1.3049.6263,2.8524,1.0854,4.1834.1919,2.943-1.9728,1.9068-5.8108.5251-8.3808a10.9969,10.9969,0,0,0-5.668-5.0262c-1.7394-.6786-4.3754-.7415-5.1553,1.2159-1.7133,4.3354,1.024,9.48,5.5007,10.8877a.263.263,0,0,0,.3454-.0906.25.25,0,0,0-.1029-.3454c-3.3268-1.87-5.63-5.7079-4.6317-9.5828.307-1.19,1.8177-1.1391,2.84-.9472a8.3071,8.3071,0,0,1,4.951,3.3882c1.6626,2.0863,3.3007,5.8491.7415,7.742-1.1376.8444-2.5592.1536-3.62-.6033-2.61-1.8285-5.0017-3.8641-5.8092-6.9591,0-.0276-.0123-.0276-.0384-.0276-.0108.0153-.023.0291-.0108.04Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/6")}
        style={containerStyle}
      />
      <path
        d="M24.5518,465.5946a2.2861,2.2861,0,0,0,2.8786-.8581c4.8482-6.1685,7.676-12.7791,12.5887-18.9077,4.274-5.3348,7.88-10.5269,12.9218-15.0942a19.037,19.037,0,0,1,3.6461-2.4041.5461.5461,0,1,0-.3054-1.0486,11.5837,11.5837,0,0,0-5.361,3.2762c-3.838,3.5309-6.6521,7.4826-9.9789,11.5125-6.0379,7.2922-9.5828,15.1878-15.326,22.7871-.2041.2809-.64.347-.9978.46h0a.2628.2628,0,0,0-.218.3071.2765.2765,0,0,0,.3193.2042l-.1674-.235Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/6")}
        style={containerStyle}
      />
      <path
        d="M39.0212,486.4474c-.4483-10.246,4.7975-18.9706,10.682-27.5309,4.2218-6.1148,8.7123-10.7833,13.3563-16.3485a8.9244,8.9244,0,0,0,1.9835-6.5906.0514.0514,0,0,0-.1028,0c-.0261.5634.1274,1.179-.218,1.6365-6.41,8.892-13.5728,15.4934-19.2792,24.7676-4.5411,7.3936-8.482,15.4933-6.4725,24.0659,0,.0261.0123.0368.0261.0261.0246,0,.0369-.0154.0246-.0261Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/6")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
    cursor: "pointer"
}
