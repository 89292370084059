import React, { useState, useEffect, Fragment } from "react";

// components
import NerveCard from "./NerveCard";
// components
import CollectionCard from "../Collection/CollectionCard";

import styled from "styled-components";

// Data
import { cranialData } from "../Static/Data";

export default function Home(props) {
  useEffect(() => {
    props.handleLocation("home");
  }, []);
  return (
    <HomepageContainer>
      <div className="block">
        <h2>Featured Quiz</h2>
        <div className="body-grid">
          <CollectionCard
            title="Functions of the Cranial Nerves"
            url="cranial-nerve-functions"
            thumbnail="https://medzcool.s3-us-west-2.amazonaws.com/Rounds/cranial-nerve-function-thumb.svg"
            caseCount="16"
            description="A quiz testing your knowledge in identifying functions of the cranial nerves"
            {...props}
          />
        </div>
      </div>
      <div className="block">
        <h2>Cranial Nerves</h2>
        <CranialContainer>
          {cranialData.data.cranialNerves.map(nerveObj => {
            return <NerveCard nerve={nerveObj} key={nerveObj.id} {...props} />;
          })}
        </CranialContainer>
      </div>
    </HomepageContainer>
  );
}
const HomepageContainer = styled.div`
  padding-top: 25px;
  max-width: 1000px;
  margin: auto;
  .block {
    margin-bottom: 100px;
  }
  .body-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 120px 50px;
  }
  h2 {
    font-size: 28px;
  }
  @media (max-width: 1024px) {
    max-width: 900px;
    .body-grid {
      grid-gap: 75px 25px;
    }
  }
  @media (max-width: 768px) {
    .body-grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (max-width: 425px) {
    padding: 0;
    padding-bottom: 50px;
    .body-grid {
      grid-template-columns: 1fr;
      grid-gap: 75px;
    }
  }
`;

const CranialContainer = styled.div`
  padding: 25px 0px 150px 0px;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 900px;
  }
  @media (max-width: 650px) {
    grid-template-columns: 1fr 1fr;
    max-width: 500px;
  }
  @media (max-width: 425px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    padding: 0;
    padding-bottom: 50px;
  }
  @media (max-width: 320px) {
    grid-template-columns: 1fr;
  }
`;
