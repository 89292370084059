import React, { Fragment } from "react";

// components
import ForaminaSVG from "./ForaminaSVG";

// Dependencies
import { Link } from "react-router-dom";

// style
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/pro-solid-svg-icons";
import { green } from "../../Utilities";

export default function ForaminaMobile(props) {
  const { foramen } = props;

  console.log(props);

  if (!foramen) {
    return (
      <Fragment>
        <NerveTopContainerMobile>
          <div className="title">
            <div className="nerve-name">Select a Foramen</div>
          </div>
          <div className="info">
            {props.foramens.map((foramensObj, index) => {
              return (
                <div key={index} className="styled-button">
                  <Link to={`/foramina/${foramensObj.id}`}>
                    {foramensObj.name}
                  </Link>
                </div>
              );
            })}
          </div>
          <div className="img-container">
            <ForaminaSVG {...props} />
            <WhiteGradient />
          </div>
        </NerveTopContainerMobile>
      </Fragment>
    );
  }

  return (
    <NerveTopContainerMobile>
      <div className="title">
        <div className="nerve-name">{foramen.name}</div>
      </div>

      <div className="info">
        {foramen.nerves.length > 0 && (
          <div className="info-block">
            <div className="label-title">NERVES</div>
            <div className="contents">
              {foramen.nerves.map((nerve, index) => {
                return (
                  <div className="content" key={index}>
                    {nerve}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {foramen.vessels.length > 0 && (
          <div className="info-block">
            <div className="label-title">VESSELS</div>
            <div className="contents">
              {foramen.vessels.map((vessel, index) => {
                return (
                  <div className="content" key={index}>
                    {vessel}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {foramen.miscellaneous.length > 0 && (
          <div className="info-block">
            <div className="label-title">MISCELLANEOUS</div>
            <div className="contents">
              {foramen.miscellaneous.map((misc, index) => {
                return (
                  <div className="content" key={index}>
                    {misc}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>

      <div className="img-container">
        <ForaminaSVG {...props} foramenId={foramen.id} />
        <WhiteGradient />
      </div>
    </NerveTopContainerMobile>
  );
}

const NerveTopContainerMobile = styled.div`
  display: none;
  margin: auto;
  position: relative;
  .title {
    margin-bottom: 35px;
    position: relative;
    z-index: 3;
    .numeral {
      font-size: 18px;
    }
    .nerve-name {
      font-weight: 700;
      font-size: 32px;
    }
  }
  .info {
    text-align: left;
    padding: 10px 0;
    font-size: 16px;
    position: relative;
    z-index: 3;
    .styled-button {
      background: #f9f9f9;
      padding: 8px 15px;
      border-radius: 5px;
      width: fit-content;
      cursor: pointer;
      margin-bottom: 5px;
      a {
        color: black;
        text-decoration: none;
      }
    }
    .info-block {
      margin-bottom: 25px;
    }
    .label-title {
      font-weight: 700;
    }
    .label-subtitle {
      font-weight: 500;
    }
    .contents {
      font-weight: 300;
      margin-top: 5px;
      .content {
        margin-bottom: 5px;
      }
      .sub-content {
        margin-bottom: 15px;
      }
    }
  }
  .img-container {
    position: absolute;
    top: 0;
    right: -15px;
    width: 60%;
    z-index: 1;
    transform: scaleX(-1);
    img {
      max-width: 100%;
      max-height: 600px;
    }
  }
  @media (max-width: 600px) {
    display: block;
  }
`;

const WhiteGradient = styled.div`
  background: white;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
  background: linear-gradient(
    89.59deg,
    rgba(255, 255, 255, 0) 50%,
    #ffffff 97%
  );
`;

const StyledButton = styled.div`
  background: #f9f9f9;
  padding: 8px 15px;
  border-radius: 5px;
  width: fit-content;
  text-align: left;
  cursor: pointer;
  .content-button-text {
    display: inline-block;
    vertical-align: middle;
  }
  .content-button-icon {
    margin-left: 10px;
    vertical-align: middle;
    &.anatomy {
      color: ${props =>
        props.nerveView == "anatomy" ? green.bright : "lightgray"};
    }
    &.foramina {
      color: ${props =>
        props.nerveView == "foramina" ? green.bright : "lightgray"};
    }
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  display: inline-block;
`;
