import React, { Fragment } from "react";

// style
import { green, grayscale } from "../../Utilities";

export default function VectorIX(props) {
  const { nerveId } = props;
  return (
    <Fragment>
      <path
        d="M52.5574,492.6s11.6676,2.9169,15.3521,3.07,17.7562-1.0348,20.7253-1.38,10.593-2.4579,13.2028-2.9153c2.61-.4621,5.1046-1.0762,6.4479-.6141,1.3433.4606,3.531,3.224,3.3775,5.6788-.1536,2.4578-.7676,6.1408-2.9169,7.217-2.1493,1.0731-13.2028,3.838-16.7338,4.1435-3.531.3071-14.1239,1.0747-17.3479.4621-3.2239-.6141-9.0577-2.1493-11.0535-3.9931-1.9957-1.8423-6.4985-5.4377-7.676-6.9085S52.5574,492.6,52.5574,492.6Z"
        fill={nerveId == "9" ? green.bright : grayscale.gray}
        onClick={() => props.history.push("/nerve/9")}
        style={containerStyle}
      />
      <path
        d="M63.9929,504.0394c2.8524,2.06,5.7954,3.9516,9.3894,4.6946,7.7666,1.6,14.9176.3071,22.5169-.7307a57.5027,57.5027,0,0,0,11.31-2.57,3.0744,3.0744,0,0,0,.7169-.4851.5689.5689,0,1,0-.6279-.9488c-7.7528,2.43-15.851,3.25-23.8848,4.0806-6.64-.0783-13.7263-.1029-19.382-4.096h0c-.0261-.0123-.0384-.0123-.0506,0a.0438.0438,0,0,0,.0122.0553h0Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/9")}
        style={containerStyle}
      />
      <path
        d="M105.0076,492.0386c-1.8806,3.313-2.3919,7.4565-.1535,10.8478.8566,1.2927,2.4563,2.6207,3.9654,1.4324,3.0059-2.38,3.7613-6.2176,3.0059-9.942-.525-2.5208-2.5454-4.6425-5.22-3.9793-2.7127.6909-3.2362,4.03-3.5693,6.411-.3961,2.8908.525,6.2944,3.5049,7.51a.3386.3386,0,0,0,.4743-.14.35.35,0,0,0-.1535-.4744c-2.5837-1.2021-3.2239-4.1589-3.02-6.68.1535-1.9559.5634-4.3646,2.43-5.2965a2.657,2.657,0,0,1,2.9553.3838c1.9313,1.8284,2.0986,4.6056,1.4968,7.062-.4483,1.7931-1.2282,4.2878-3.3268,4.5933-2.5331-1.1391-3.07-4.1066-3.2884-6.5507a8.0242,8.0242,0,0,1,.9472-5.1445c0-.0261,0-.0369-.0123-.0507-.0107.0016-.0368.0016-.0368.0169Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/9")}
        style={containerStyle}
      />
      <path
        d="M45.4814,486.2171c1.4584,1.6765,2.1247,3.85,4.03,5.2335,6.0257,4.3616,13.2412,4.7454,20.1619,5.0908a79.3068,79.3068,0,0,0,17.027-.7553,94.5747,94.5747,0,0,0,10.2352-2.2906c3.0444-.8689,5.8845-1.9466,9.0317-2.266a6.11,6.11,0,0,1,3.1088.3316.2673.2673,0,0,0,.1919-.4989,4.0076,4.0076,0,0,0-2.9169-.7554c-9.3126,1.5076-17.8837,4.3355-27.0949,4.7193-6.8317.2947-13.5222.347-20.4429-1.265-5.2074-1.23-10.3749-2.9691-13.2795-7.587h0a.0415.0415,0,0,0-.0522,0c-.0123.0184-.0123.0292,0,.043Z"
        fill="#5a514c"
        onClick={() => props.history.push("/nerve/9")}
        style={containerStyle}
      />
    </Fragment>
  );
}

const containerStyle = {
  cursor: "pointer"
};
